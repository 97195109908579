(function () {
  var storeApp = window.storeApp || {};
  storeApp.cineclubAlertBarVM = function (ko) {
    var vm = this;
    var cookieName = "CineClub";
    vm.init = initialize;
    vm.displayCineclubAlertbar = ko.observable(false);
    vm.dismissCineclubAlertbar = dismissCineclubAlertbar;
    vm.profileId = ko.observable(null);
    return vm;
    function initialize() {
      $.get("/Home/GetUserProfileLocally", function (data) {
        if (data.IsCineClubMember) {
          var show = !getCookie(data.UserProfileID);
          ShowHideBar(show);
          vm.profileId(data.UserProfileID);
          vm.displayCineclubAlertbar(show);
        }
      });
    }
    function dismissCineclubAlertbar() {
      if (vm.profileId() != null) setCookie(vm.profileId());
      ShowHideBar(false);
      vm.displayCineclubAlertbar(false);
    }
    function getCookie(profileId) {
      var cookies = decodeURIComponent(document.cookie).split(';');
      var found = false;
      cookies.forEach(function (item) {
        var name = item.split('=')[0].trim();
        var value = item.split('=')[1].trim();
        if (name == profileId && value == cookieName) found = true;
      });
      return found;
    }
    function setCookie(profileId) {
      try {
        var expDays = 30;
        var date = new Date();
        date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
        var cookieObject = "".concat(profileId, "=").concat(cookieName, ";expires=").concat(date.toUTCString(), ";path=/");
        document.cookie = cookieObject;
      } catch (err) {
        console.log('Set Cookie :');
        console.log(err);
        return false;
      }
    }
    function ShowHideBar(status) {
      if (status) {
        $("#promo-banner-wrap").addClass("banner-in");
        $("#main-nav").addClass("subscription");
        $(".full-width-promo-area").addClass("subscription");
      } else {
        $("#promo-banner-wrap").removeClass("banner-in");
        $("#main-nav").removeClass("subscription");
        $(".full-width-promo-area").removeClass("subscription");
      }
    }
  };
})();