ko.bindingHandlers.slickSlider = {
  init: function init(element, valueAccessor, allBindings) {
    var configurations = ko.unwrap(valueAccessor()),
      $slider = $(element);
    $slider.slick(configurations);
    if (allBindings().useAnalytics) {
      $slider.on("swipe", handleJumbotronSwipe).find(".promo-indicator").on("click", handleCarouselClickEvent);
    }
    function handleJumbotronSwipe(event, slick, direction) {
      if (direction === "left") {
        sendNextAnalyticsEvent();
      } else {
        sendPrevAnalyticsEvent();
      }
    }
    function handleCarouselClickEvent(event) {
      if (event.delegateTarget.dataset.indicator === "next") {
        sendNextAnalyticsEvent();
      } else {
        sendPrevAnalyticsEvent();
      }
    }
    function sendNextAnalyticsEvent() {
      CSOmnitureTrackingApp.csOmnitureTrackingModule.carousel.clickCarouselControl("carousel-right");
    }
    function sendPrevAnalyticsEvent() {
      CSOmnitureTrackingApp.csOmnitureTrackingModule.carousel.clickCarouselControl("carousel-left");
    }
  }
};