var storeApp = window.storeApp || {};
storeApp.checkoutViewModel = function ($, ko, bowser, connect, csTracking, csGlobal, newsletterService, promoService) {
  "use strict";

  var main = {
    init: initFunction
  };

  //// Methods ////
  function moneyFormat(n, currency) {
    if (main.vmLanguageSetting === "French") {
      return decimalFormat(n) + " " + currency;
    } else {
      return currency + decimalFormat(n);
    }
  }
  function decimalFormat(n) {
    if (main.vmLanguageSetting === "French") {
      return parseFloat(n).toFixed(2).replace('.', ',');
    } else {
      return parseFloat(n).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
    }
  }
  function replaceHtmlCharacters(input) {
    return input.replace(/&#(\d{0,4});/g, function (fullStr, str) {
      return String.fromCharCode(str);
    });
  }
  function loadCheckout() {
    var connectInitializedCallback = window.connectInitializedCallback || function () {
      return;
    };
    var oldConnectInitializeFunction = connectInitializedCallback;
    window.connectInitializedCallback = function () {
      oldConnectInitializeFunction();
      if (connect.IsUserLoggedIn()) {
        getConnectSceneCardsFirstLoad();
        getConnectGiftCardsFirstLoad();
        getConnectCreditCardsFirstLoad();
        getPaypalInformationFirstLoad();
        main.couponCode(main.vmPromoCode());
        main.vmUserInfo(ConnectApp.ConnectModule.getConnectUserInfo());
        applySessionPromoCode();
        if (main.payPalEnabled) {
          if (!(main.sessionPromoCode && main.sessionPromoCode.couponCode !== '')) {
            main.isLoaded(true);
          }
        } else if ((!main.hasCreditCard() || !main.selectedCreditCard().provinceCode || !main.selectedCreditCard().countryCode) && (!main.vmCurrentIPCountry || !main.vmCurrentIPProvince || main.vmCurrentIPCountry !== "CA")) {
          main.errorMessage("Please update your credit card before proceeding");
          main.IsErrorMessage(true);
          main.isLoaded(true);
        } else {
          if (!(main.sessionPromoCode && main.sessionPromoCode.couponCode !== '')) {
            main.isLoaded(true);
          }
        }
      } else {
        main.errorMessage("An unexpected error has occured please refresh your page or try again later");
        main.IsErrorMessage(true);
        main.isLoaded(true);
      }
    };
  }
  function applySessionPromoCode() {
    if (!!main.sessionPromoCode && main.sessionPromoCode.couponCode !== '') {
      main.couponCode(main.sessionPromoCode.couponCode);
      onClickPromoCodeSubmitFunction();
    }
  }

  //init method
  function initFunction(vmodel, checkoutDetailsData, language) {
    //Init Observables, methods, etc.
    main.isMobileOrTablet = ko.observable(Boolean(bowser.mobile) || Boolean(bowser.tablet));
    main.OrderSuccessModalVisible = ko.observable(false);
    main.OrderFailureModalVisible = ko.observable(false);
    main.IsErrorMessage = ko.observable(false);
    main.errorMessage = ko.observable();
    main.submitOrderLoading = ko.observable(false);
    main.getTotalEarnPointsToDisplay = getTotalEarnPointsToDisplayFunction;
    main.checkoutDetailsObject = null;
    main.formatCurrency = moneyFormat;
    main.getQualityList = getQualityList;
    main.windowResizeDebouncer = null;
    //Checkout model data
    main.vmLanguageSetting = "English";
    main.vmProductId = 0;
    main.vmPricingPlanId = 0;
    //vm Scene card
    main.vmCurrentScenePointsToRedeem = ko.observable(0);
    main.vmScenePointToDollarMultiplier = 0;
    main.vmDollarToScenePointMultiplier = 0;
    main.vmNotEnoughScenePointsToRedeemErrorMessage = ko.observable("");
    main.vmCurrenSceneCardMemberName = ko.observable("");
    main.vmCurrenSceneCardNumber = ko.observable("");
    main.vmScenePointToEarnMultiplier = 0;
    main.vmScenePointToEarnForSuperTicket = 0;
    //vm Gift card
    main.vmCurrentGiftAmountToRedeem = ko.observable(0);
    main.vmCurrentGiftCardNumber = ko.observable("");
    main.vmZeroGiftCardBalanceErrorMessage = ko.observable("");
    //vm Credit card
    main.vmCurrentPaymentInstrumentId = ko.observable(0);
    main.vmCurrentCreditCardNumber = ko.observable("");
    //vm PayPal
    main.vmCurrentPayPalAmount = ko.observable(0);
    //vm Promo Code
    main.vmPromoCodeActionUrl = ko.observable("");
    main.vmPromoCode = ko.observable("");
    main.vmTaxInfoActionUrl = "";
    //End of Checkout model data 
    //Pricing and Points info
    main.chargeAmount = 0.0;
    main.discountedAmount = 0.0;
    main.productScenePoints = ko.observable(0);
    main.productBonusPoints = ko.observable(0);
    main.origProductScenePoints = 0;
    main.origProductBonusPoints = 0;
    main.screenQualityType = "";
    main.purchaseType = "";
    main.pricingPlanType = "";
    main.isPreOrder = ko.observable(false);
    main.taxRate = ko.observable(0);
    main.totalAmountDisplay = ko.observable("0");
    main.totalPrice = ko.observable(0.00);
    main.taxType = ko.observable("");
    main.totalInfoLoading = ko.observable(true);
    main.isUpgradeSuperTicket = ko.observable(false);
    main.isBundle = ko.observable(false);
    //Scene Card
    main.selectedSceneCard = ko.observable();
    main.selectSceneCard = selectSceneCardFunction;
    main.isSelectedSceneCard = isSelectedSceneCardFunction;
    main.redeemableScenePoints = ko.observable(0);
    main.sceneCards = ko.observableArray([]);
    main.openAddSceneCard = openAddSceneCardFunction;
    main.isSceneRedemptionAvailable = isSceneRedemptionAvailableFunction;
    main.removeScenePoints = removeScenePointsFunction;
    main.hasSceneCard = ko.observable(false);
    main.sceneChecked = ko.observable(false).extend({
      notify: 'always'
    });
    main.sceneCheckDisable = ko.observable(false);
    main.sceneInfoLoading = ko.observable(true);
    //Gift Card
    main.selectedGiftCard = ko.observable();
    main.selectGiftCard = selectGiftCardFunction;
    main.isSelectedGiftCard = isSelectedGiftCardFunction;
    main.giftCards = ko.observableArray([]);
    main.redeemableGiftAmount = ko.observable(0);
    main.openAddGiftCard = openAddGiftCardFunction;
    main.isGiftRedemptionAvailable = isGiftRedemptionAvailableFunction;
    main.removeGiftAmount = removeGiftAmountFunction;
    main.hasGiftCard = ko.observable(false);
    main.giftChecked = ko.observable(false).extend({
      notify: 'always'
    });
    main.giftCheckDisable = ko.observable(false);
    main.giftCardInfoLoading = ko.observable(true);
    //Credit Card
    main.selectedCreditCard = ko.observable();
    main.selectCreditCard = selectCreditCardFunction;
    main.isSelectedCreditCard = isSelectedCreditCardFunction;
    main.payableCreditAmount = ko.observable(0);
    main.creditCards = ko.observableArray([]);
    main.openAddCreditCard = openAddCreditCardFunction;
    main.hasCreditCard = ko.observable(false);
    main.creditCardInfoLoading = ko.observable(true);
    main.onClickPlaceOrder = onClickPlaceOrderFunction;
    main.selectCardImgSrc = selectCardImgSrcFunction;
    //Promo Code
    main.vmGeneralPromoCodeErrorMessage = ko.observable("");
    main.onClickPromoCodeSubmit = onClickPromoCodeSubmitFunction;
    main.couponCode = ko.observable("");
    main.IsCouponValid = ko.observable(false);
    main.IsCouponApplied = ko.observable(false);
    main.CouponErrorMessage = ko.observable("");
    main.promoSuccessMessage = ko.observable("");
    main.promoErrorMessage = ko.observable("");
    main.promoAmountToRedeem = ko.observable(0);
    main.removePromo = removePromoFunction;
    main.isCheckoutSubmitLoaded = ko.observable(true);
    main.sessionPromoCode = vmodel.autoRedeemPromoCode;

    //PayPal
    main.payPalEnabled = false;
    main.onClickPaypal = onClickPaypalFunction;
    main.paypalUserName = ko.observable("");
    main.payPalAmountToPay = ko.observable(0);
    main.payPalChecked = ko.observable(false);
    //Connect User Info
    main.vmUserInfo = ko.observable();
    main.vmCheckoutMessagesJson = null;
    main.submitOrder = submitOrderFunction;
    //Ordercompleted Modal
    main.playPurchasedMovie = playPurchasedMovie;
    main.csgPlayPurchasedMovie = csgPlayPurchasedMovie;
    main.orderModalErrorMessage = ko.observable("");
    // New Additions
    main.isLoaded = ko.observable(false);
    main.addCustomScrollBar = addCustomScrollBarFunction;
    main.closeCardModal = closeCardModal;
    main.vmLanguageSetting = language;
    main.vmMyLibraryUrl = vmodel.myLibraryUrl;
    main.pdpUrl = vmodel.pdpUrl;
    main.vmCheckoutMessagesJson = JSON && vmodel.checkoutMessagesJson || $.parseJSON(vmodel.checkoutMessagesJson);

    //Location based on IP address
    main.vmCurrentIPCountry = vmodel.countryFromIP;
    main.vmCurrentIPProvince = vmodel.provinceFromIP;
    main.vmCurrentIPPostalCode = vmodel.postalCodeFromIP;

    //Scene Card
    main.vmCurrentScenePointsToRedeem(vmodel.currentScenePointsToRedeem);
    main.vmNotEnoughScenePointsToRedeemErrorMessage(vmodel.notEnoughScenePointsToRedeemErrorMessage);
    main.vmScenePointToDollarMultiplier = vmodel.scenePointToDollarMultiplier;
    main.vmDollarToScenePointMultiplier = vmodel.dollarToScenePointMultiplier;
    main.vmCurrenSceneCardMemberName(vmodel.currenSceneCardMemberName);
    main.vmCurrenSceneCardNumber(vmodel.currenSceneCardNumber);
    main.vmScenePointToEarnMultiplier = vmodel.scenePointToEarnMultiplier;
    main.vmScenePointToEarnForSuperTicket = vmodel.scenePointToEarnForSuperTicket;

    //Gift Card
    main.vmCurrentGiftAmountToRedeem(vmodel.currentGiftAmountToRedeem);
    main.vmCurrentGiftCardNumber(vmodel.currentGiftCardNumber);
    main.vmZeroGiftCardBalanceErrorMessage(vmodel.zeroGiftCardBalanceErrorMessage);

    //Credit card
    main.vmCurrentPaymentInstrumentId(vmodel.currentPaymentInstrumentId);
    main.vmCurrentCreditCardNumber(vmodel.currentCreditCardNumber);

    //PayPal
    main.vmCurrentPayPalAmount(vmodel.currentPayPalAmount);
    main.payPalEnabled = vmodel.payPalEnabled;

    //Promo
    main.vmPromoCodeActionUrl(vmodel.promoCodeActionUrl);
    main.vmPromoCode(vmodel.currentPromoCode);
    main.vmGeneralPromoCodeErrorMessage(vmodel.GeneralPromoCodeErrorMessage);

    //Newsletter Signup
    main.newsletterError = ko.observable(false);
    main.newsletterChecked = ko.observable(false);
    main.newsletterChecked.subscribe(newsletterUpdated);
    main.toggleNewsletter = toggleNewsletter;
    main.vmTaxInfoActionUrl = vmodel.getTaxInfoActionUrl;
    main.vmUserInfo(vmodel.koUserInfo);
    main.vmProductId = vmodel.productId;
    main.vmPricingPlanId = vmodel.pricingPlanId;
    main.checkoutDetailsObject = checkoutDetailsData;

    //Order Success Failure Modal Params
    main.playerContentUrl = vmodel.playerContentUrl;

    // ADOBE DTM parameters
    if (digitalData !== null || digitalData !== 'undefined') {
      var _main$checkoutDetails, _main$checkoutDetails2, _main$checkoutDetails3, _main$checkoutDetails4, _main$checkoutDetails5, _main$checkoutDetails6, _main$checkoutDetails7, _main$checkoutDetails8, _main$checkoutDetails9, _main$checkoutDetails10, _main$checkoutDetails11;
      digitalData.page.pageInfo.pageName = "checkout";
      digitalData.product.productName = (_main$checkoutDetails = main.checkoutDetailsObject.ProductName) !== null && _main$checkoutDetails !== void 0 ? _main$checkoutDetails : "";
      digitalData.product.productID = (_main$checkoutDetails2 = main.checkoutDetailsObject.ProductId) !== null && _main$checkoutDetails2 !== void 0 ? _main$checkoutDetails2 : "";
      digitalData.product.productCategory = (_main$checkoutDetails3 = main.checkoutDetailsObject.ContentType) !== null && _main$checkoutDetails3 !== void 0 ? _main$checkoutDetails3 : "";
      digitalData.product.productPrice = (_main$checkoutDetails4 = main.checkoutDetailsObject.TotalBeforeTax) !== null && _main$checkoutDetails4 !== void 0 ? _main$checkoutDetails4 : "";
      digitalData.product.productQuantity = 1;
      digitalData.product.productType = (_main$checkoutDetails5 = main.checkoutDetailsObject.PricingPlanName) !== null && _main$checkoutDetails5 !== void 0 ? _main$checkoutDetails5 : "";
      digitalData.content.contentCategory = (_main$checkoutDetails6 = main.checkoutDetailsObject.ContentType) !== null && _main$checkoutDetails6 !== void 0 ? _main$checkoutDetails6 : "";
      digitalData.content.contentID = (_main$checkoutDetails7 = main.checkoutDetailsObject.ProductId) !== null && _main$checkoutDetails7 !== void 0 ? _main$checkoutDetails7 : "";
      digitalData.content.contentName = (_main$checkoutDetails8 = main.checkoutDetailsObject.ProductName) !== null && _main$checkoutDetails8 !== void 0 ? _main$checkoutDetails8 : "";
      digitalData.content.contentType = (_main$checkoutDetails9 = main.checkoutDetailsObject.PricingPlanName) !== null && _main$checkoutDetails9 !== void 0 ? _main$checkoutDetails9 : "";
      digitalData.content.format = main.checkoutDetailsObject.PricingPlanName.split(' ').length > 0 ? main.checkoutDetailsObject.PricingPlanName.split(' ')[1] : "";
      digitalData.content.genre = (_main$checkoutDetails10 = main.checkoutDetailsObject.MovieGenre) !== null && _main$checkoutDetails10 !== void 0 ? _main$checkoutDetails10 : "";
      digitalData.content.studio = (_main$checkoutDetails11 = main.checkoutDetailsObject.MovieStudio) !== null && _main$checkoutDetails11 !== void 0 ? _main$checkoutDetails11 : "";
      digitalData.user.account.authState = vmodel.isUserLoggedIn;
      digitalData.user.account.connectID = vmodel.userConnectGuid;
    }
    //END: ADOBE DTM parameters

    //Member Discount
    main.membershipDiscount = main.checkoutDetailsObject.MembershipDiscount.toFixed(2); // amount of discount
    main.MembershipDiscountLabel = main.checkoutDetailsObject.MembershipDiscountName; // "isSuccessful": boolean,
    main.IsCineClubMember = ko.observable(vmodel.IsCineClubMember);
    main.taxRate(vmodel.TaxRate);
    main.chargeAmount = main.checkoutDetailsObject.ChargeAmount.toFixed(2);
    main.discountedAmount = main.checkoutDetailsObject.DiscountedAmount.toFixed(2);
    main.dummyTotal = ko.computed(function () {
      return (main.checkoutDetailsObject.DiscountedAmount + main.checkoutDetailsObject.MembershipDiscount).toFixed(2);
    });
    main.isUpgradeSuperTicket(main.checkoutDetailsObject.IsUpgradeSuperTicket);
    main.isBundle(vmodel.isBundle);
    main.productScenePoints(main.checkoutDetailsObject.BaseScenePoints);
    main.productBonusPoints(0);
    if (main.checkoutDetailsObject.ScreenQualityType !== "None" && main.checkoutDetailsObject.ScreenQualityType !== "") main.screenQualityType = main.checkoutDetailsObject.ScreenQualityType;else main.screenQualityType = "";
    main.isUHDQuality = main.screenQualityType.toLowerCase() === 'uhd';
    main.isHarmonizedPricing = ko.computed(function () {
      return main.checkoutDetailsObject.PricingPlanType.toLowerCase() === CineplexAppConstants.HarmonizedName;
    });
    main.purchaseType = getProductPurchaseType(main.checkoutDetailsObject.ContentType);
    main.isPreOrder(main.checkoutDetailsObject.IsPreOrder);
    main.productBonusPoints(main.checkoutDetailsObject.BonusScenePoints); // bonus points

    if (main.isUpgradeSuperTicket()) {
      main.productScenePoints(main.vmScenePointToEarnForSuperTicket);
      main.productBonusPoints(0);
    }
    main.origProductScenePoints = main.productScenePoints();
    main.origProductBonusPoints = main.productBonusPoints();
    main.productTotalPointsDisplay = ko.computed(main.getTotalEarnPointsToDisplay);
    loadCheckout();

    //declare computed functions here

    //Overall Display
    main.displayQuality = ko.computed(function () {
      return main.screenQualityType != '' && !main.isUHDQuality;
    });
    main.displayBody = ko.computed(function () {
      return main.isLoaded() && !main.IsErrorMessage();
    });
    main.displayPageLoader = ko.computed(function () {
      return main.isLoaded() || main.IsErrorMessage();
    });
    main.taxAmount = ko.computed(function () {
      var taxAmnt = parseFloat(main.discountedAmount - main.promoAmountToRedeem()) * parseFloat(main.taxRate());
      return Math.round(taxAmnt * 100) / 100;
    });
    main.redeemableScenePointsValue = ko.computed(redeemableScenePointsValueFunction);
    main.totalAmountNoGift = ko.computed(totalAmountNoGiftFunction);
    main.totalAmountNoScene = ko.computed(totalAmountNoSceneFunction);

    //Scene Card
    main.sceneCardBalanceToBeUsed = ko.computed(function () {
      if (main.selectedSceneCard()) {
        var totalToPoints = parseFloat(main.totalAmountNoScene()) * main.vmDollarToScenePointMultiplier;
        return Math.min(parseFloat(main.selectedSceneCard().pointsBalance), totalToPoints);
      } else {
        return 0;
      }
    });

    //Gift Card
    main.showGiftCardAddCardBtn = ko.computed(function () {
      return main.giftCards().length === 0;
    });
    main.showGiftCardOptions = ko.computed(function () {
      return main.giftCards().length > 0;
    });
    main.giftCardBalanceToBeUsed = ko.computed(function () {
      if (main.selectedGiftCard() != null) {
        return Math.min(parseFloat(main.totalAmountNoGift()), parseFloat(main.selectedGiftCard().balance));
      } else {
        return 0;
      }
    });
    main.totalAmount = ko.computed(totalAmountFunction);
    main.sceneChecked.subscribe(function (newValue) {
      applyRedeemableScenePointsFunction(newValue);
    });
    main.selectedSceneCard.subscribe(function (newValue) {
      if (newValue != null) {
        if (newValue.pointsBalance <= 0) {
          main.sceneCheckDisable(true);
        } else {
          main.sceneCheckDisable(false);
        }
      }
    });
    main.giftChecked.subscribe(function (newValue) {
      applyRedeemableGiftAmountFunction(newValue);
    });

    //PayPal
    main.payPalChecked.subscribe(function (newValue) {
      if (newValue) {
        main.payPalAmountToPay(main.totalAmount());
      } else {
        main.payPalAmountToPay(0);
      }
    });
    main.couponCode.subscribe(function (newValue) {
      if (main.IsCouponApplied() && !main.IsCouponValid()) {
        main.IsCouponApplied(false);
      }
    });
    main.paypalUnselected = ko.computed(function () {
      return !main.payPalChecked();
    });
    main.payPalAdded = ko.computed(function () {
      return main.paypalUserName() !== "";
    });
    main.showPayPalCheckoutButton = ko.computed(function () {
      return main.paypalUserName() === "";
    });
    main.placeOrderDisabled = ko.computed(function () {
      return parseFloat(main.totalAmount()) > 0 && !(main.hasCreditCard() || main.payPalChecked());
    });
    main.promoApplyVisible = ko.computed(function () {
      return !(main.IsCouponApplied() && main.IsCouponValid());
    });

    //Visibility Computed Functions

    main.redeemSceneCardCheckboxVisible = ko.computed(function () {
      return main.selectedSceneCard() != null && main.selectedSceneCard().pointsBalance > 0;
    });
    main.redeemGiftCardCheckboxVisible = ko.computed(function () {
      return main.selectedGiftCard() != null && main.selectedGiftCard().balance > 0;
    });
    main.scenePointsEarnedVisible = ko.computed(function () {
      return parseInt(main.productTotalPointsDisplay()) > 0;
    });
    main.promoCodeSectionWithoutCodeVisible = ko.computed(function () {
      return !main.IsCouponApplied() || main.IsCouponApplied() && !main.IsCouponValid();
    });
    main.promoCodeSectionWithCodeVisible = ko.computed(function () {
      return main.IsCouponApplied() && main.IsCouponValid();
    });

    //Misc Functions

    main.toggleScenePayment = function () {
      if (main.selectedSceneCard() != null && main.selectedSceneCard().pointsBalance > 0 && !main.sceneCheckDisable() || main.sceneChecked()) main.sceneChecked(!main.sceneChecked());
    };
    main.toggleGiftPayment = function () {
      if (!main.giftCheckDisable()) {
        main.giftChecked(!main.giftChecked());
      }
    };
    main.checkPaypal = function () {
      main.payPalChecked(true);
      closeCardModal();
    };
  }
  function addCustomScrollBarFunction(elem) {
    var $elem = $(elem);
    $elem.modal("show");
    setTimeout(function () {
      $('body').on("click touchstart", outsideModalClickHandler);
    });
  }
  function outsideModalClickHandler(event) {
    if (!$(event.target).closest('.card-modal').length && !$(event.target).is('.card-modal')) {
      closeCardModal();
      //$(window).unbind("resize", reinitjScrollOnResize);
    }
  }
  function closeCardModal() {
    $('.card-modal').modal('hide');
    $("body").unbind("click touchstart", outsideModalClickHandler);
  }
  function getProductPurchaseType(contentType) {
    if (contentType.indexOf("DigitalPurchase") >= 0) return "Buy";else return "Rent";
  }
  function getTotalEarnPointsToDisplayFunction() {
    var totalEarnPointToDisplay = main.productScenePoints().toString();
    if (parseInt(main.productBonusPoints()) > 0) totalEarnPointToDisplay += " + " + main.productBonusPoints().toString();
    return totalEarnPointToDisplay;
  }

  //Pricing and Points Calculation methods

  function getTaxInfo(provinceCode, countryCode) {
    main.totalInfoLoading(true);
    $.ajax({
      type: "GET",
      dataType: "json",
      url: main.vmTaxInfoActionUrl,
      data: {
        "provinceCode": provinceCode,
        "countryCode": countryCode
      },
      success: function success(data, textStatus, jqXHR) {
        var taxResultJson = $.parseJSON(data);
        if (taxResultJson) {
          main.taxRate(taxResultJson.TotalTaxRate);
          main.taxType(taxResultJson.TotalTaxName);
        }
        main.totalInfoLoading(false);
      },
      error: function error(xhr, status, _error) {
        console.log(_error);
        console.log(status);
        console.log(xhr);
        main.totalInfoLoading(false);
      }
    });
  }
  function totalAmountFunction() {
    var subtotal = parseFloat(main.discountedAmount);
    var sceneValue = parseFloat(main.redeemableScenePointsValue());
    var giftValue = parseFloat(main.redeemableGiftAmount());
    var result = parseFloat(subtotal + main.taxAmount() - (sceneValue + giftValue + main.promoAmountToRedeem())).toFixed(2);
    result = result < 0 ? 0.00 : result;
    main.giftCheckDisable(false);
    if (main.selectedSceneCard() != null && main.selectedSceneCard().pointsBalance > 0) main.sceneCheckDisable(false);
    if (result <= 0) {
      if (main.giftChecked() && !main.sceneChecked()) main.sceneCheckDisable(true);
      if (!main.giftChecked() && !main.sceneChecked()) {
        main.giftCheckDisable(true);
        main.sceneCheckDisable(true);
      }
    }
    main.totalPrice(result);
    main.totalAmountDisplay(moneyFormat(result, "$"));
    return result;
  }
  function totalAmountNoGiftFunction() {
    var subtotal = parseFloat(main.discountedAmount);
    subtotal = parseFloat(subtotal + main.taxAmount() - main.promoAmountToRedeem());
    return subtotal;
  }
  function totalAmountNoSceneFunction() {
    var subtotal = parseFloat(main.discountedAmount);
    var giftValue = parseFloat(main.redeemableGiftAmount());
    subtotal = parseFloat(subtotal + main.taxAmount() - (giftValue + main.promoAmountToRedeem()));
    return subtotal;
  }

  //Scene Card methods
  function sceneCard(name, number, maskednumber, pointsBalance) {
    this.name = name;
    this.number = number;
    this.maskednumber = maskednumber;
    this.numberLast4Digits = number.substring(number.length - 4, number.length);
    this.pointsBalance = pointsBalance;
    this.fullDetails = name + ' (' + maskednumber + ')';
  }
  function isSelectedSceneCardFunction(card) {
    return main.selectedSceneCard() && main.selectedSceneCard().number === card.number;
  }
  function isSceneRedemptionAvailableFunction() {
    return main.isSceneRedemptionAvailable === "true" && connect.IsUserLoggedIn();
  }
  function redeemableScenePointsValueFunction() {
    var result = (main.redeemableScenePoints() * main.vmScenePointToDollarMultiplier).toFixed(2);
    return result;
  }
  function applyRedeemableScenePointsFunction(checked) {
    if (checked) {
      if (main.totalAmount() >= 1)
        // equal or more than $1
        {
          updateRedeemableScenePoints();
          if (!main.isUpgradeSuperTicket()) {
            main.productBonusPoints(0);
            main.productScenePoints(0);
          }
        }
    } else {
      main.removeScenePoints();
    }
  }
  function updateRedeemableScenePoints() {
    var points = Math.round(parseFloat(main.sceneCardBalanceToBeUsed()));
    main.redeemableScenePoints(points);
  }
  function getConnectSceneCards() {
    main.sceneInfoLoading(true);
    connect.GetSceneInfo(function (sceneInfo) {
      var originalSceneCards = main.sceneCards();
      resetSceneOptions();
      var koSceneCards = ko.utils.arrayMap(sceneInfo.Cards, function (item) {
        return new sceneCard(item.CardMemberName, item.CardNumber, item.MaskedCardNumber, item.PointsBalance);
      });
      if (koSceneCards.length === 0) {
        main.hasSceneCard(false);
        main.sceneCards([]);
      } else {
        main.hasSceneCard(true);
        main.sceneCards(koSceneCards);
        if (originalSceneCards.length < main.sceneCards().length) {
          var addedSceneItem = getAddedCard(main.sceneCards(), originalSceneCards, "fullDetails");
          if (addedSceneItem.length > 0) {
            main.selectedSceneCard(addedSceneItem[0]);
          }
        } else {
          var selectedSceneItem = ko.utils.arrayFirst(koSceneCards, function (item) {
            return item.number === main.vmCurrenSceneCardNumber() && item.name === main.vmCurrenSceneCardMemberName();
          });
          if (!selectedSceneItem) selectedSceneItem = ko.utils.arrayFirst(koSceneCards, function (item) {
            return item;
          });
          main.selectedSceneCard(selectedSceneItem);
        }
      }
      main.sceneInfoLoading(false);
    });
  }
  function getConnectSceneCardsFirstLoad() {
    main.sceneInfoLoading(true);
    resetSceneOptions();
    var obj = main.checkoutDetailsObject;
    var sceneCards = obj.SceneCards;
    if (sceneCards) {
      if (sceneCards.length === 0) {
        main.hasSceneCard(false);
        main.sceneCards([]);
        main.selectSceneCard(null);
      } else {
        main.hasSceneCard(true);
        var koSceneCards = ko.utils.arrayMap(sceneCards, function (item) {
          return new sceneCard(item.CardMemberName, item.CardNumber, item.MaskedCardNumber, item.Balance);
        });
        main.sceneCards(koSceneCards);
        var selectedSceneItem = ko.utils.arrayFirst(koSceneCards, function (item) {
          return item.number === main.vmCurrenSceneCardNumber() && item.name === main.vmCurrenSceneCardMemberName();
        });
        if (!selectedSceneItem) selectedSceneItem = ko.utils.arrayFirst(koSceneCards, function (item) {
          return item;
        });
        main.selectedSceneCard(selectedSceneItem);
        populateSceneCardPointsToRedeem();
      }
    }
    main.sceneInfoLoading(false);
  }
  function populateSceneCardPointsToRedeem() {
    if (main.vmCurrentScenePointsToRedeem() > 0) {
      //setTimeout(function () {
      main.sceneChecked(true);
      main.vmCurrentScenePointsToRedeem(0);
      //}, 500);
    }
  }
  function selectSceneCardFunction(record) {
    resetSceneOptions();
    main.selectedSceneCard(record);
    closeCardModal();
  }
  function openAddSceneCardFunction() {
    closeCardModal();

    //omniture tracking
    if (main.hasSceneCard()) csTracking.checkout.clickEditButton("SCENE");else csTracking.checkout.clickAddButton("SCENE");
    connect.Settings.PopupMode = true;
    connect.Settings.Close = function () {
      if (connect.IsUserLoggedIn()) {
        getConnectSceneCards();
      }
    };
    connect.OpenConnectPage(connect.Page.SceneSettings);
  }
  function removeScenePointsFunction() {
    main.redeemableScenePoints(0);
    if (main.sceneChecked()) main.sceneChecked(false);
    if (!main.isUpgradeSuperTicket()) {
      if (main.promoAmountToRedeem() === 0) {
        main.productScenePoints(main.origProductScenePoints);
        main.productBonusPoints(main.origProductBonusPoints);
      } else {
        var promoToRedeem = Math.min(parseFloat(main.promoAmountToRedeem()), parseFloat(main.discountedAmount));
        var newSubtotal = parseFloat(main.discountedAmount - promoToRedeem);
        main.productScenePoints(parseInt(Math.ceil(newSubtotal * main.vmScenePointToEarnMultiplier)));
      }
    }
  }
  function resetSceneOptions() {
    main.removeScenePoints();
    main.selectedSceneCard(null);
  }

  //Gift Card methods
  function giftCard(status, number, balance) {
    this.status = status;
    this.number = number;
    this.numberLast4Digits = number.substring(number.length - 4, number.length);
    this.balance = balance;
    this.fullDetails = number + ' ($' + balance + ')';
  }
  function isGiftRedemptionAvailableFunction() {
    return main.isGiftRedemptionAvailable === "true" && connect.IsUserLoggedIn();
  }
  function applyRedeemableGiftAmountFunction(checked) {
    if (checked) {
      main.redeemableGiftAmount(main.giftCardBalanceToBeUsed());
    } else {
      main.removeGiftAmount();
    }
    if (main.sceneChecked() && main.totalAmountNoScene() > 0) {
      updateRedeemableScenePoints();
    } else {
      main.sceneChecked(false);
    }
  }
  function isSelectedGiftCardFunction(card) {
    if (main.selectedGiftCard() && main.selectedGiftCard().number === card.number) {
      return true;
    }
    return false;
  }
  function getConnectGiftCards() {
    main.giftCardInfoLoading(true);
    connect.GetGiftCardInfo(function (giftInfo) {
      var originalGiftCards = main.giftCards();
      resetGiftOptions();
      giftInfo.Cards = giftInfo.Cards.filter(function (card) {
        return card.Balance > 0;
      });
      var koGiftCards = ko.utils.arrayMap(giftInfo.Cards, function (item) {
        return new giftCard(item.Status, item.CardNumber, item.Balance);
      });
      if (koGiftCards.length === 0) {
        main.hasGiftCard(false);
        main.giftCards([]);
      } else {
        main.hasGiftCard(true);
        main.giftCards(koGiftCards);
        if (originalGiftCards.length < main.giftCards().length) {
          var addedGiftCardItem = getAddedCard(main.giftCards(), originalGiftCards, "fullDetails");
          if (addedGiftCardItem.length > 0) {
            main.selectedGiftCard(addedGiftCardItem[0]);
          }
        } else {
          var selectedGiftItem = ko.utils.arrayFirst(koGiftCards, function (item) {
            return item.number === main.vmCurrentGiftCardNumber();
          });
          if (!selectedGiftItem) selectedGiftItem = ko.utils.arrayFirst(koGiftCards, function (item) {
            return item;
          });
          main.selectedGiftCard(selectedGiftItem);
        }
      }
      main.giftCardInfoLoading(false);
    });
  }
  function getConnectGiftCardsFirstLoad() {
    main.giftCardInfoLoading(true);
    resetGiftOptions();
    var obj = main.checkoutDetailsObject;
    var giftCards = obj.GiftCards;
    giftCards = giftCards.filter(function (card) {
      return card.Balance > 0;
    });
    if (giftCards) {
      if (giftCards.length === 0) {
        main.hasGiftCard(false);
        main.giftCards([]);
      } else {
        var koGiftCards = ko.utils.arrayMap(giftCards, function (item) {
          return new giftCard(item.Status, item.CardNumber, item.Balance);
        });
        main.hasGiftCard(true);
        main.giftCards(koGiftCards);
        var selectedGiftItem = ko.utils.arrayFirst(koGiftCards, function (item) {
          return item.number === main.vmCurrentGiftCardNumber();
        });
        if (!selectedGiftItem) selectedGiftItem = ko.utils.arrayFirst(koGiftCards, function (item) {
          return item;
        });
        main.selectedGiftCard(selectedGiftItem);
        populateGiftCardPointsToRedeem();
      }
    }
    main.giftCardInfoLoading(false);
  }
  function populateGiftCardPointsToRedeem() {
    if (main.vmCurrentGiftAmountToRedeem() > 0) {
      //setTimeout(function () {
      main.giftChecked(true);
      main.vmCurrentGiftAmountToRedeem(0);
      //}, 500);
    }
  }
  function selectGiftCardFunction(record) {
    resetGiftOptions();
    main.selectedGiftCard(record);
    closeCardModal();
  }
  function openAddGiftCardFunction() {
    closeCardModal();

    //omniture tracking
    if (main.hasGiftCard()) csTracking.checkout.clickEditButton("giftcard");else csTracking.checkout.clickAddButton("giftcard");
    connect.Settings.PopupMode = true;
    connect.Settings.Close = function () {
      if (connect.IsUserLoggedIn()) {
        getConnectGiftCards();
      }
    };
    connect.OpenConnectPage(connect.Page.GiftCards);
  }
  function removeGiftAmountFunction() {
    main.redeemableGiftAmount(0);
    if (main.giftChecked()) main.giftChecked(false);
  }
  function resetGiftOptions() {
    main.removeGiftAmount();
    main.selectedGiftCard(null);
  }

  //Credit Card methods
  function creditCard(paymentInstrumentId, type, cardHolderName, cardNumber, expiryDate, postalCode, provinceCode, countryCode) {
    this.type = type;
    this.typeLabel = getCreditCardTypes(type);
    this.cardHolderName = cardHolderName;
    this.cardHolderNameCapitalized = cardHolderName.charAt(0).toUpperCase() + cardHolderName.slice(1);
    this.cardNumber = cardNumber;
    this.cardNumberLast4Digits = cardNumber.substring(cardNumber.length - 4, cardNumber.length);
    this.expiryDate = expiryDate;
    this.paymentInstrumentId = paymentInstrumentId;
    this.fullDetails = this.typeLabel + ' (' + this.cardNumber + ')';
    this.postalCode = postalCode;
    this.countryCode = countryCode;
    this.provinceCode = provinceCode;
  }
  function isSelectedCreditCardFunction(card) {
    return main.selectedCreditCard() && main.selectedCreditCard().cardNumber === card.cardNumber && !main.payPalChecked();
  }
  function openAddCreditCardFunction() {
    closeCardModal();

    //omniture tracking
    if (main.hasCreditCard()) csTracking.checkout.clickEditButton("cc");else csTracking.checkout.clickAddButton("cc");
    connect.Settings.PopupMode = true;
    connect.Settings.Close = function () {
      if (connect.IsUserLoggedIn()) {
        getConnectCreditCards();
      }
    };
    connect.OpenConnectPage(connect.Page.CreditCards);
  }
  function getConnectCreditCards() {
    main.creditCardInfoLoading(true);
    connect.GetPaymentCards(function (creditInfo) {
      var originalCreditCards = [].concat(main.creditCards());
      resetCreditOptions();
      var koCreditCards = ko.utils.arrayMap(creditInfo.Cards, function (item) {
        return new creditCard(item.CSGPaymentInstrumentId, item.Type, item.CardHolderName, item.CardNumber, item.ExpiryDate, item.PostalCode, item.ProvinceCode, item.CountryCode);
      });
      if (koCreditCards.length === 0) {
        main.hasCreditCard(false);
        main.creditCards([]);
        if (main.payPalAdded()) {
          main.payPalChecked(true);
        }
        getTaxInfo(main.vmCurrentIPProvince, main.vmCurrentIPCountry);
      } else {
        main.hasCreditCard(true);
        main.creditCards(koCreditCards);
        if (originalCreditCards.length < main.creditCards().length) {
          var addedCreditCardItem = getAddedCard(main.creditCards(), originalCreditCards, "fullDetails");
          if (addedCreditCardItem.length > 0) {
            main.selectCreditCard(addedCreditCardItem[0]);
          }
        } else {
          var selectedCreditCardItem = ko.utils.arrayFirst(koCreditCards, function (item) {
            return item.cardNumber === main.vmCurrentCreditCardNumber();
          });
          if (!selectedCreditCardItem) {
            selectedCreditCardItem = ko.utils.arrayFirst(koCreditCards, function (item) {
              return item;
            });
          }
          main.selectCreditCard(selectedCreditCardItem);
        }
      }
      main.creditCardInfoLoading(false);
    });
  }
  function getConnectCreditCardsFirstLoad() {
    main.creditCardInfoLoading(true);
    resetCreditOptions();
    var obj = main.checkoutDetailsObject;
    var creditCards = obj.CreditCards;
    if (creditCards) {
      if (creditCards.length === 0) {
        main.hasCreditCard(false);
        main.creditCards([]);
        getTaxInfo(main.vmCurrentIPProvince, main.vmCurrentIPCountry);
      } else {
        var koCreditCards = ko.utils.arrayMap(creditCards, function (item) {
          return new creditCard(item.CSGPaymentInstrumentId, item.Type, item.CardHolderName, item.CardNumber, item.ExpiryDate, item.PostalCode, item.ProvinceCode, item.CountryCode);
        });
        main.hasCreditCard(true);
        main.creditCards(koCreditCards);
        var selectedCreditCardItem = ko.utils.arrayFirst(koCreditCards, function (item) {
          return item.cardNumber === main.vmCurrentCreditCardNumber();
        });
        if (!selectedCreditCardItem) {
          selectedCreditCardItem = ko.utils.arrayFirst(koCreditCards, function (item) {
            return item;
          });
        }
        main.selectCreditCard(selectedCreditCardItem);
      }
    }
    main.creditCardInfoLoading(false);
  }
  function selectCreditCardFunction(record) {
    if (main.payPalChecked()) {
      main.payPalChecked(false);
    }
    resetCreditOptions();
    main.selectedCreditCard(record);
    if (!record.provinceCode || !record.provinceCode) {
      getTaxInfo(main.vmCurrentIPProvince, main.vmCurrentIPCountry);
    } else {
      getTaxInfo(record.provinceCode, record.countryCode);
    }
    closeCardModal();
  }
  function resetCreditOptions() {
    main.payableCreditAmount(0);
    main.selectedCreditCard(null);
  }
  function getCreditCardTypes(typeId) {
    var cardType = null;
    switch (typeId) {
      case 0:
        cardType = "";
        break;
      case 1:
        cardType = "Visa";
        break;
      case 2:
        cardType = "MasterCard";
        break;
      case 3:
        cardType = "AmericanExpress";
        break;
    }
    return cardType;
  }
  function selectCardImgSrcFunction(typeId) {
    var cardSrc = null;
    switch (typeId) {
      case 0:
        cardSrc = "/Content/img2017/CC.png";
        break;
      case 1:
        cardSrc = "/Content/img2017/visa.png";
        break;
      case 2:
        cardSrc = "/Content/img2017/MC.png";
        break;
      case 3:
        cardSrc = "/Content/img2017/AMEX.png";
        break;
    }
    return cardSrc;
  }

  //PayPal Methods
  function getPaypalInformation() {
    connect.GetPayPalInformation(function (clientPayPalInformation) {
      if (clientPayPalInformation.UserName !== "") {
        main.paypalUserName(clientPayPalInformation.UserName);
        main.payPalChecked(true);
      } else {
        main.paypalUserName("");
        main.payPalAmountToPay(0);
      }
    });
  }
  function getPaypalInformationFirstLoad() {
    var obj = main.checkoutDetailsObject;
    var payPalInfo = obj.PayPalInformation;
    if (payPalInfo) {
      if (!main.hasCreditCard() && payPalInfo.UserName !== "") {
        main.paypalUserName(payPalInfo.UserName);
        main.payPalChecked(true);
        main.payPalAmountToPay(main.totalAmount());
      } else if (payPalInfo.UserName !== "") {
        main.paypalUserName(payPalInfo.UserName);
      } else {
        main.paypalUserName("");
        main.payPalAmountToPay(0);
      }
    }
    populatePayPalInfoOnLoad();
  }
  function populatePayPalInfoOnLoad() {
    if (main.vmCurrentPayPalAmount() > 0 && main.paypalUserName() !== "") {
      main.payPalChecked(true);
      main.payPalAmountToPay(main.vmCurrentPayPalAmount());
    }
  }
  function onClickPaypalFunction() {
    closeCardModal();

    //omniture tracking
    csTracking.checkout.clickAddButton("paypal");
    connect.Settings.PopupMode = true;
    connect.Settings.Close = function () {
      if (connect.IsUserLoggedIn()) {
        getPaypalInformation();
      }
    };
    connect.OpenConnectPage(connect.Page.ConnectPayPal);
  }

  //Newsletter functions

  function newsletterUpdated(checked) {
    newsletterService.saveNewsletterInfo(checked, saveNewsletterSuccess, saveNewsletterFail);
  }
  function saveNewsletterSuccess(data, textStatus, jqXHR) {
    //No Visual Cues to user to show newsletter has been subscribed to
  }
  function saveNewsletterFail(error) {
    main.newsletterError(true);
    main.newsletterChecked(false);
  }
  function toggleNewsletter() {
    main.newsletterChecked(!main.newsletterChecked());
  }

  //Place Order
  function onClickPlaceOrderFunction() {
    if (main.payPalChecked() && main.payPalEnabled) {
      main.payPalAmountToPay(main.totalAmount());
    } else {
      main.payPalAmountToPay(0);
    }
    main.submitOrder();
  }
  function submitOrderFunction() {
    var sceneCard = main.sceneChecked() ? main.selectedSceneCard() : null;
    var giftCard = main.giftChecked() ? main.selectedGiftCard() : null;
    var creditCard = main.selectedCreditCard();
    var userName = replaceHtmlCharacters(main.vmUserInfo().firstName) + " " + replaceHtmlCharacters(main.vmUserInfo().lastName);
    var shoppingSessionParams = {
      'billToFullName': creditCard != null ? creditCard.cardHolderName : userName,
      'billToProvinceCode': creditCard != null ? creditCard.provinceCode : main.vmCurrentIPProvince,
      'billToCountryCode': creditCard != null ? creditCard.countryCode : main.vmCurrentIPCountry,
      'billToPostalCode': creditCard != null ? creditCard.postalCode : main.vmCurrentIPPostalCode,
      'sceneCardMemberName': sceneCard != null ? sceneCard.name : '',
      'sceneCardNumber': sceneCard != null ? sceneCard.number : '',
      'scenePointsToRedeem': main.redeemableScenePoints(),
      'giftAmountToRedeem': decimalFormat(main.redeemableGiftAmount()),
      'giftCardNumber': giftCard != null ? giftCard.number : '',
      'paymentInstrumentId': creditCard != null ? creditCard.paymentInstrumentId : 0,
      'creditCardNumber': creditCard != null ? creditCard.cardNumber : '',
      'creditCardHolderName': creditCard != null ? creditCard.cardHolderName : '',
      'creditCardType': creditCard != null ? creditCard.type : '',
      'creditCardProvince': creditCard != null ? creditCard.provinceCode : '',
      'creditCardCountry': creditCard != null ? creditCard.countryCode : '',
      'creditCardPostalCode': creditCard != null ? creditCard.postalCode : '',
      'productId': main.vmProductId,
      'pricingPlanId': main.vmPricingPlanId,
      'promoCode': "",
      'paypalUserName': main.paypalUserName(),
      'payPalAmountToPay': decimalFormat(main.payPalAmountToPay()),
      'totalAmountToPay': decimalFormat(main.totalAmount()),
      'provinceFromIP': main.vmCurrentIPProvince,
      'countryFromIP': main.vmCurrentIPCountry,
      'postalCodeFromIP': main.vmCurrentIPPostalCode
    };
    if (main.IsCouponValid() && main.promoAmountToRedeem() > 0 && main.couponCode() != null && main.couponCode().trim() !== "") {
      shoppingSessionParams.promoCode = main.couponCode();
    }
    shoppingSessionParams.PlatformId = storeApp.storeGlobalFunctions.getDeviceId();
    main.submitOrderLoading(true);
    callAdobeEvent("aaEventCheckOut", {});
    $.ajax({
      type: "POST",
      dataType: "json",
      url: '/Checkout/PlaceOrder',
      data: {
        'shoppingSessionParams': shoppingSessionParams
      },
      success: function success(data, textStatus, jqXHR) {
        var resultJson = $.parseJSON(data);
        resetAllmessages();
        main.submitOrderLoading(false);
        if (resultJson != null) {
          switch (resultJson.Status) {
            case 1:
              // Order submitted successfully
              var id = "";
              resultJson.TransactionId = id.concat(main.vmUserInfo().userProfileGuid, "_", main.checkoutDetailsObject.ProductId, "_", main.checkoutDetailsObject.PricingPlanId);
              //omniture tracking
              csTracking.onSuccessfulPurchase(resultJson);

              //clear promo pin session
              promoService.removePromoPinSession();
              callAdobeEvent("aaPurchaseComplete", {
                productId: main.checkoutDetailsObject.ProductId,
                purchaseType: main.purchaseType,
                orderId: resultJson.OrderId
              });
              main.OrderSuccessModalVisible(true);
              if (main.purchaseType == "Rent") {
                $("#order-success-modal").addClass("rental-modal");
              }
              $("body").addClass("modal-open");
              break;
            default:
              // Order failed
              if (resultJson.MessageCode == 8) {
                main.orderModalErrorMessage(main.vmCheckoutMessagesJson[8]);
                $("#order-failure-modal").modal("toggle");
              } else {
                main.orderModalErrorMessage(main.vmCheckoutMessagesJson[12]);
                $("#order-failure-modal").modal("toggle");
              }
              break;
          }
        } else {
          // Order failed
          main.errorMessage(main.vmCheckoutMessagesJson[12]);
          $("#order-failure-modal").modal("toggle");
        }
        $('html, body').animate({
          scrollTop: 0
        }, 'fast');
      },
      error: function error(xhr, status, _error2) {
        // Order failed
        resetAllmessages();
        main.errorMessage(main.vmCheckoutMessagesJson[12]);
        $("#order-failure-modal").modal("toggle");
        main.submitOrderLoading(false);
      }
    });
  }
  function callAdobeEvent(name, data) {
    if (window.digitalData) {
      var dd = window.digitalData;
      var newEvent = {
        callString: name,
        details: data,
        eventName: name
      };
      var events = dd.events || [];
      events.push(newEvent);
      dd.events = events;
      window.digitalData = dd;
    }
  }
  function playPurchasedMovie() {
    window.location = main.pdpUrl + main.vmProductId + '?autoplay=true&acknowledged=true';
  }
  function csgPlayPurchasedMovie() {
    window.open(main.playerContentUrl.replace('{0}', main.vmProductId).replace('{1}', main.vmPricingPlanId));
    window.location = main.pdpUrl + main.vmProductId;
  }
  function redirectToMyLibraryPage() {
    window.location = main.vmMyLibraryUrl;
  }
  function resetAllmessages() {
    main.IsErrorMessage(false);
    main.errorMessage("");
  }

  //Promo Code methods
  function onClickPromoCodeSubmitFunction() {
    main.CouponErrorMessage("");
    if (main.totalAmount() > 0 && main.couponCode().trim() !== "") {
      var couponRequestData = {
        'productId': main.vmProductId,
        'pricingPlanId': main.vmPricingPlanId,
        'couponCode': main.couponCode(),
        'userStatus': main.vmUserInfo().status,
        'userProfileGuid': main.vmUserInfo().userProfileGuid,
        'firstName': replaceHtmlCharacters(main.vmUserInfo().firstName),
        'lastName': replaceHtmlCharacters(main.vmUserInfo().lastName),
        'language': main.vmUserInfo().language,
        'email': main.vmUserInfo().email,
        'totalAmount': main.totalAmount()
      };
      if (couponRequestData !== null) {
        main.isCheckoutSubmitLoaded(false);
        $.ajax({
          type: "POST",
          dataType: "json",
          url: main.vmPromoCodeActionUrl(),
          data: couponRequestData,
          success: function success(data, textStatus, jqXHR) {
            main.IsCouponApplied(true);
            var resultJson = $.parseJSON(data);
            if (resultJson.IsValid && resultJson.Status === 1) {
              var discountAmount = parseFloat(resultJson.DiscountAmount);
              if (!isNaN(discountAmount)) {
                if (discountAmount > 0) {
                  var _couponRequestData$co;
                  var promoToRedeem = Math.min(parseFloat(discountAmount), parseFloat(main.discountedAmount));

                  //omniture tracking
                  csTracking.checkout.clickApplyPromoCode();
                  main.IsCouponValid(true);
                  main.promoAmountToRedeem(promoToRedeem);

                  //Adobe Lunch Tracking Promo Data
                  digitalData.cart.promoApplied = true;
                  digitalData.cart.promoCode = (_couponRequestData$co = couponRequestData.couponCode) !== null && _couponRequestData$co !== void 0 ? _couponRequestData$co : "";
                  main.promoSuccessMessage("\"" + resultJson.AppliedDiscounts + "\"");
                  if (!main.isUpgradeSuperTicket()) {
                    if (!main.sceneChecked()) {
                      main.productScenePoints(resultJson.UpdatedCheckoutDetails.BaseScenePoints);
                    } else main.productScenePoints(0);
                    main.productBonusPoints(0);
                  }
                } else {
                  main.IsCouponValid(false);
                  main.promoErrorMessage("\"" + main.couponCode() + "\"");
                }
              }
            } else {
              main.IsCouponValid(false);
              main.promoErrorMessage("\"" + main.couponCode() + "\"");
              if (resultJson.Message != null) {
                main.CouponErrorMessage(resultJson.Message);
              }
            }
            main.isCheckoutSubmitLoaded(true);
            if (!main.isLoaded()) main.isLoaded(true);
          },
          error: function error(xhr, status, _error3) {
            main.CouponErrorMessage(main.vmGeneralPromoCodeErrorMessage);
            main.IsCouponApplied(true);
            main.IsCouponValid(false);
            digitalData.cart.promoApplied = true;
            main.promoErrorMessage("\"" + main.couponCode() + "\"");
            main.isCheckoutSubmitLoaded(true);
            if (!main.isLoaded()) main.isLoaded(true);
          }
        });
      }
    } else if (main.couponCode().trim() === "") {
      main.IsCouponApplied(true);
      main.IsCouponValid(false);
    }
  }
  function removePromoFunction() {
    main.promoAmountToRedeem(0);
    main.IsCouponApplied(false);
    main.IsCouponValid(false);
    if (!main.sceneChecked()) {
      main.productScenePoints(main.origProductScenePoints);
      main.productBonusPoints(main.origProductBonusPoints);
    }
    main.couponCode('');
  }
  function setTitleSize() {
    if (window.innerWidth >= 600) {
      csGlobal.resizeLargeTitle($("#movie-title-desktop"), 2, "2.5rem");
    } else {
      csGlobal.resizeLargeTitle($("#movie-title-mobile"), 2, "2.5rem");
    }
  }
  function initWindowResizeFunction() {
    $(window).on("resize", function () {
      clearTimeout(main.windowResizeDebouncer);
      main.windowResizeDebouncer = setTimeout(function () {
        setTitleSize();
        setTimeout(function () {
          positionOrderSummary();
        });
      }, 500);
    });
  }
  function positionOrderSummary() {
    var $orderSummaryElem = $("#orderInfo");
    if (window.innerWidth >= 900) {
      var $elem = $(".scene-card-section");
      var offsetTop = $elem.position().top + "px";
      $orderSummaryElem.css({
        "margin-top": offsetTop
      });
    } else {
      $orderSummaryElem.css({
        "margin-top": ""
      });
    }
  }
  function getAddedCard(arrayOne, arrayTwo, compareProperty) {
    var matchedObject = arrayOne.filter(function (newCard) {
      var matched = true;
      arrayTwo.forEach(function (ogCard) {
        matched = matched && ogCard[compareProperty] !== newCard[compareProperty];
      });
      return matched;
    });
    return matchedObject;
  }
  function getQualityList() {
    var qualityPriorityList = window.CineplexAppConstants.ScreenQualityLevels;
    var qualityList = window.CineplexAppConstants.ScreenQualityList;
    var currQualityList = [];
    var currQuality = qualityPriorityList[main.screenQualityType.toLowerCase()];
    var screenQualityTypes = '(';
    qualityList.forEach(function (x) {
      var thisPriority = qualityPriorityList[x.key.toLowerCase()];
      if (thisPriority <= currQuality) {
        currQualityList[thisPriority] = x.display;
      }
    });

    //slice and reverse to get it from descending order without desctructive
    currQualityList.slice().reverse().forEach(function (x) {
      screenQualityTypes += "".concat(x, " ");
    });
    screenQualityTypes = screenQualityTypes.trim().replace(/ /g, ', ') + ")";
    return screenQualityTypes;
  }
  return main;
};