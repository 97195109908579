(function () {
  var storeApp = window.storeApp || {};
  storeApp.watchlistService = function ($) {
    return {
      addToWatchlist: addToWatchlistFunction,
      removeFromWatchlist: removeFromWatchlistFunction,
      getWatchlistedMovies: getWatchlistedMovies,
      isWatchlisted: isWatchlisted
    };
    function addToWatchlistFunction(productId, successCallback, errorCallback) {
      $.ajax({
        url: '/Watchlist/AddToWatchlist',
        data: {
          'productId': productId
        },
        method: 'POST',
        success: successCallback,
        error: errorCallback
      });
    }
    function removeFromWatchlistFunction(productId, successCallback, errorCallback) {
      $.ajax({
        url: '/Watchlist/RemoveFromWatchlist',
        data: {
          'productId': productId
        },
        method: 'POST',
        success: successCallback,
        error: errorCallback
      });
    }
    function getWatchlistedMovies(successCallback, errorCallback) {
      $.ajax({
        url: '/Watchlist/GetWatchlist',
        method: 'GET',
        success: successCallback,
        error: errorCallback
      });
    }
    function isWatchlisted(productId, successCallback, errorCallback) {
      $.ajax({
        url: '/Watchlist/IsWatchlisted',
        data: {
          'productId': productId
        },
        method: 'POST',
        success: successCallback,
        error: errorCallback
      });
    }
  }(jQuery);
})();