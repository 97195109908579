(function () {
  var storeApp = window.storeApp || {};
  storeApp.promoService = function ($) {
    return {
      validatePromo: validatePromo,
      dismissPromo: dismissPromo,
      removePromoPinSession: removePromoPinSession
    };
    function validatePromo(productId, pricingPlanId, successCallback, errorCallback) {
      var requestObject = {
          product: productId,
          pricingPlanId: pricingPlanId
        },
        requestUrl = "/Layout/GetPromoAutoRedeem?" + $.param(requestObject);
      $.ajax({
        url: requestUrl,
        type: 'GET',
        success: successCallback,
        error: errorCallback
      });
    }
    function dismissPromo(successCallback, errorCallback) {
      $.ajax({
        url: "Layout/ShowHidePromoBanner",
        type: 'POST',
        data: {
          ShowPromoBanner: false
        }
      });
    }
    function removePromoPinSession(successCallback, errorCallback) {
      $.ajax({
        url: "/Layout/RemovePromoAutoRedeem",
        type: 'POST'
      });
    }
  }(jQuery);
})();