(function () {
  var storeApp = window.storeApp || {};
  storeApp.videoPlayerService = function ($) {
    //for movie
    var main = {
      playerInstance: null,
      productInfo: {},
      connectInfo: {},
      restartContentPercentage: 95,
      analyticsData: {}
    };
    return {
      initPlayer: initPlayer,
      playMedia: playMedia,
      playTrailer: playTrailer
    };
    function initPlayer(productInfo, restartContentPercentage, sessionToken, connectGuid, userSessionToken, analyticsData) {
      connectGuid = connectGuid ? connectGuid : null;
      userSessionToken = userSessionToken ? userSessionToken : null;
      main.productInfo = productInfo;
      main.connectInfo.sessionToken = sessionToken;
      if (connectGuid) main.connectInfo.connectId = connectGuid;
      if (userSessionToken) main.connectInfo.userSessionToken = userSessionToken;
      main.restartContentPercentage = restartContentPercentage;
      main.analyticsData = analyticsData;
    }
    function playMedia(pricingPlanId, movieCloseHandler) {
      var playbackStartTime = main.productInfo.playbackProgressSeconds;
      //check if we've crossed the restart threshold
      if (playbackStartTime < 0 || main.productInfo.playbackProgressPercentage >= main.restartContentPercentage) {
        playbackStartTime = 0;
        main.productInfo.playbackProgressPercentage = 0;
      }
      var options = {
        width: window.innerWidth,
        height: window.innerHeight,
        autoPlay: true,
        autoResize: true,
        productId: main.productInfo.id,
        pricingPlanId: pricingPlanId,
        title: main.productInfo.name,
        thumbnailUrl: main.productInfo.thumbnailUrl,
        imageUrl: main.productInfo.imageUrl,
        startTime: playbackStartTime
      };
      var closeHandler = function closeHandler() {
        onBackButtonClicked();
        var playbackInfo = {
          productId: main.productInfo.id,
          pricingPlanId: pricingPlanId,
          progressSeconds: main.playerInstance.playerInstance.state.currentTime
        };
        movieCloseHandler(playbackInfo);
      };
      initAndPlayMedia(options, closeHandler);
    }
    function playTrailer() {
      var options = {
        width: window.innerWidth,
        height: window.innerHeight,
        autoPlay: true,
        autoResize: true,
        productId: main.productInfo.id,
        previewId: main.productInfo.previewId,
        title: main.productInfo.name,
        thumbnailUrl: main.productInfo.thumbnailUrl,
        imageUrl: main.productInfo.imageUrl
      };
      initAndPlayMedia(options, onBackButtonClicked);
    }
    function initAndPlayMedia(playerOptions, playerCloseHandler) {
      cpxMediaPlayer.setConnectSettings(getConnectInfo());
      var playerId = 'cpxPlayerContainer'; //same as the div id for player
      var platformId = getPlatform();
      //Sets and returns analytics metadata based on playerOptions
      if (!main.playerInstance) {
        //create and embed player
        main.playerInstance = cpxMediaPlayer.embedPlayer(playerId, platformId, playerOptions);
        //Register events, this is an example for the back button event:
        main.playerInstance.addEventListener(cpxMediaPlayer.MediaPlayerEvents.BACK_CLICKED, playerCloseHandler);
        main.playerInstance.addEventListener(cpxMediaPlayer.MediaPlayerEvents.ENDED, playerCloseHandler);
        main.playerInstance.addEventListener(cpxMediaPlayer.MediaPlayerEvents.OVERLAY_CLOSED, playerCloseHandler);
        //Set Analytics data on the PlayerInstance
        setAnalyticsData(main.playerInstance.setAnalyticsData, playerOptions);
        //Call this to initialize and playback content
        main.playerInstance.init();
      } else {
        //Set Analytics data on the PlayerInstance
        setAnalyticsData(main.playerInstance.setAnalyticsData, playerOptions);
        main.playerInstance.playMedia(playerOptions);
      }
      document.getElementById("cpx-player").style.display = "block";
    }
    function onBackButtonClicked() {
      closePlayer();
      document.getElementById("cpx-player").style.display = "none";
    }
    function closePlayer(trackPercentage) {
      if (main.playerInstance) {
        //stops playback and unload the content
        main.playerInstance.stopMedia();
        if (trackPercentage) {
          main.productInfo.playbackProgressSeconds = main.playerInstance.playerInstance.state.currentTime;
        }
      }
    }
    function getPlatform() {
      var browser = detectBrowser();
      var os = detectOs();
      return "web-" + os + "-" + browser;
    }
    function detectBrowser() {
      var sBrowser,
        sUsrAg = navigator.userAgent;
      if (sUsrAg.indexOf("Firefox") > -1) {
        sBrowser = "firefox"; //"Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
      } else if (sUsrAg.indexOf("Opera") > -1) {
        sBrowser = "opera";
      } else if (sUsrAg.indexOf("Trident") > -1) {
        sBrowser = "ie";
        //"Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
      } else if (sUsrAg.indexOf("Edge") > -1) {
        sBrowser = "ie";
        //"Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
      } else if (sUsrAg.indexOf("Chrome") > -1) {
        sBrowser = "chrome";
        //"Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
      } else if (sUsrAg.indexOf("Safari") > -1) {
        sBrowser = "safari";
        //"Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
      } else {
        sBrowser = "chrome"; //default
      }
      return sBrowser;
    }
    function detectOs() {
      var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null;
      if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'mac';
      } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'ios';
      } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'pc'; //windows
      } else if (/Android/.test(userAgent)) {
        os = 'android';
      } else if (!os && /Linux/.test(platform)) {
        os = 'linux';
      } else {
        os = "pc"; //default
      }
      return os;
    }

    //Tries to update the user's connect info in case they've logged in since this service was initialized
    function getConnectInfo() {
      if (main.connectInfo.connectId == 'NA') {
        main.connectInfo.connectId = getUserConnectGuid();
      }
      if (main.connectInfo.userSessionToken == 'NA') {
        main.connectInfo.userSessionToken = getUserSessionToken();
      }
      return main.connectInfo;
    }
    function getUserConnectGuid() {
      var connectId = Cineplex.Connect.GetUserStatus().UserProfileGuid;
      if (connectId != "") {
        return connectId;
      } else {
        return "";
      }
    }
    function getUserSessionToken() {
      var pattern = RegExp('CCToken' + "=.[^;]*");
      var matched = document.cookie.match(pattern);
      if (matched) {
        var cookie = matched[0].split('=');
        if (cookie != undefined) {
          var cctoken = cookie[1];
          return cctoken;
        }
      }
      return "";
    }

    /**
     * Send analytics data to playerInstance in two separate calls
     * One call to send the Scene number and is obtained via Async http call
     * One call to send the rest of the Analytics Data
     * @param {any} setFn - playerInstance function to setAnalyticsData
     * @param {any} playerOptions - playerOptions to determine AnalyticsData
     */
    function setAnalyticsData(setFn, playerOptions) {
      var _playerOptions$previe, _playerOptions$pricin;
      //This duplicates the call to set analytics data but 
      //prevents blocking from slow http calls. 
      //setFn should be nondestructive so order does not matter.
      if (digitalData.user.account.loyalityID) {
        main.analyticsData.sceneId = digitalData.user.account.loyalityID;
      } else {
        ConnectApp.ConnectModule.getSceneId().then(function (x) {
          digitalData.user.account.loyalityID = x !== null && x !== void 0 ? x : "";
          if (x) {
            setFn({
              sceneId: x
            });
          }
        });
      }

      //Sets parameters based on playerOptions
      main.analyticsData.isTrailer = parseInt(playerOptions.previewId) > 0;
      main.analyticsData.previewId = (_playerOptions$previe = playerOptions.previewId) !== null && _playerOptions$previe !== void 0 ? _playerOptions$previe : "NA";
      main.analyticsData.pricingPlanId = (_playerOptions$pricin = playerOptions.pricingPlanId) !== null && _playerOptions$pricin !== void 0 ? _playerOptions$pricin : "NA";
      setFn(main.analyticsData);
    }
  }(jQuery);
})();