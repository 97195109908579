(function () {
  var storeApp = window.storeApp || {};
  storeApp.digitalCopyService = function ($) {
    var main = {
      redeemCode: redeemCodeFunction
    };
    function redeemCodeFunction(codeToRedeem, productId, redeemDataUrl, token, callback) {
      if (codeToRedeem != null && codeToRedeem !== "") {
        $.ajax({
          url: redeemDataUrl,
          type: 'POST',
          data: {
            __RequestVerificationToken: token,
            RedeemCode: codeToRedeem,
            ProviderId: 32,
            ProductId: productId
          },
          success: function success(result) {
            callback(result);
          },
          error: function error(httpObj) {
            callback({
              Status: 2
            });
          }
        });
      } else {
        callback({
          Status: 1
        });
      }
    }
    return main;
  }(jQuery);
})();