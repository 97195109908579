ko.bindingHandlers.hiddenInlineText = {
  update: function update(element, valueAccessor) {
    var hidden = ko.unwrap(valueAccessor()),
      elementVisible = element.style.display !== "none";
    if (hidden && elementVisible) {
      element.style.display = "none";
    } else {
      element.style.display = "inline";
    }
  }
};