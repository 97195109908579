(function () {
  var storeApp = window.storeApp || {};
  storeApp.myLibraryVM = function (ko, $, moment, bowser, connect, csGlobal, csTracking, videoPlayerService) {
    var vm = this,
      rentExpiryDaysText,
      rentExpiryMinsText,
      rentExpiryHoursText;
    vm.init = initializeMyLibrary;
    function initializeMyLibrary(model) {
      //Set values for variables used in instantiation
      rentExpiryDaysText = model.rentExpiryDaysText;
      rentExpiryMinsText = model.rentExpiryMinsText;
      rentExpiryHoursText = model.rentExpiryHoursText;
      var rentalMovies = mapRentalMovieObjects(model.rentalMovies),
        purchasedMovies = mapPurchasedMovieObjects(model.purchasedMovies).filter(function (movies) {
          return movies.IsSeries === false;
        }),
        purchasedShows = mapPurchasedMovieObjects(model.purchasedMovies).filter(function (shows) {
          return shows.IsSeries === true;
        }),
        downloaderLink = bowser.mac ? model.storeDownlaoderIOSLink : model.storeDownloaderWindowsLink,
        appDownloaderLink = bowser.ios ? model.iOSAppLink : model.androidAppLink;
      vm.isMobileOrTablet = ko.observable(Boolean(bowser.mobile) || Boolean(bowser.tablet));
      vm.unsupportedOS = ko.observable(Boolean(bowser.windowsphone) || Boolean(bowser.linux) || Boolean(bowser.chromeos) || Boolean(bowser.webos) || Boolean(bowser.tizen) || Boolean(bowser.sailfish) || Boolean(bowser.tablet) && Boolean(bowser.windows));
      vm.isLoaded = ko.observable(false);
      vm.playerContentUrl = model.playerContentUrl;
      vm.selectedSort = ko.observable("0");
      vm.rentalMoviesRaw = ko.observableArray(rentalMovies);
      vm.purchasedMoviesRaw = ko.observableArray(purchasedMovies);
      vm.purchasedShowsRaw = ko.observableArray(purchasedShows);
      vm.rentalMovieSelected = ko.observable(null);
      vm.downloaderLink = ko.observable(downloaderLink);
      vm.appDownloaderLink = ko.observable(appDownloaderLink);
      vm.userConnectGuid = model.userConnectGuid;
      vm.userSessionToken = model.userSessionToken;
      vm.sessionToken = model.sessionToken;
      vm.restartPlaybackThreshold = model.restartPlaybackThreshold;
      vm.rentalMovies = ko.computed(function () {
        if (parseInt(vm.selectedSort())) {
          var resultSort = [].concat(vm.rentalMoviesRaw());
          return resultSort.sort(filmAlphabeticalSort);
        } else {
          var resultSort = [].concat(vm.rentalMoviesRaw());
          return resultSort.sort(filmDateSort);
        }
      });
      vm.purchasedMovies = ko.computed(function () {
        if (parseInt(vm.selectedSort())) {
          var resultSort = [].concat(vm.purchasedMoviesRaw());
          return resultSort.sort(filmAlphabeticalSort);
        } else {
          var resultSort = [].concat(vm.purchasedMoviesRaw());
          return resultSort.sort(filmDateSort);
        }
      });
      vm.purchasedShows = ko.computed(function () {
        if (parseInt(vm.selectedSort())) {
          var resultSort = [].concat(vm.purchasedShowsRaw());
          return resultSort.sort(filmAlphabeticalSort);
        } else {
          var resultSort = [].concat(vm.purchasedShowsRaw());
          return resultSort.sort(filmDateSort);
        }
      });
      vm.isMoviePreorder = isMoviePreorder;
      vm.playbackVisible = playbackVisible;
      vm.setPlaybackWidth = setPlaybackWidth;
      vm.showUpgradeToHD = showUpgradeToHD;
      vm.upgradeToHD = upgradeToHD;
      vm.constructPdpUrl = constructPdpUrl;

      //csg functions
      vm.playMovie = playMovie;
      vm.playRentalMovie = playRentalMovie;
      vm.startRentalMovieFirstTime = startRentalMovieFirstTime;
      //new
      vm.constructPlayMovieUrl = constructPlayMovieUrl;
      //end
      vm.generateRentalConfirmTitle = generateRentalConfirmTitle;
      vm.checkoutUrl = model.checkoutUrl;
      vm.pdpUrl = model.pdpUrl;
      vm.isLoaded = ko.observable(true);
    }
    function filmAlphabeticalSort(movie1, movie2) {
      var title1 = movie1.FilmTitle.toLowerCase(),
        title2 = movie2.FilmTitle.toLowerCase();
      if (title1 > title2) {
        return 1;
      } else if (title1 < title2) {
        return -1;
      } else {
        return 0;
      }
    }
    function filmDateSort(movie1, movie2) {
      var date1 = movie1.PurchaseDateTime,
        date2 = movie2.PurchaseDateTime;
      if (date1 > date2) {
        return -1;
      } else if (date1 < date2) {
        return 1;
      } else {
        return 0;
      }
    }
    function isMoviePreorder(releaseDate, isComingSoon) {
      return !moment().isAfter(releaseDate) && isComingSoon;
    }
    function playbackVisible(playbackPercent) {
      return playbackPercent > 0;
    }
    function setPlaybackWidth(playbackPercent) {
      return playbackPercent + "%";
    }
    function showUpgradeToHD(upgradeToHDCode) {
      return upgradeToHDCode > 0;
    }
    function upgradeToHD(productId, upgradeToHDCode) {
      return vm.checkoutUrl + productId + "?PricingPlanId=" + upgradeToHDCode;
    }
    function generateRentalConfirmTitle(rentalTitleText, movieTitle) {
      return rentalTitleText.replace("{0}", movieTitle);
    }
    function constructPdpUrl(productId) {
      return vm.pdpUrl + productId;
    }

    //old block
    function startRentalMovieFirstTime(rentalMovie) {
      playMovie(rentalMovie.CSGProductId, rentalMovie.CSGPricingPlanId);
      rentalMovie.rentalExpiryText(setRentalExpiryText(moment().add(2, 'days')));
      rentalMovie.IsWatching(true);
      $("#rental-confirm-modal").modal("hide");
    }
    function playRentalMovie(rentalMovie) {
      vm.rentalMovieSelected(rentalMovie);
      $("#rental-confirm-modal").modal("show");
    }
    function playMovie(productId, pricingPlan) {
      window.open(vm.playerContentUrl.replace('{0}', productId).replace('{1}', pricingPlan));
    }
    //new block
    function constructPlayMovieUrl(productId) {
      return constructPdpUrl(productId) + "?autoplay=true";
    }
    //end

    function mapRentalMovieObjects(rentalMovieArray) {
      var mappedArray = [];
      rentalMovieArray.forEach(function (rentalMovie) {
        mappedArray.push(new rentalMovieObject(rentalMovie));
      });
      return mappedArray;
    }
    function mapPurchasedMovieObjects(purchasedMovieArray) {
      var mappedArray = [];
      purchasedMovieArray.forEach(function (rentalMovie) {
        mappedArray.push(new purchasedMovieObject(rentalMovie));
      });
      return mappedArray;
    }
    function rentalMovieObject(configObject) {
      this.AvailableDateTime = configObject.availableDateTime;
      this.ContentProgressPercentage = configObject.contentProgressPercentage;
      this.ContentProgressSeconds = configObject.contentProgressSeconds;
      this.CSGLockerId = configObject.csgLockerId;
      this.CSGPricingPlanId = configObject.csgPricingPlanId;
      this.CSGPricingPlanName = configObject.csgPricingPlanName;
      this.CSGProductContextId = configObject.csgProductContextId;
      this.CSGProductId = configObject.csgProductId;
      this.ExpiryDateTime = configObject.expiryDateTime;
      this.FilmDescription = configObject.filmDescription;
      this.FilmImageURL = configObject.filmImageURL;
      this.FilmRating = configObject.filmRating;
      this.FilmTitle = configObject.filmTitle;
      this.FilmWatchedInMinute = configObject.filmWatchedInMinute;
      this.FirstAccessDateTime = configObject.firstAccessDateTime;
      this.IsComingSoon = configObject.isComingSoon;
      this.IsWatching = ko.observable(configObject.isWatching);
      this.PurchaseDateTime = configObject.purchaseDateTime;
      this.ScreenQualityType = configObject.screenQualityType;
      this.WatchBeginDateTime = configObject.watchBeginDateTime;
      this.rentalExpiryText = ko.observable(setRentalExpiryText(configObject.expiryDateTime));
    }
    function purchasedMovieObject(configObject) {
      this.AvailableDateTime = configObject.availableDateTime;
      this.ContentProgressPercentage = configObject.contentProgressPercentage;
      this.ContentProgressSeconds = configObject.contentProgressSeconds;
      this.CSGLockerId = configObject.csgLockerId;
      this.CSGPricingPlanId = configObject.csgPricingPlanId;
      this.CSGPricingPlanName = configObject.csgPricingPlanName;
      this.CSGProductContextId = configObject.csgProductContextId;
      this.CSGProductId = configObject.csgProductId;
      this.DigitalPurchaseType = configObject.digitalPurchaseType;
      this.FilmDescription = configObject.filmDescription;
      this.FilmImageURL = configObject.filmImageURL;
      this.FilmRating = configObject.filmRating;
      this.FilmTitle = configObject.filmTitle;
      this.FilmWatchedInMinute = configObject.filmWatchedInMinute;
      this.IsComingSoon = configObject.isComingSoon;
      this.IsWatching = configObject.isWatching;
      this.IsUV = configObject.isUV;
      this.IsSeries = configObject.isSeries;
      this.LastViewedDateTime = configObject.lastViewedDateTime;
      this.PurchaseDateTime = configObject.purchaseDateTime;
      this.ScreenQualityType = configObject.screenQualityType;
      this.UpgradeToHDPricingPlanID = configObject.upgradeToHDPricingPlanID;
    }
    function setRentalExpiryText(expiryDateTime) {
      var today = moment();
      var expiryDate = moment(expiryDateTime);
      //var expiryDate = moment(expiryDateTime);
      var totalMinutes = expiryDate.diff(today, 'minutes');
      //var totalMinutes = moment.duration(expiryDateTime, 'minutes');
      if (totalMinutes > 2880) {
        var expDays = Math.round(totalMinutes / 1440);
        //return initModel.expiryDateText.replace("{0}", expDate.format("MMM D, YYYY h:mm A"));
        return rentExpiryDaysText.replace("{0}", expDays.toString());
      } else if (totalMinutes > 120) {
        return rentExpiryHoursText.replace("{0}", Math.round(totalMinutes / 60).toString());
      } else {
        return rentExpiryMinsText.replace("{0}", totalMinutes.toString());
      }
    }
    return vm;
  };
})();