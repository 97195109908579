function ProductPricingPlan(rewardData, chargeAmt, discountedAmt, isStandalone, pricingPlanDisplayName, pricingPlanId, baseScenePointValue, bonusScenePoints, screenQualityType, contentType, isPurchasedByUser, expiresInHours, digitalPurchaseType, availabilityStart, availabilityEnd, isVip, relevantProductId, isSuperTicketUpgrade, isSuperTicketHDUpgrade, pricingPlanType) {
  this.RewardData = rewardData;
  this.ChargeAmount = chargeAmt;
  this.DiscountedAmount = discountedAmt;
  this.IsStandalone = isStandalone;
  this.PricingPlanDisplayName = pricingPlanDisplayName;
  this.PricingPlanId = pricingPlanId;
  this.BaseScenePoints = baseScenePointValue;
  this.BonusScenePoints = bonusScenePoints;
  this.ScreenQualityType = screenQualityType;
  this.ContentType = contentType;
  this.IsPurchasedByUser = isPurchasedByUser;
  this.ExpiresInHours = expiresInHours;
  this.DigitalPurchaseType = digitalPurchaseType;
  this.AvailabilityStart = availabilityStart;
  this.AvailabilityEnd = availabilityEnd;
  this.IsVip = typeof isVip !== "undefined" && isVip == true;
  this.RelevantProductId = relevantProductId;
  this.isSuperTicketUpgrade = isSuperTicketUpgrade;
  this.isSuperTicketHDUpgrade = isSuperTicketHDUpgrade;
  this.showBaseScenePoints = this.BaseScenePoints > 0 && !this.RewardData;
  this.showBonusScenePoints = !!this.RewardData && this.BonusScenePoints > 0;
  this.hasDiscount = this.ChargeAmount !== this.DiscountedAmount;
  this.PricingPlanType = pricingPlanType;
}