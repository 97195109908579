var storeApp = window.storeApp || {};
storeApp.pdpViewModel = function (ko, $, momentJs, bowser, dataModule, redeemDataModule, pricingService, connectModule, csTracking, csGlobal, videoPlayerService) {
  "use strict";

  //Encapsulate what we wish to reveal outside in this model
  var main = {
    //constant values
    titleMaxLines: 1,
    twoLineTitleSize: "0.8em",
    //properties
    productId: 0,
    previewId: 0,
    thumbnailUrl: "",
    imageUrl: "",
    productDataAjaxUrl: null,
    redeemDataAjaxUrl: null,
    playerContentUrl: null,
    languageSetting: "English",
    checkoutUrl: "",
    trailerUrl: "",
    hasTrailer: false,
    connectLoginPromptVisible: ko.observable(false),
    entitledPricingPlan: ko.observable(null),
    entitledPricingPlanId: ko.observable(0),
    IsRedeemedForEntitledPricingPlan: ko.observable(false),
    dcInvalidAddress: ko.observable(false),
    dcInvalidCode: ko.observable(false),
    dcServerError: ko.observable(false),
    dcRedeemSuccess: ko.observable(false),
    dcInvalidLogin: ko.observable(false),
    isFirstAccessToStream: false,
    isGeneric: ko.observable(false),
    isGenericMobile: ko.observable(false),
    productOrderStatus: "",
    synopsisFits: ko.observable(false),
    pricingModalVisible: ko.observable(false),
    //IsHomePremiere
    IsHomePremiere: ko.observable(false),
    //public functions
    init: initFunction,
    playTrailer: playTrailerFunction,
    startContent: startContentFunction,
    startRentalContent: startRentalContentFunction,
    playRentalFirstTime: playRentalFirstTimeFunction,
    startBestAvailableContent: startBestAvailableContentFunction,
    csgPlayTrailer: csgPlayTrailerFunction,
    csgStartContent: csgStartContentFunction,
    csgStartRentalContent: csgStartRentalContentFunction,
    csgPlayRentalFirstTime: csgPlayRentalFirstTimeFunction,
    csgStartBestAvailableContent: csgStartBestAvailableContentFunction,
    proceedToCheckout: proceedToCheckoutFunction,
    relatedItem: relatedItemFunction,
    expiryDate: "",
    //DC Redeem
    redeemCode: ko.observable(""),
    tryRedeemDc: tryRedeemDcFunction,
    dCLoaderVisible: ko.observable(false),
    showWatchTrailerButton: ko.observable(true),
    clearDCPlusField: clearDCPlusFieldFunction,
    //new pdp additions
    showMoreContent: ko.observable(false),
    shortenedReadMoreSection: ko.observable([]),
    actualReadMoreSection: $(".pdp-wrapper .pdp-movie-details .pdp-main-synopsis").text(),
    displayMoreInfo: displayMoreInfoFunction,
    isLoaded: ko.observable(false),
    windowResizeTimeout: null,
    buyHarmoPricingPlan: ko.observable(null),
    buyUHDPricingPlan: ko.observable(null),
    buyHDPricingPlan: ko.observable(null),
    buySDPricingPlan: ko.observable(null),
    rentHarmoPricingPlan: ko.observable(null),
    rentUHDPricingPlan: ko.observable(null),
    rentHDPricingPlan: ko.observable(null),
    rentSDPricingPlan: ko.observable(null),
    preorderHarmoPricingPlan: ko.observable(null),
    preorderHDPricingPlan: ko.observable(null),
    preorderSDPricingPlan: ko.observable(null),
    superTicketUpgradeToHDPlan: ko.observable(null),
    togglePricingModal: togglePricingModal,
    pricingModalClose: pricingModalClose,
    connectFailedLoginCallback: connectFailedLoginCallback,
    userConnectGuid: "",
    sessionToken: "",
    userSessionToken: "",
    restartPlaybackThreshold: 0,
    contentProgressSeconds: 0,
    contentProgressPercentage: 0,
    acknowledged: false
  };
  var contentTypeEnum = {
    None: 0,
    All: 1,
    Rental: 2,
    DigitalPurchase: 3
  };
  var digitalPurchaseTypeEnum = {
    None: 0,
    PreOrder: 1,
    SuperTicket: 2,
    DCPlus: 3
  };
  var screenQualityTypeEnum = {
    None: 0,
    All: 1,
    HD: 2,
    SD: 3,
    UHD: 4
  };
  var pricingPlanType = {
    None: 0,
    Single: 1,
    Harmonize: 2
  };
  function findScreenQualityType(qualityNumber) {
    var key;
    for (key in screenQualityTypeEnum) {
      if (screenQualityTypeEnum[key] == qualityNumber) {
        return key;
      }
    }
    return undefined;
  }
  main.redeemCode.subscribe(function (value) {
    clearRedeemStatus();
  });
  return main;
  function initFunction(serverDataObject, initModel) {
    var _serverDataObject$Pro, _initModel$userConnec, _window$digitalData$u, _initModel$isUserLogg, _initModel$movieProdu, _initModel$languageCo, _initModel$movieProdu2, _window$digitalData$t, _window$digitalData$t2, _window$digitalData$t3, _window$digitalData$t4, _initModel$movieProdu3, _serverDataObject$Ent, _serverDataObject$Ent2, _serverDataObject$Ent3, _serverDataObject$Ent4, _serverDataObject$Ent5, _serverDataObject$Ent6, _serverDataObject$Ent7, _initModel$movieGenre;
    //Used to append old connect close function to new one
    var connectOldCloseFunc1 = connectModule.Settings.Close;
    connectModule.Settings.Close = function () {
      connectOldCloseFunc1();
      if (connectModule.IsUserLoggedIn()) {
        updatePricingInfo(initModel.productId, initModel.productDataUrl);
      }
    };
    var oldConnectInitCallback = window.connectInitializedCallback;
    window.connectInitializedCallback = function () {
      oldConnectInitCallback();
      checkPlayOnPageLoad();
    };
    main.isMobileOrTablet = Boolean(bowser.mobile) || Boolean(bowser.tablet);
    main.mobileDownloadLink = bowser.ios ? initModel.iOSAppLink : initModel.androidAppLink;
    main.trailerUrl = initModel.trailerUrl;
    main.hasTrailer = parseInt(initModel.trailerUrl.substring(initModel.trailerUrl.lastIndexOf('/') + 1)) > 0;
    main.productDataAjaxUrl = initModel.productDataUrl;
    main.redeemDataAjaxUrl = initModel.redeemDataUrl;
    main.playerContentUrl = initModel.playerContentUrl;
    main.languageSetting = initModel.language;
    main.fullTitleText = $(".pdp-wrapper .pdp-movie-information .pdp-movie-title").text();
    main.productId = initModel.productId;
    main.previewId = initModel.previewId;
    main.thumbnailUrl = initModel.thumbnailUrl;
    main.imageUrl = initModel.imageUrl;
    main.isGeneric(initModel.isGeneric);
    main.isGenericMobile(initModel.isGenericMobile);
    main.checkoutUrl = initModel.checkoutUrl;
    main.userConnectGuid = initModel.userConnectGuid;
    main.sessionToken = initModel.sessionToken;
    main.userSessionToken = initModel.userSessionToken;
    main.contentProgressPercentage = initModel.contentProgressPercentage;
    main.contentProgressSeconds = initModel.contentProgressSeconds;
    main.restartPlaybackThreshold = initModel.restartPlaybackThreshold;
    //IsHomePremiere
    main.IsHomePremiere = initModel.IsHomePremiere;
    main.productName = initModel.productName;
    main.releaseYear = initModel.releaseYear;
    main.mpaaRating = initModel.mpaaRating;
    main.isGenericMobile = initModel.isGenericMobile;
    main.mobileMarqueeSrcSet = initModel.mobileMarqueeSrcSet;
    main.mobileImgSrc = initModel.mobileImgSrc;
    main.isBundle = initModel.bundleItems && initModel.bundleItems !== "";
    main.pricingModalModel = {
      rentSdOrHdText: initModel.rentSdOrHdText,
      rentUHDButtonUnavailableText: initModel.rentUHDButtonUnavailableText,
      rentHDUnavailableText: initModel.rentHDUnavailableText,
      rentSDUnavailableText: initModel.rentSDUnavailableText,
      noRentalPricingPlansAvailableText: initModel.noRentalPricingPlansAvailableText,
      buySdOrHdText: initModel.buySdOrHdText,
      buyUHDUnavailableText: initModel.buyUHDUnavailableText,
      buyHDUnavailableText: initModel.buyHDUnavailableText,
      buySDUnavailableText: initModel.buySDUnavailableText,
      noBuyPlansAvailableText: initModel.noBuyPlansAvailableText,
      preOrderSDOrHDText: initModel.preOrderSDOrHDText,
      baseScenePtsTxt: initModel.modalBaseSceneText,
      bonusScenePtsTxt: initModel.modalBonusSceneText,
      connectModule: connectModule,
      checkoutUrl: initModel.checkoutUrl,
      harmonizedPricingMessage: initModel.harmonizedPricingMessage,
      hdText: initModel.hdText,
      sdText: initModel.sdText
    };

    //Building object for Analytics tracking on Web Player
    var analyticsStringCheck = function analyticsStringCheck(str) {
      return str && str !== "" ? str : "";
    };
    var analyticsNumCheck = function analyticsNumCheck(num) {
      return num && num !== 0 ? num : "";
    };
    main.playerAnalyticsData = {
      productId: (_serverDataObject$Pro = serverDataObject.ProductId) !== null && _serverDataObject$Pro !== void 0 ? _serverDataObject$Pro : "",
      platformId: window.digitalData.technology.platform,
      dtmPagename: "pdp",
      connectId: (_initModel$userConnec = initModel.userConnectGuid) !== null && _initModel$userConnec !== void 0 ? _initModel$userConnec : "",
      sceneId: window.digitalData.user && window.digitalData.user.account ? (_window$digitalData$u = window.digitalData.user.account.loyalityID) !== null && _window$digitalData$u !== void 0 ? _window$digitalData$u : "" : "",
      isLoggedIn: (_initModel$isUserLogg = initModel.isUserLoggedIn) !== null && _initModel$isUserLogg !== void 0 ? _initModel$isUserLogg : "",
      pageName: (_initModel$movieProdu = initModel.movieProductName) !== null && _initModel$movieProdu !== void 0 ? _initModel$movieProdu : "",
      language: (_initModel$languageCo = initModel.languageCode) !== null && _initModel$languageCo !== void 0 ? _initModel$languageCo : "",
      siteSection: (_initModel$movieProdu2 = initModel.movieProductName) !== null && _initModel$movieProdu2 !== void 0 ? _initModel$movieProdu2 : "",
      platform: (_window$digitalData$t = window.digitalData.technology.platform) !== null && _window$digitalData$t !== void 0 ? _window$digitalData$t : "",
      deviceName: (_window$digitalData$t2 = window.digitalData.technology.deviceName) !== null && _window$digitalData$t2 !== void 0 ? _window$digitalData$t2 : "",
      deviceModel: (_window$digitalData$t3 = window.digitalData.technology.deviceModel) !== null && _window$digitalData$t3 !== void 0 ? _window$digitalData$t3 : "",
      appVersion: (_window$digitalData$t4 = window.digitalData.technology.appVersion) !== null && _window$digitalData$t4 !== void 0 ? _window$digitalData$t4 : "",
      productName: (_initModel$movieProdu3 = initModel.movieProductName) !== null && _initModel$movieProdu3 !== void 0 ? _initModel$movieProdu3 : "",
      contentCategory: (_serverDataObject$Ent = (_serverDataObject$Ent2 = serverDataObject.EntitledPricingPlan) === null || _serverDataObject$Ent2 === void 0 ? void 0 : _serverDataObject$Ent2.ContentType) !== null && _serverDataObject$Ent !== void 0 ? _serverDataObject$Ent : "",
      productCategory: (_serverDataObject$Ent3 = (_serverDataObject$Ent4 = serverDataObject.EntitledPricingPlan) === null || _serverDataObject$Ent4 === void 0 ? void 0 : _serverDataObject$Ent4.ContentType) !== null && _serverDataObject$Ent3 !== void 0 ? _serverDataObject$Ent3 : "",
      contentType: (_serverDataObject$Ent5 = (_serverDataObject$Ent6 = serverDataObject.EntitledPricingPlan) === null || _serverDataObject$Ent6 === void 0 ? void 0 : _serverDataObject$Ent6.ScreenQualityType) !== null && _serverDataObject$Ent5 !== void 0 ? _serverDataObject$Ent5 : "",
      productType: analyticsNumCheck((_serverDataObject$Ent7 = serverDataObject.EntitledPricingPlan) === null || _serverDataObject$Ent7 === void 0 ? void 0 : _serverDataObject$Ent7.ScreenQualityType),
      genre: (_initModel$movieGenre = initModel.movieGenre) !== null && _initModel$movieGenre !== void 0 ? _initModel$movieGenre : "",
      studio: analyticsStringCheck(initModel.movieStudio)
    };
    // ADOBE DTM parameteres
    if (digitalData !== null || digitalData !== 'undefined') {
      var _initModel$movieProdu4, _serverDataObject$Pro2, _serverDataObject$Pro3, _initModel$movieStudi, _serverDataObject$Pro4, _initModel$movieProdu5, _qualityType$filter, _qualityType$filter2, _initModel$movieGenre2, _initModel$movieProdu6, _serverDataObject$Ses;
      digitalData.event.eventName = "Product Details";
      digitalData.event.callString = "";
      digitalData.page.pageInfo.pageName = "pdp";
      digitalData.product.productName = (_initModel$movieProdu4 = initModel.movieProductName) !== null && _initModel$movieProdu4 !== void 0 ? _initModel$movieProdu4 : "";
      digitalData.product.productID = (_serverDataObject$Pro2 = serverDataObject.ProductId) !== null && _serverDataObject$Pro2 !== void 0 ? _serverDataObject$Pro2 : "";
      digitalData.product.productCategory = serverDataObject.BuyPricingPlans.length > 0 ? serverDataObject.BuyPricingPlans[0].ContentType : "";

      // checking all available pricing plans
      var buyPricing = serverDataObject.BuyPricingPlans.map(function (element) {
        return element.ChargeAmount;
      });
      var rentPricing = serverDataObject.RentPricingPlans.map(function (element) {
        return element.ChargeAmount;
      });
      var preOrderPricing = serverDataObject.PreOrderPricingPlans.map(function (element) {
        return element.ChargeAmount;
      });
      var availablePrices = buyPricing.concat(rentPricing, preOrderPricing);
      digitalData.product.productPrice = availablePrices !== null && availablePrices !== void 0 ? availablePrices : "";

      // checking all available qualities inside each price plans
      var qualityType = (_serverDataObject$Pro3 = serverDataObject.ProductDetailPageInfoList.map(function (Element) {
        return Element.PricingPlanDisplayName;
      })) !== null && _serverDataObject$Pro3 !== void 0 ? _serverDataObject$Pro3 : "";
      var buyQuality = serverDataObject.BuyPricingPlans.map(function (element) {
        return element.PricingPlanName;
      });
      var rentQuality = serverDataObject.RentPricingPlans.map(function (element) {
        return element.PricingPlanName;
      });
      var preOrderQuality = serverDataObject.PreOrderPricingPlans.map(function (element) {
        return element.PricingPlanName;
      });
      var qualityAvailable = buyQuality.concat(rentQuality, preOrderQuality);
      digitalData.product.productType = qualityAvailable !== null && qualityAvailable !== void 0 ? qualityAvailable : "";
      digitalData.product.productQuantity = 1;
      digitalData.content.studio = (_initModel$movieStudi = initModel.movieStudio) !== null && _initModel$movieStudi !== void 0 ? _initModel$movieStudi : "";
      digitalData.content.contentCategory = serverDataObject.BuyPricingPlans.length > 0 ? serverDataObject.BuyPricingPlans[0].ContentType : "";
      digitalData.content.contentID = (_serverDataObject$Pro4 = serverDataObject.ProductId) !== null && _serverDataObject$Pro4 !== void 0 ? _serverDataObject$Pro4 : "";
      digitalData.content.contentName = (_initModel$movieProdu5 = initModel.movieProductName) !== null && _initModel$movieProdu5 !== void 0 ? _initModel$movieProdu5 : "";
      digitalData.content.contentType = (_qualityType$filter = qualityType.filter(function (n) {
        return n;
      })) !== null && _qualityType$filter !== void 0 ? _qualityType$filter : "";
      digitalData.content.format = (_qualityType$filter2 = qualityType.filter(function (n) {
        return n;
      })) !== null && _qualityType$filter2 !== void 0 ? _qualityType$filter2 : "";
      digitalData.content.genre = (_initModel$movieGenre2 = initModel.movieGenre) !== null && _initModel$movieGenre2 !== void 0 ? _initModel$movieGenre2 : "";
      digitalData.page.category.siteSection = "pdp";
      digitalData.page.category.subSection1 = (_initModel$movieProdu6 = initModel.movieProductName) !== null && _initModel$movieProdu6 !== void 0 ? _initModel$movieProdu6 : "";
      digitalData.page.category.subSection2 = "";
      digitalData.user.account.authState = initModel.isUserLoggedIn != null && initModel.isUserLoggedIn == "true" ? "logged in" : "logged out";
      digitalData.user.account.connectID = initModel.userConnectGuid != null && initModel.userConnectGuid != 'NA' ? initModel.userConnectGuid : "";
      digitalData.user.account.profileID = (_serverDataObject$Ses = serverDataObject.SessionInfo.UserProfileId) !== null && _serverDataObject$Ses !== void 0 ? _serverDataObject$Ses : "";
    }
    //END: ADOBE DTM parameteres

    //Set up pricing plan observables and new computeds here
    populatePricingPlans(serverDataObject);
    main.availablePricingPlans = ko.computed(function () {
      var pricingPlanArray = [main.buyUHDPricingPlan(), main.buyHDPricingPlan(), main.buySDPricingPlan(), main.rentUHDPricingPlan(), main.rentHDPricingPlan(), main.rentSDPricingPlan(), main.preorderHDPricingPlan(), main.preorderSDPricingPlan()];
      return pricingPlanArray.filter(function (pricingPlan) {
        return pricingPlan !== null && !pricingPlan.IsPurchasedByUser;
      });
    });
    main.harmonizedPricingPlans = ko.computed(function () {
      var pricingPlanArray = [main.buyHarmoPricingPlan(), main.rentHarmoPricingPlan(), main.preorderHarmoPricingPlan()];
      return pricingPlanArray.filter(function (pricingPlan) {
        return pricingPlan !== null;
      });
    });
    main.availableHarmonizedPricingPlans = ko.computed(function () {
      return main.harmonizedPricingPlans().filter(function (pricingPlan) {
        return pricingPlan != null && !pricingPlan.IsPurchasedByUser;
      });
    });
    main.pricingListsBuy = ko.computed(function () {
      return [main.buyHarmoPricingPlan(), main.buyUHDPricingPlan(), main.buyHDPricingPlan(), main.buySDPricingPlan()];
    });
    main.availableBuyPricingLists = ko.computed(function () {
      return main.pricingListsBuy().filter(function (pricingPlan) {
        return pricingPlan != null && !pricingPlan.IsPurchasedByUser;
      });
    });
    main.pricingListsRent = ko.computed(function () {
      return [main.rentHarmoPricingPlan(), main.rentUHDPricingPlan(), main.rentHDPricingPlan(), main.rentSDPricingPlan()];
    });
    main.availableRentPricingLists = ko.computed(function () {
      return main.pricingListsRent().filter(function (pricingPlan) {
        return pricingPlan != null && !pricingPlan.IsPurchasedByUser;
      });
    });
    main.preOrderPricingPlans = ko.computed(function () {
      return [main.preorderHarmoPricingPlan(), main.preorderHDPricingPlan(), main.preorderSDPricingPlan()];
    });
    main.availablePreOrderPricingPlans = ko.computed(function () {
      return main.preOrderPricingPlans().filter(function (pricingPlan) {
        return pricingPlan !== null && !pricingPlan.IsPurchasedByUser;
      });
    });
    main.lowestPrice = ko.computed(function () {
      var prices = formatPricesInPlans(main.availablePricingPlans());
      return formatAsCurrency(Math.min.apply(this, prices));
    });
    main.lowestPriceBuy = ko.computed(function () {
      var prices = formatPricesInPlans(main.availableBuyPricingLists());
      return formatAsCurrency(Math.min.apply(this, prices));
    });
    main.lowestPriceRent = ko.computed(function () {
      var prices = formatPricesInPlans(main.availableRentPricingLists());
      return formatAsCurrency(Math.min.apply(this, prices));
    });
    main.lowestPricePreOrder = ko.computed(function () {
      var prices = formatPricesInPlans(main.availablePreOrderPricingPlans());
      return formatAsCurrency(Math.min.apply(this, prices));
    });
    main.lowestHarmonizedPrice = ko.computed(function () {
      var prices = formatPricesInPlans(main.availableHarmonizedPricingPlans());
      return prices.length == 0 ? "" : formatAsCurrency(Math.min.apply(this, prices));
    });
    main.highestReward = ko.computed(function () {
      var rewards = [];
      ko.utils.arrayForEach(main.availablePricingPlans(), function (pricingPlan) {
        if (pricingPlan.RewardData && pricingPlan.BonusScenePoints > 0) {
          var totalReward = pricingPlan.BaseScenePoints + pricingPlan.BonusScenePoints;
          rewards.push(totalReward);
        } else {
          rewards.push(pricingPlan.BaseScenePoints);
        }
        ;
      });
      return Math.max.apply(this, rewards);
    });
    main.highestRewardText = ko.computed(function () {
      return initModel.highestRewardText.replace('{0}', main.highestReward());
    });
    main.plansHaveReward = ko.computed(function () {
      return main.highestReward() > 0;
    });
    main.plansHaveSale = ko.computed(function () {
      for (var index in main.availablePricingPlans()) {
        if (main.availablePricingPlans()[index].hasDiscount) return true;
      }
      return false;
    });
    main.plansHaveVip = ko.computed(function () {
      for (var index in main.availablePricingPlans()) {
        if (main.availablePricingPlans()[index].IsVip) return true;
      }
      return false;
    });
    main.buyPlansHaveBonusSceneReward = ko.computed(function () {
      for (var index in main.availableBuyPricingLists()) {
        var item = main.availableBuyPricingLists()[index];
        if (item.RewardData && item.BonusScenePoints > 0) return true;
      }
      return false;
    });
    main.rentPlansHaveBonusSceneReward = ko.computed(function () {
      for (var index in main.availableRentPricingLists()) {
        var item = main.availableRentPricingLists()[index];
        if (item.RewardData && item.BonusScenePoints > 0) return true;
      }
      return false;
    });
    main.preorderPlansHaveBonusSceneReward = ko.computed(function () {
      for (var index in main.availablePreOrderPricingPlans()) {
        var item = main.availablePreOrderPricingPlans()[index];
        if (item.RewardData && item.BonusScenePoints > 0) return true;
      }
      return false;
    });
    main.harmonizedPlansHaveBonusSceneReward = ko.computed(function () {
      for (var index in main.availableHarmonizedPricingPlans()) {
        var item = main.availableHarmonizedPricingPlans()[index];
        if (item.RewardData && item.BonusScenePoints > 0) return true;
      }
      return false;
    });

    //Harmonized Pricing, either HD or UHD
    main.isHarmonizedPricing = ko.computed(function () {
      //return main.harmonizedPricingPlans().length > 0;
      return main.harmonizedPricingPlans().filter(function (pricingPlan) {
        return pricingPlan != null && !pricingPlan.IsPurchasedByUser;
      }).length > 0;
    });
    main.isHarmonizedPreorder = ko.computed(function () {
      return main.preorderHarmoPricingPlan() && main.preorderHarmoPricingPlan().PricingPlanType.toLowerCase() === CineplexAppConstants.HarmonizedName && !main.preorderHarmoPricingPlan().IsPurchasedByUser || main.preorderHDPricingPlan() && main.preorderHDPricingPlan().PricingPlanType.toLowerCase() === CineplexAppConstants.HarmonizedName && !main.preorderHDPricingPlan().IsPurchasedByUser || main.preorderSDPricingPlan() && main.preorderSDPricingPlan().PricingPlanType.toLowerCase() === CineplexAppConstants.HarmonizedName && !main.preorderSDPricingPlan().IsPurchasedByUser;
    });
    //Checking Pricing, UHD
    main.nonHarmonizedHighestQuality = ko.computed(function () {
      var qualityLevel = CineplexAppConstants.ScreenQualityLevels;
      if (Object.keys(main.availablePricingPlans()).length === 0 && Object.keys(main.entitledPricingPlan()).length === 0) {
        return null;
      } else if (main.entitledPricingPlan().IsPurchasedByUser) {
        return findScreenQualityType(main.entitledPricingPlan().ScreenQualityType);
      } else if (Object.keys(main.availablePricingPlans()).length > 0) {
        return main.availablePricingPlans().reduce(function (prev, current) {
          return qualityLevel[prev.ScreenQualityType.toLowerCase()] > qualityLevel[current.ScreenQualityType.toLowerCase()] ? prev : current;
        }).ScreenQualityType.toUpperCase();
      } else {
        return null;
      }
    });

    // Check if is harmonized Highest Quality
    main.harmonizedHighestQuality = ko.computed(function () {
      var qualityLevel = CineplexAppConstants.ScreenQualityLevels;
      if (!main.harmonizedPricingPlans() || main.harmonizedPricingPlans().length === 0) {
        return null;
      } else {
        return main.harmonizedPricingPlans().reduce(function (prev, current) {
          return qualityLevel[prev.ScreenQualityType.toLowerCase()] > qualityLevel[current.ScreenQualityType.toLowerCase()] ? prev : current;
        }).ScreenQualityType.toUpperCase();
      }
    });
    // Check if is Harmonized UHD Capable
    main.isHarmonizedUHDCapable = ko.computed(function () {
      if (!main.isHarmonizedPricing()) {
        return false;
      } else {
        return main.harmonizedPricingPlans().filter(function (x) {
          return x.ScreenQualityType.toLowerCase() === 'uhd';
        }).length > 0;
      }
    });

    // Check if is non-Harmonized UHD Capable
    main.isNonHarmonizedUHDCapable = ko.computed(function () {
      if (Object.keys(main.availablePricingPlans()).length === 0 && Object.keys(main.entitledPricingPlan()).length === 0) {
        return false;
      } else if (main.nonHarmonizedHighestQuality() === "UHD") {
        return true;
      } else if (Object.keys(main.availablePricingPlans()).length > 0) {
        return main.availablePricingPlans().filter(function (x) {
          return x.ScreenQualityType == screenQualityTypeEnum.UHD;
        }).length > 0;
      } else if (Object.keys(main.entitledPricingPlan()).length > 0) {
        return main.entitledPricingPlan().ScreenQualityType == screenQualityTypeEnum.UHD;
      }
    });

    // Check if is UHD Capable
    main.isUHDCapable = ko.computed(function () {
      if (main.isHarmonizedUHDCapable()) {
        return main.isHarmonizedUHDCapable();
      } else if (main.isNonHarmonizedUHDCapable()) {
        return main.isNonHarmonizedUHDCapable();
      } else {
        return false;
      }
    });
    //End

    main.entitledPricingPlanId(serverDataObject.EntitledPricingPlanId);
    main.IsRedeemedForEntitledPricingPlan(serverDataObject.IsRedeemedForEntitledPricingPlan);
    main.productOrderStatus = serverDataObject.ProductOrderStatus;
    main.isFirstAccessToStream = serverDataObject.IsFirstAccessToStream;
    main.showWatchTrailerButton(parseInt(serverDataObject.PreviewId) > 0);

    //declare computed functions here

    main.buySceneRewards = ko.computed(function () {
      return getMaxScenePointReward(main.availableBuyPricingLists());
    });
    main.rentSceneRewards = ko.computed(function () {
      return getMaxScenePointReward(main.availableRentPricingLists());
    });
    main.preorderSceneRewards = ko.computed(function () {
      return getMaxScenePointReward(main.availablePreOrderPricingPlans());
    });
    main.harmonizedSceneRewards = ko.computed(function () {
      return getMaxScenePointReward(main.availableHarmonizedPricingPlans());
    });
    main.buySceneRewardsText = ko.computed(function () {
      return initModel.bonusSceneText.replace("{0}", main.buySceneRewards().toString());
    });
    main.rentSceneRewardsText = ko.computed(function () {
      return initModel.bonusSceneText.replace("{0}", main.rentSceneRewards().toString());
    });
    main.preorderSceneRewardsText = ko.computed(function () {
      return initModel.bonusSceneText.replace("{0}", main.preorderSceneRewards().toString());
    });
    main.harmonizedSceneRewardsText = ko.computed(function () {
      return initModel.bonusSceneText.replace("{0}", main.harmonizedSceneRewards().toString());
    });
    main.buyHasReward = ko.computed(function () {
      return main.buySceneRewards() > 0;
    });
    main.rentHasReward = ko.computed(function () {
      return main.rentSceneRewards() > 0;
    });
    main.harmonizedHasReward = ko.computed(function () {
      return main.harmonizedSceneRewards() > 0;
    });
    main.buyHasSale = ko.computed(function () {
      if (!main.availableBuyPricingLists() || main.availableBuyPricingLists().length === 0) return false;
      var buyWithSale = _.reject(main.availableBuyPricingLists(), function (value) {
        return value.ChargeAmount === value.DiscountedAmount;
      });
      return buyWithSale.length > 0;
    });
    main.rentHasSale = ko.computed(function () {
      if (!main.availableRentPricingLists() || main.availableRentPricingLists().length === 0) return false;
      var rentWithSale = _.reject(main.availableRentPricingLists(), function (value) {
        return value.ChargeAmount === value.DiscountedAmount;
      });
      return rentWithSale.length > 0;
    });
    main.preorderHasSale = ko.computed(function () {
      if (!main.availablePreOrderPricingPlans() || main.availablePreOrderPricingPlans().length === 0) return false;
      var preorderWithSale = _.reject(main.availablePreOrderPricingPlans(), function (value) {
        return value.ChargeAmount === value.DiscountedAmount;
      });
      return preorderWithSale.length > 0;
    });
    main.isHarmonizedPricingSale = ko.computed(function () {
      var buyPlan = main.buyHarmoPricingPlan();
      var rentPlan = main.rentHarmoPricingPlan();
      var preorderPlan = main.preorderHarmoPricingPlan();
      return main.isHarmonizedPricing() && (buyPlan && buyPlan.ChargeAmount !== buyPlan.DiscountedAmount || rentPlan && rentPlan.ChargeAmount !== rentPlan.DiscountedAmount || preorderPlan && preorderPlan.ChargeAmount !== preorderPlan.DiscountedAmount);
    });
    main.bestQualityRentedPricingPlan = ko.computed(function () {
      var _main$entitledPricing;
      if (main.entitledPricingPlan() && (!(main.entitledPricingPlan().IsPurchasedByUser && momentJs.duration((_main$entitledPricing = main.entitledPricingPlan()) === null || _main$entitledPricing === void 0 ? void 0 : _main$entitledPricing.ExpiresInHours).asMinutes() > 0) || main.entitledPricingPlan().ContentType !== CineplexAppConstants.ContentType.rental)) {
        return null;
      }
      return main.entitledPricingPlan();
    });
    main.bestQualityBoughtPricingPlan = ko.computed(function () {
      if (main.entitledPricingPlan() && (!main.entitledPricingPlan().IsPurchasedByUser || main.entitledPricingPlan().ContentType !== CineplexAppConstants.ContentType.digitalPurchase)) {
        return null;
      }
      return main.entitledPricingPlan();
    });
    main.bestQualityPreordered = ko.computed(function () {
      if (!main.preOrderPricingPlans() || main.preOrderPricingPlans().length === 0) return null;
      var purchasedPricingPlanWithHighestQuality = _.reject(main.preOrderPricingPlans(), function (value) {
        return value == null || value.IsPurchasedByUser === false;
      });
      if (purchasedPricingPlanWithHighestQuality.length === 0) return null;
      return _.last(purchasedPricingPlanWithHighestQuality);
    });
    main.availableForSuperTicketUpgradeToHD = ko.computed(function () {
      return !!main.entitledPricingPlan() && main.entitledPricingPlan().ContentType === contentTypeEnum.DigitalPurchase && main.entitledPricingPlan().ScreenQualityType === screenQualityTypeEnum.SD && main.entitledPricingPlan().DigitalPurchaseType === digitalPurchaseTypeEnum.SuperTicket && !!main.superTicketUpgradeToHDPlan();
    });
    main.hasPurchasedBuy = ko.computed(function () {
      return main.bestQualityBoughtPricingPlan() != null;
    });
    main.hasPurchasedRentOrBuy = ko.computed(function () {
      return main.bestQualityRentedPricingPlan() != null || main.bestQualityBoughtPricingPlan() != null;
    });
    main.hasPurchasedPreorder = ko.computed(function () {
      return main.bestQualityPreordered() != null;
    });
    main.hasPurchasedHarmo = ko.computed(function () {
      return main.bestQualityBoughtPricingPlan() != null && main.bestQualityBoughtPricingPlan().PricingPlanType == CineplexAppConstants.PricingPlanType.Harmonize;
    });
    main.isBuyAvailable = ko.computed(function () {
      return main.bestQualityRentedPricingPlan() == null && main.bestQualityBoughtPricingPlan() == null || main.bestQualityRentedPricingPlan() != null && main.bestQualityBoughtPricingPlan() == null;
    });
    main.hasRedeemed = ko.computed(function () {
      return main.entitledPricingPlanId() != null && main.entitledPricingPlanId() > 0 && main.IsRedeemedForEntitledPricingPlan();
    });
    main.isPurchased = ko.computed(function () {
      return main.hasPurchasedRentOrBuy() || main.hasRedeemed() || main.hasPurchasedPreorder();
    });
    main.hasPreOrderPricingPlans = ko.computed(function () {
      return main.availablePreOrderPricingPlans() != null && main.availablePreOrderPricingPlans().length !== 0;
    });
    main.expiryDateMessage = ko.computed(function () {
      //check if the movie is also bought
      var bestQualityBoughtPlan = main.bestQualityBoughtPricingPlan();
      var boughtPricingPlanObj;
      if (bestQualityBoughtPlan != null) {
        boughtPricingPlanObj = _.findWhere(main.pricingListsBuy(), {
          PricingPlanId: bestQualityBoughtPlan.PricingPlanId
        });
      }
      if (boughtPricingPlanObj == null) {
        var bestQualityRentedPlan = main.bestQualityRentedPricingPlan(); //Find the highest quality rented item purchased
        if (bestQualityRentedPlan != null) {
          //Find out in how long it expires, this is calculated in the backend 
          var expiryDate = bestQualityRentedPlan.ExpiresInHours;
          if (expiryDate != null) {
            //return momentJs.duration(expiryDate).asHours();
            var totalHours = momentJs.duration(expiryDate).asHours();
            if (totalHours < 0) {
              return 0;
            }
            //totalHours = 35;
            if (totalHours > 48) {
              var expDays = Math.round(totalHours / 24);
              //return initModel.expiryDateText.replace("{0}", expDate.format("MMM D, YYYY h:mm A"));
              return initModel.expiryDateText.replace("{0}", expDays.toString());
            } else {
              return initModel.hrsToExpiry.replace("{0}", Math.round(totalHours).toString());
            }
          }
        }
      }
      return 0;
    });

    //VISIBILITY COMPUTEDS
    main.showBigTrailerBtn = ko.computed(function () {
      return (!(main.hasPurchasedRentOrBuy() || main.hasRedeemed()) || main.hasPreOrderPricingPlans() && main.hasPurchasedPreorder()) && main.showWatchTrailerButton() && !main.isMobileOrTablet;
    });
    main.showPlayControls = ko.computed(function () {
      return (main.hasPurchasedRentOrBuy() || main.hasRedeemed()) && !main.hasPurchasedPreorder() && !main.isMobileOrTablet;
    });
    main.noMediaButtons = ko.computed(function () {
      return !main.showBigTrailerBtn() && (!main.hasPurchasedRentOrBuy() && !main.hasRedeemed() || main.hasPreOrderPricingPlans());
    });
    main.buyBtnVisible = ko.computed(function () {
      return !main.hasRedeemed() && !main.hasPurchasedBuy() && main.availableBuyPricingLists().length > 0 && !main.availableForSuperTicketUpgradeToHD() && !main.isHarmonizedPricing();
    });
    main.rentBtnVisible = ko.computed(function () {
      return !main.hasRedeemed() && !main.hasPurchasedRentOrBuy() && main.availableRentPricingLists().length > 0 && !main.availableForSuperTicketUpgradeToHD() && !main.isHarmonizedPricing();
    });
    main.preOrderBtnVisible = ko.computed(function () {
      return !main.hasRedeemed() && main.hasPreOrderPricingPlans() && !main.hasPurchasedPreorder() && main.superTicketUpgradeToHDPlan() == null && !main.availableForSuperTicketUpgradeToHD() && !main.isHarmonizedPricing();
    });
    main.watchBtnVisible = ko.computed(function () {
      return (main.buyBtnVisible() && main.rentBtnVisible() || main.rentBtnVisible() && main.preOrderBtnVisible() || main.buyBtnVisible() && main.preOrderBtnVisible()) && !main.availableForSuperTicketUpgradeToHD();
    });
    main.altPlansAvailable = ko.computed(function () {
      return main.preOrderBtnVisible() || main.rentBtnVisible() || main.buyBtnVisible();
    });
    main.synopsisExtrasVisible = ko.computed(function () {
      return main.synopsisFits() || main.showMoreContent();
    });
    main.dcPlusHasError = ko.computed(function () {
      return main.dcInvalidAddress() || main.dcInvalidCode() || main.dcServerError() || main.dCLoaderVisible() || main.dcInvalidLogin();
    });
    main.downloadManagerUrl = function () {
      var isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
      if (isMac) return initModel.downloadManagerUrlMac;else return initModel.downloadManagerUrlWindows;
    }();
    initSynopsis();
    csGlobal.resizeLargeTitle($(".pdp-wrapper .pdp-movie-information .pdp-movie-title"), main.titleMaxLines, main.twoLineTitleSize, afterTitleResize);
    initRelatedTitlesSlider(initModel.hasRelatedTitles);
    initWindowResize();
    main.isLoaded(true);
    $('body').click(function (event) {
      if (!$(event.target).closest('.dc-plus-btn-input-wrap').length && !$(event.target).is('.dc-plus-btn-input-wrap')) {
        $(".dc-plus-btn-input-wrap").modal('hide');
        clearDCPlusFieldFunction();
      }
    });
    initScrollBehaviour();
  }
  function clearDCPlusFieldFunction() {
    $(".pdp-wrapper .dc-plus-form .dc-redeem-code").val('');
    clearRedeemStatus();
  }
  function initWindowResize() {
    $(window).on("resize", function () {
      clearTimeout(main.windowResizeTimeout);
      main.windowResizeTimeout = setTimeout(windowResize, 500);
    });
  }
  function windowResize() {
    if (!main.showMoreContent()) {
      initSynopsis();
    }
    $(".pdp-wrapper .pdp-movie-information .pdp-movie-title").text(main.fullTitleText);
    csGlobal.resizeLargeTitle($(".pdp-wrapper .pdp-movie-information .pdp-movie-title"), main.titleMaxLines, main.twoLineTitleSize, afterTitleResize);
  }
  function initScrollBehaviour() {
    $(window).scroll(function () {
      var scroll = Math.max($("html").scrollTop(), $("body").scrollTop());
      if (scroll > 0) {
        if ($("#pdp-background-fade").length) {
          $("#pdp-background-fade").addClass("fade-in");
        }
        if ($("#pvod-background-fade").length) {
          $("#pvod-background-fade").addClass("fade-in");
        }
      } else {
        if ($("#pdp-background-fade").length) {
          $("#pdp-background-fade").removeClass("fade-in");
        }
        if ($("#pvod-background-fade").length) {
          $("#pvod-background-fade").removeClass("fade-in");
        }
      }
    });
  }
  function initSynopsis() {
    var $elem = $(".pdp-wrapper .pdp-movie-details .pdp-main-synopsis");
    var originalText = main.actualReadMoreSection;
    var elemWidth = parseInt($(".pdp-wrapper .pdp-movie-details .pdp-synopsis-wrap").css("width"));
    var allowableHeight = 1.95 * parseInt($("body").css("font-size"));
    var maxAllowableHeight = 3 * allowableHeight;
    var allowableWidth = elemWidth - 16 * parseInt($("body").css("font-size"));
    $elem.text(originalText);
    if ($elem.height() > maxAllowableHeight) {
      main.synopsisFits(false);
      $elem.text("");
      createTextRows($elem, allowableHeight, allowableWidth, ["line-1", "line-2", "line-3"], originalText);
    } else {
      main.synopsisFits(true);
    }
  }
  function initPlayer() {
    var productInfo = {
      id: main.productId,
      previewId: main.previewId,
      name: main.productName,
      thumbnailUrl: main.thumbnailUrl,
      imageUrl: main.imageUrl,
      playbackProgressSeconds: main.contentProgressSeconds,
      playbackProgressPercentage: main.contentProgressPercentage
    };
    videoPlayerService.initPlayer(productInfo, main.restartPlaybackThreshold, main.sessionToken, main.userConnectGuid, main.userSessionToken, main.playerAnalyticsData);
  }
  function createTextRows($elem, allowableHeight, allowableWidth, rowClasses, originalText) {
    var rowClass,
      $rowElem,
      remainingWords = originalText.split(" ");
    for (var j = 0; j < rowClasses.length; j++) {
      if (j !== rowClasses.length - 1) {
        rowClass = rowClasses[j];
        $elem.append("<span class='" + rowClass + "'></span>");
        $rowElem = $elem.find("." + rowClass);
        remainingWords = createTextRow($rowElem, allowableHeight, remainingWords, checkReadMoreSectionHeight);
        if (!remainingWords) {
          $elem.text(originalText);
          j = rowClasses.length;
        }
      } else {
        rowClass = rowClasses[j];
        $elem.append("<span class='" + rowClass + "'></span>");
        $rowElem = $elem.find("." + rowClass);
        remainingWords = createTextRow($rowElem, allowableWidth, remainingWords, checkReadMoreSectionWidth);
        if (!remainingWords) {
          $elem.text(originalText);
        }
      }
    }
  }
  function createTextRow($elem, allowableSize, wordArray, sizeCheckCallback) {
    var newWordArray = [],
      numWords = wordArray.length;
    for (var i = 0; i < numWords; i++) {
      newWordArray.push(wordArray.splice(0, 1)[0]);
      if (!wordArray) {
        return [];
      }
      if (sizeCheckCallback(newWordArray, $elem, allowableSize)) {
        wordArray.unshift(newWordArray.pop());
        return wordArray;
      }
    }
    return wordArray;
  }
  function checkReadMoreSectionHeight(newWordArray, $elem, allowableheight) {
    var movieText = newWordArray.join(" "),
      oldText = $elem.text(),
      elemheight;
    $elem.text(movieText);
    elemheight = parseInt($elem.css("height"));
    if (elemheight > allowableheight) {
      $elem.text(oldText + " ");
      return true;
    } else {
      return false;
    }
  }
  function checkReadMoreSectionWidth(newWordArray, $elem, allowablewidth) {
    var movieText = newWordArray.join(" ");
    $elem.text(movieText);
    var elemWidth = parseInt($elem.css("width"));
    if (elemWidth > allowablewidth) {
      return true;
    } else {
      return false;
    }
  }
  function afterTitleResize($elem) {
    var elemHeight = parseInt($elem.css("height")),
      maxHeight = parseInt($elem.css("line-height")) * 2;
    if (elemHeight > maxHeight) {
      $elem.css("font-size", "0.64em");
      titleOverflowLimitCheck($elem);
    }
  }
  function titleOverflowLimitCheck($elem) {
    var maxAllowableHeight = 3 * parseInt($elem.css("line-height"));
    if ($elem.height() > maxAllowableHeight) {
      var originalTextArray = $elem.text().split(" ");
      $elem.text("");
      createTextRow($elem, maxAllowableHeight, originalTextArray, checkReadMoreSectionHeight);
      addEllipsisToMovieText($elem, maxAllowableHeight);
    }
  }
  function addEllipsisToMovieText($elem, maxAllowableHeight) {
    var ellipsisText = $elem.text() + "...",
      oldText = $elem.text();
    $elem.text(ellipsisText);
    var elemheight = $elem.height();
    if (elemheight > maxAllowableHeight) {
      var endText = oldText.substring(0, oldText.length - 3);
      endText += "...";
      $elem.text(endText);
    }
  }
  function initRelatedTitlesSlider(hasRelatedTitles) {
    if (hasRelatedTitles) {
      $("#related-titles-slider").slick({
        centerPadding: '60px',
        slidesToShow: 5,
        slidesToScroll: 5,
        infinite: false,
        lazyLoad: 'ondemand',
        nextArrow: "<div class=\"playlist-indicator playlist-next-indicator\"><div class=\"rail-icon store-icon-railright\" aria-hidden=\"true\"></div><span class=\"sr-only\">Next</span></div>",
        prevArrow: "<div class=\"playlist-indicator playlist-back-indicator\"><div class=\"rail-icon store-icon-railleft\" aria-hidden=\"true\"></div><span class=\"sr-only\">Previous</span></div>",
        responsive: [{
          breakpoint: 1800,
          settings: {
            arrows: true,
            centerPadding: '60px',
            slidesToShow: 4,
            slidesToScroll: 4
          }
        }, {
          breakpoint: 900,
          settings: {
            arrows: true,
            centerPadding: '40px',
            slidesToShow: 4,
            slidesToScroll: 4
          }
        }, {
          breakpoint: 600,
          settings: {
            arrows: true,
            centerPadding: '40px',
            slidesToShow: 3,
            slidesToScroll: 3
          }
        }]
      });
    }
  }
  function displayMoreInfoFunction() {
    main.showMoreContent(true);
    $(".pdp-wrapper .pdp-movie-details .pdp-main-synopsis").text(main.actualReadMoreSection);
  }
  function playTrailerFunction() {
    initPlayer();
    videoPlayerService.playTrailer();
  }
  function csgPlayTrailerFunction() {
    window.open(main.trailerUrl);
  }

  //for movie
  function startContentFunction(pricingPlan) {
    initPlayer();
    videoPlayerService.playMedia(pricingPlan, onMovieClose);
  }
  function csgStartContentFunction(pricingPlan) {
    window.open(main.playerContentUrl.replace('{0}', main.productId).replace('{1}', pricingPlan));
  }
  function onMovieClose(playbackInfo) {
    //Update the progress seconds so it will resume playback if the user opens the media again on this page
    main.contentProgressSeconds = playbackInfo.progressSeconds;
    //Reset the percentage so it won't trigger the playback restart again
    if (main.contentProgressPercentage >= main.restartPlaybackThreshold) {
      main.contentProgressPercentage = 0;
    }
  }
  function startRentalContentFunction(rentalPricingPlan) {
    if (main.isFirstAccessToStream && !main.acknowledged) {
      $("#rental-confirm-modal").modal("show");
    } else {
      startContentFunction(rentalPricingPlan);
    }
  }
  function csgStartRentalContentFunction(rentalPricingPlan) {
    if (main.isFirstAccessToStream) {
      $("#rental-confirm-modal").modal("show");
    } else {
      csgStartContentFunction(rentalPricingPlan);
    }
  }
  function playRentalFirstTimeFunction(rentalPricingPlan) {
    $("#rental-confirm-modal").modal("hide");
    main.isFirstAccessToStream = false;
    startContentFunction(rentalPricingPlan);
  }
  function csgPlayRentalFirstTimeFunction(rentalPricingPlan) {
    $("#rental-confirm-modal").modal("hide");
    main.isFirstAccessToStream = false;
    csgStartContentFunction(rentalPricingPlan);
    window.location.href = window.location.href;
  }
  function startBestAvailableContentFunction() {
    //If rent and buy pricing plans are in user's locker rent takes precedense
    if (main.bestQualityRentedPricingPlan() != null) {
      startRentalContentFunction(main.bestQualityRentedPricingPlan().PricingPlanId);
    } else if (main.bestQualityBoughtPricingPlan() != null) {
      startContentFunction(main.bestQualityBoughtPricingPlan().PricingPlanId);
    } else if (main.entitledPricingPlanId() != null) {
      startContentFunction(main.entitledPricingPlanId());
    }
  }
  function csgStartBestAvailableContentFunction() {
    //If rent and buy pricing plans are in user's locker rent takes precedense
    if (main.bestQualityRentedPricingPlan() != null) {
      csgStartRentalContentFunction(main.bestQualityRentedPricingPlan().PricingPlanId);
    } else if (main.bestQualityBoughtPricingPlan() != null) {
      csgStartContentFunction(main.bestQualityBoughtPricingPlan().PricingPlanId);
    } else if (main.entitledPricingPlanId() != null) {
      csgStartContentFunction(main.entitledPricingPlanId());
    }
  }
  function tryRedeemDcFunction() {
    main.dCLoaderVisible(true);
    clearRedeemStatus();
    if (!connectModule.IsUserLoggedIn()) {
      main.dcInvalidLogin(true);
      main.dCLoaderVisible(false);
      return;
    }
    var form = $('#__AjaxAntiForgeryForm');
    var token = $('input[name="__RequestVerificationToken"]', form).val();
    redeemDataModule.redeemCode(main.redeemCode(), main.productId, main.redeemDataAjaxUrl, token, function (result) {
      if (result.Status === 1) {
        main.dcInvalidCode(true);
      } else if (result.Status === 2) {
        main.dcServerError(true);
      } else if (result.StatusCode === 3) {
        main.dcInvalidAddress(true);
      } else if (result.Status === 0) {
        main.dcInvalidAddress(false);
        main.dcInvalidCode(false);
        main.dcServerError(false);
        main.dcRedeemSuccess(true);
      } else {
        main.dcServerError(true);
      }
      main.dCLoaderVisible(false);
    });
  }
  function checkPlayOnPageLoad() {
    var autoplay = null;
    if (window.URLSearchParams) {
      var urlParams = new URLSearchParams(window.location.search);
      autoplay = urlParams.get('autoplay');
      main.acknowledged = urlParams.get('acknowledged');
    } else {
      autoplay = getParameterByName('autoplay');
      main.acknowledged = getParameterByName('acknowledged');
    }
    if (autoplay && autoplay === "true") {
      startBestAvailableContentFunction();
    }
  }
  function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
  function clearRedeemStatus() {
    main.dcInvalidAddress(false);
    main.dcInvalidCode(false);
    main.dcServerError(false);
    main.dcRedeemSuccess(false);
    main.dcInvalidLogin(false);
  }
  function formatAsCurrency(value) {
    if (main.languageSetting === "French") {
      return parseFloat(value).toFixed(2).replace('.', ',') + " $";
    } else {
      return "$" + parseFloat(value).toFixed(2);
    }
  }
  function relatedItemFunction(movie, url) {
    try {
      csTracking.pdp.clickRelatedItem(movie);
      window.location.href = url;
    } catch (e) {
      console.log("Error in binding: ", e);
    }
  }
  function proceedToCheckoutFunction(purchaseType, productId, pricingPlanId, qualityType) {
    //omniture tracking
    csTracking.pdp.clickCheckoutCart(purchaseType, qualityType);
    if (purchaseType === "Rent") {
      csTracking.pdp.clickRent();
    } else if (purchaseType === "Buy") {
      csTracking.pdp.clickBuy();
    }

    //$("#pdp-price-modal").modal("hide");

    if (connectModule.IsUserLoggedIn()) {
      window.location.href = main.checkoutUrl + productId + "?PricingPlanId=" + pricingPlanId;
    } else {
      //Used to append old connect close function to new one
      var connectOldCloseFunc2 = connectModule.Settings.Close;
      connectModule.Settings.Close = function () {
        setTimeout(function () {
          connectOldCloseFunc2();
          if (connectModule.IsUserLoggedIn()) {
            main.connectLoginPromptVisible(false);
            window.location.href = main.checkoutUrl + productId + "?PricingPlanId=" + pricingPlanId;
          } else {
            main.connectLoginPromptVisible(true);
            connectModule.Settings.Close = function () {
              connectOldCloseFunc2();
              main.connectLoginPromptVisible(false);
            };
          }
        }, 50);
      };
      connectModule.OpenConnectPage(1);
    }
  }

  ///Private functions
  function getHDUpgradePlan(detailArray) {
    var result = null;
    detailArray.forEach(function (detail) {
      if (detail.IsHDUpgradeSuperTicket && !detail.IsPurchasedByUser) {
        result = detail;
      }
    });
    return result;
  }
  function updatePricingInfo(productId, productDataUrl) {
    dataModule.getProductPricingData(productId, productDataUrl, function (ajaxData) {
      main.entitledPricingPlanId(ajaxData.EntitledPricingPlanId);

      //Updating new Observables
      populatePricingPlans(ajaxData);
    });
  }
  function populatePricingPlans(plansData) {
    main.buyHarmoPricingPlan(pricingService.getHarmonizedPricingPlan(plansData, "BuyPricingPlans", main.languageSetting));
    main.rentHarmoPricingPlan(pricingService.getHarmonizedPricingPlan(plansData, "RentPricingPlans", main.languageSetting));
    main.preorderHarmoPricingPlan(pricingService.getHarmonizedPricingPlan(plansData, "PreOrderPricingPlans", main.languageSetting));
    main.buyUHDPricingPlan(pricingService.getPricingPlanByType(plansData, "BuyPricingPlans", "uhd", undefined, main.languageSetting));
    main.rentUHDPricingPlan(pricingService.getPricingPlanByType(plansData, "RentPricingPlans", "uhd", undefined, main.languageSetting));
    main.buyHDPricingPlan(pricingService.getPricingPlanByType(plansData, "BuyPricingPlans", "hd", undefined, main.languageSetting));
    main.rentHDPricingPlan(pricingService.getPricingPlanByType(plansData, "RentPricingPlans", "hd", undefined, main.languageSetting));
    main.buySDPricingPlan(pricingService.getPricingPlanByType(plansData, "BuyPricingPlans", "sd", undefined, main.languageSetting));
    main.rentSDPricingPlan(pricingService.getPricingPlanByType(plansData, "RentPricingPlans", "sd", undefined, main.languageSetting));
    main.preorderHDPricingPlan(pricingService.getPricingPlanByType(plansData, "PreOrderPricingPlans", "hd", undefined, main.languageSetting));
    main.preorderSDPricingPlan(pricingService.getPricingPlanByType(plansData, "PreOrderPricingPlans", "sd", undefined, main.languageSetting));
    main.entitledPricingPlan(plansData.EntitledPricingPlan);
    main.superTicketUpgradeToHDPlan(getHDUpgradePlan(plansData.ProductDetailPageInfoList));
  }
  function connectFailedLoginCallback() {
    main.connectLoginPromptVisible(true);
  }
  function togglePricingModal() {
    main.pricingModalVisible(!main.pricingModalVisible());
  }
  function pricingModalClose() {
    //omniture tracking
    csTracking.pdp.clickCloseButton();
  }
  function formatPricesInPlans(plans) {
    var prices = [];
    ko.utils.arrayForEach(plans, function (pricingPlan) {
      var numericalDiscountAmount = parseFloat(pricingPlan.DiscountedAmount.replace(',', '.').replace(/[^\d\.]/, ''));
      prices.push(numericalDiscountAmount);
    });
    return prices;
  }
  function getMaxScenePointReward(plans) {
    var sceneRewardsArray = [],
      maxBonusPoints;
    if (!plans || plans.length === 0) return 0;else {
      plans.forEach(function (pricingData) {
        var itemScenePoints = pricingData.RewardData != null ? pricingData.BaseScenePoints + pricingData.BonusScenePoints : pricingData.BaseScenePoints;
        sceneRewardsArray.push(itemScenePoints);
      });
      maxBonusPoints = Math.max.apply(this, sceneRewardsArray);
    }
    return maxBonusPoints;
  }
};