(function () {
  var storeApp = window.storeApp || {};
  storeApp.menuVM = function (ko, $, csGlobal, csTracking) {
    'use strict';

    var vm = this;
    vm.init = initialize;
    vm.onClickMenuItem = onClickMenuItemFunction;
    function initialize(storeCollectionSetting, searchSaytCollection, saytAreaSetting, productDetailPageUrl, searchPageUrl, currentLanguage) {
      vm.navCollapsePoint = 1050;
      vm.dropdownHoverInitialized = false;
      vm.windowResizeDebounce = null;
      vm.dropdownVisible = ko.observable(false);
      vm.overlayVisible = ko.observable(false);
      vm.searchOpen = ko.observable(false);
      vm.searchInput = ko.observable("");
      vm.searchPageSearchDisplay = ko.observable(false);
      vm.language = currentLanguage;
      vm.toggleSearchOpen = toggleSearch;
      vm.searchPageUrl = searchPageUrl;
      vm.goToSearchPage = goToSearchPageFunction;
      initMenuSayt(storeCollectionSetting, searchSaytCollection, saytAreaSetting, productDetailPageUrl);
      initMobileTabletBehaviour();
      //initMainNavDropdownBehaviour();
      searchPageOpenCheck();
      openSearchNav();
      //initWindowResize();
    }
    function onClickMenuItemFunction(menuTitle) {
      //omniture tracking
      csTracking.mainMenu.clickMenuItem(menuTitle);
      return true;
    }
    function initMenuSayt(storeCollectionSetting, searchSaytCollection, saytAreaSetting, productDetailPageUrl) {
      var saytMenu = $(".primary-nav #nav-search-dropdown .nav-search-input").sayt({
        subdomain: 'cineplex',
        collection: storeCollectionSetting,
        saytCollection: searchSaytCollection,
        saytArea: saytAreaSetting,
        autocompleteTemplate: 'autocompleteTemplate',
        productTemplate: 'productTemplate',
        defaultImage: 'https://storemedia.cineplex.com/postermissing_460x682.jpg',
        productSort: vm.language === 'fr-ca' ? '~languageField:D' : '~languageField:A',
        selectSearchTerm: function selectSearchTerm(data) {
          $('#nav-search-form .nav-search-input').val(data.value);
          $('#nav-search-form').submit();
        },
        selectNavigation: function selectNavigation(data) {
          $('#nav-search-form .nav-search-input').val(data.value);
          $('#nav-search-form').submit();
        },
        selectProduct: function selectProduct(data) {
          if (data.ProductUrlName != null) {
            window.location.href = productDetailPageUrl + data.ProductUrlName;
            csTracking.search.dropDownItemSelect();
          }
        }
      });
      //Note this is triggered by custom sayt library - see sayt-2.0.212.mod.js file
      saytMenu.on('saytsendanalytics', function (event, data) {
        //console.log("term: " + data.term + " count: " + data.count);
        //omniture tracking
        csTracking.search.autoCompleteResult(data.term, data.count);
      });
    }
    function initMobileTabletBehaviour() {
      var $navbar = $(".primary-nav .navbar-nav.navbar-collapse");
      var $body = $("body");
      var $html = $("html");
      $navbar.on("show.bs.collapse", function () {
        var topOffset = Math.max($html.scrollTop(), $body.scrollTop());
        $body.addClass("modal-open");
        $body.css("top", -topOffset);
        $('.navbar-brand').css('visibility', 'hidden');
      });
      $navbar.on("hide.bs.collapse", function () {
        $body.removeClass("modal-open");
        $body.scrollTop($body.css("top"));
        $body.css("top", "auto");
        $('.navbar-brand').css('visibility', 'visible');
      });
    }
    function initMainNavDropdownBehaviour() {
      var $allPrimaryDropdownToggles = $(".primary-nav #navbar > .navbar-nav > .dropdown > .dropdown-toggle"),
        $allPrimaryDropdowns = $(".primary-nav #navbar > .navbar-nav > .dropdown");
      if (window.innerWidth >= vm.navCollapsePoint && !vm.dropdownHoverInitialized) {
        $allPrimaryDropdownToggles.on("mouseenter", mainNavDropdownHoverToggle);
        $allPrimaryDropdowns.on("show.bs.dropdown", mainDropdownShowEventHandler);
        $allPrimaryDropdowns.on("hide.bs.dropdown", mainDropdownHideEventHandler);
        vm.dropdownHoverInitialized = true;
      }
      function mainDropdownShowEventHandler(e) {
        if (window.innerWidth >= vm.navCollapsePoint) {
          $allPrimaryDropdownToggles.unbind("mouseenter", mainNavDropdownHoverToggle);
          $(e.target).bind("mouseleave", mainNavDropdownHoverToggle);
        }
      }
      function mainDropdownHideEventHandler(e) {
        if (window.innerWidth >= vm.navCollapsePoint) {
          $(e.target).unbind("mouseleave", mainNavDropdownHoverToggle);
          $allPrimaryDropdownToggles.on("mouseenter", mainNavDropdownHoverToggle);
        }
      }
    }
    function mainNavDropdownHoverToggle(event) {
      if (window.innerWidth >= vm.navCollapsePoint) {
        $(this).dropdown("toggle");
      }
    }
    function toggleSearch() {
      if (!vm.searchOpen()) {
        vm.searchOpen(true);
        setTimeout(openSearch, 250);
      } else {
        //vm.dropdownVisible(false);
        setTimeout(closeSearch, 250);
      }
    }
    function openSearch() {
      //var $allPrimaryDropdownToggles = $(".primary-nav #navbar > .navbar-nav > .dropdown > .dropdown-toggle");
      //$allPrimaryDropdownToggles.unbind("mouseenter", mainNavDropdownHoverToggle);
      vm.searchOpen(true);
      vm.dropdownVisible(true);
      $(".primary-nav .nav-search-input").focus();
      $(document).on("focus click", searchOpenClickHandler);
      $(".primary-nav .menu-item.dropdown-toggle").on("click", closeSearch);
      if (vm.searchPageSearchDisplay() && window.innerWidth < 900) {
        vm.overlayVisible(false);
      } else {
        vm.overlayVisible(true);
      }
    }
    function closeSearch() {
      var $allPrimaryDropdownToggles = $(".primary-nav #navbar > .navbar-nav > .dropdown > .dropdown-toggle");
      vm.overlayVisible(false);
      vm.dropdownVisible(false);
      vm.searchOpen(false);
      vm.searchInput("");
      $(document).unbind("focus click touchstart", searchOpenClickHandler);
      //$allPrimaryDropdownToggles.unbind("click touchstart", closeSearch);
      //$allPrimaryDropdownToggles.on("mouseenter", mainNavDropdownHoverToggle);
    }
    function searchOpenClickHandler(e) {
      if (!(e.target.id == "nav-search-dropdown" || $(e.target).parents("#nav-search-dropdown").size()) && !(e.target.id == "nav-search-toggle" || $(e.target).parents("#nav-search-toggle").size())) {
        closeSearch();
      }
    }
    function searchPageOpenCheck() {
      var pathURL = document.location.pathname;
      if (pathURL.indexOf("search") > -1) {
        vm.searchPageSearchDisplay(true);
        getSearchResult();
      }
    }
    function getSearchResult() {
      var searchResultURL = document.location.pathname;
      vm.searchInput(csGlobal.getUrlParam("query"));
    }
    function openSearchNav() {
      if (vm.searchPageSearchDisplay() && $(window).width() < 900) {
        openSearch();
      }
    }
    function initWindowResize() {
      $(window).on("resize", function () {
        clearTimeout(vm.windowResizeDebounce);
        vm.windowResizeDebounce = setTimeout(function () {
          initMainNavDropdownBehaviour();
        }, 500);
      });
    }
    function goToSearchPageFunction() {
      if (vm.searchInput().length > 0) window.location.href = vm.searchPageUrl + vm.searchInput();
    }
    return vm;
  };
})();