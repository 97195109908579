var storeApp = window.storeApp || {};
storeApp.watchlistVM = function (ko, $, moment, bowser, connect, watchlistService, pricingService, csGlobal, csTracking) {
  var vm = this;
  //rentExpiryDaysText,
  //rentExpiryMinsText,
  //rentExpiryHoursText;

  vm.init = initializeMyLibrary;
  function initializeMyLibrary(model) {
    //rentExpiryDaysText = model.rentExpiryDaysText;
    //rentExpiryMinsText = model.rentExpiryMinsText;
    //rentExpiryHoursText = model.rentExpiryHoursText;

    var watchlistedMovies = mapWatchlistedMoviesObject(model.watchlistedMovies),
      downloaderLink = bowser.mac ? model.storeDownlaoderIOSLink : model.storeDownloaderWindowsLink,
      appDownloaderLink = bowser.ios ? model.iOSAppLink : model.androidAppLink;
    vm.isMobileOrTablet = Boolean(bowser.mobile) || Boolean(bowser.tablet);
    vm.unsupportedOS = Boolean(bowser.windowsphone) || Boolean(bowser.linux) || Boolean(bowser.chromeos) || Boolean(bowser.webos) || Boolean(bowser.tizen) || Boolean(bowser.sailfish) || Boolean(bowser.tablet) && Boolean(bowser.windows);
    vm.isLoaded = ko.observable(false);
    vm.playerContentUrl = model.playerContentUrl;
    vm.selectedSort = ko.observable("0");
    vm.watchlistedMoviesRaw = ko.observableArray(watchlistedMovies);
    vm.downloaderLink = downloaderLink;
    vm.appDownloaderLink = appDownloaderLink;
    vm.rentalMovieSelected = ko.observable(null);
    vm.unexpectedError = ko.observable(false);
    vm.rentHDPricingPlan = ko.observable(null);
    vm.rentSDPricingPlan = ko.observable(null);
    vm.buyHDPricingPlan = ko.observable(null);
    vm.buySDPricingPlan = ko.observable(null);
    vm.preorderSDPricingPlan = ko.observable(null);
    vm.preorderHDPricingPlan = ko.observable(null);
    vm.selectedProductName = ko.observable("");
    vm.selectedReleaseYear = ko.observable("");
    vm.selectedMpaaRating = ko.observable("");
    vm.isGenericMobile = ko.observable("");
    vm.mobileMarqueeSrcSet = ko.observable("");
    vm.mobileImgSrc = ko.observable("");
    vm.pricingModalVisible = ko.observable(false);
    vm.pricingModalModel = {
      rentSdOrHdText: model.rentSdOrHdText,
      rentSDUnavailableText: model.rentSDUnavailableText,
      noRentalPricingPlansAvailableText: model.noRentalPricingPlansAvailableText,
      buySdOrHdText: model.buySdOrHdText,
      buyHDUnavailableText: model.buyHDUnavailableText,
      buySDUnavailableText: model.buySDUnavailableText,
      noBuyPlansAvailableText: model.noBuyPlansAvailableText,
      preOrderSDOrHDText: model.preOrderSDOrHDText,
      baseScenePtsTxt: model.modalBaseSceneText,
      bonusScenePtsTxt: model.modalBonusSceneText,
      connectModule: connect,
      checkoutUrl: model.checkoutUrl
    };
    vm.watchlistedMovies = ko.computed(function () {
      var resultSort = vm.watchlistedMoviesRaw().slice();
      if (parseInt(vm.selectedSort())) {
        return resultSort.sort(filmAlphabeticalSort);
      } else {
        return resultSort.reverse();
      }
    });
    vm.watchlistVisible = ko.computed(function () {
      return !vm.unexpectedError() && vm.isLoaded();
    });
    vm.isMoviePreorder = isMoviePreorder;
    vm.playMovie = playMovie;
    vm.confirmRentalStart = confirmRentalStart;
    vm.startRentalMovieFirstTime = startRentalMovieFirstTime;
    vm.constructPdpUrl = constructPdpUrl;
    vm.generateRentalConfirmTitle = generateRentalConfirmTitle;
    vm.removeFromWatchlist = removeFromWatchlist;
    vm.pdpUrl = model.pdpUrl;
    vm.openMoviePricingPlans = openMoviePricingPlans;
    vm.isLoaded(true);
  }
  function filmAlphabeticalSort(movie1, movie2) {
    var title1 = movie1.productName.toLowerCase(),
      title2 = movie2.productName.toLowerCase();
    return title1 >= title2;
  }
  function filmDateSort(movie1, movie2) {
    var date1 = movie1.PurchaseDateTime,
      date2 = movie2.PurchaseDateTime;
    return date1 >= date2;
  }
  function isMoviePreorder(releaseDate, isComingSoon) {
    return !moment().isAfter(releaseDate) && isComingSoon;
  }
  function removeFromWatchlist(productId) {
    var filteredWatchlist = vm.watchlistedMoviesRaw().filter(function (watchlistedMovie) {
      return watchlistedMovie.productId !== productId;
    });
    vm.watchlistedMoviesRaw(filteredWatchlist);
    watchlistService.removeFromWatchlist(productId, watchlistRemovalSuccess, unexpectedError);
    function watchlistRemovalSuccess(resp) {
      var parsedResponse = JSON.parse(resp);
      if (parsedResponse.Status !== 1) {
        unexpectedError(new Error("Watchlist Removal Failed"));
      }
    }
  }
  function unexpectedError(err) {
    vm.unexpectedError(true);
  }
  function startRentalMovieFirstTime(rentalMovie) {
    playMovie(rentalMovie.productId, rentalMovie.entitledPricingPlanId);
    //rentalMovie.rentalExpiryText(setRentalExpiryText(moment().add(2, 'days')));
    rentalMovie.isFirstAccessToStream(false);
    $("#rental-confirm-modal").modal("hide");
  }
  function confirmRentalStart(rentalMovie) {
    vm.rentalMovieSelected(rentalMovie);
    $("#rental-confirm-modal").modal("show");
  }
  function playMovie(productId, pricingPlan) {
    window.open(vm.playerContentUrl.replace('{0}', productId).replace('{1}', pricingPlan));
  }
  function generateRentalConfirmTitle(rentalTitleText, movieTitle) {
    return rentalTitleText.replace("{0}", movieTitle);
  }
  function constructPdpUrl(productId) {
    return vm.pdpUrl + productId;
  }
  function mapWatchlistedMoviesObject(watchlistedMovieArray) {
    var mappedArray = [];
    watchlistedMovieArray.forEach(function (watchlistedMovie) {
      mappedArray.push(new watchlistMovieObject(watchlistedMovie.MovieDetails, watchlistedMovie.ProductCsgDetails));
    });
    return mappedArray;
  }
  function openMoviePricingPlans(movieData) {
    populateSelectedMovieData(movieData);
    $("#pdp-price-modal").modal("show");
  }
  function populateSelectedMovieData(movieData) {
    vm.selectedProductName(movieData.productName);
    vm.selectedReleaseYear(movieData.ReleaseYear);
    vm.selectedMpaaRating(movieData.MpaaRating);
    vm.isGenericMobile(movieData.isGenericMobile);
    vm.mobileMarqueeSrcSet(movieData.mobileMarqueeSrcSet);
    vm.mobileImgSrc(movieData.mobileImgSrc);
    vm.buyHDPricingPlan(pricingService.getPricingPlanByType(movieData.productCsgDetails, "BuyPricingPlans", "hd"));
    vm.buySDPricingPlan(pricingService.getPricingPlanByType(movieData.productCsgDetails, "BuyPricingPlans", "sd"));
    vm.rentHDPricingPlan(pricingService.getPricingPlanByType(movieData.productCsgDetails, "RentPricingPlans", "hd"));
    vm.rentSDPricingPlan(pricingService.getPricingPlanByType(movieData.productCsgDetails, "RentPricingPlans", "sd"));
    vm.preorderHDPricingPlan(pricingService.getPricingPlanByType(movieData.productCsgDetails, "PreOrderPricingPlans", "hd"));
    vm.preorderSDPricingPlan(pricingService.getPricingPlanByType(movieData.productCsgDetails, "PreOrderPricingPlans", "sd"));
  }
  function watchlistMovieObject(movieDetails, productCsgDetails) {
    this.productId = movieDetails.ProductId || 0;
    this.productName = movieDetails.ProductName || "Not Found";
    this.thumbnailUrl = movieDetails.ThumbnailUrl || "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=";
    this.MpaaRating = movieDetails.MpaaRating || "";
    this.ReleaseYear = moment(movieDetails.ReleaseDate).format('YYYY') || "0001";
    this.isPurchased = productCsgDetails.EntitledPricingPlan.IsPurchasedByUser || false;
    this.isPreorder = productCsgDetails.EntitledPricingPlan.IsPreOrder || false;
    this.previewId = productCsgDetails.PreviewId || 0;
    this.entitledPricingPlanId = productCsgDetails.EntitledPricingPlanId || 0;
    this.isRedeemedForEntitledPricingPlan = productCsgDetails.IsRedeemedForEntitledPricingPlan || 0;
    this.isRental = productCsgDetails.ProductOrderStatus === "r";
    this.isStarted = productCsgDetails.EntitledPricingPlan.ContentProgressSeconds > 0;
    this.isAvailableForStream = moment().isAfter(moment(movieDetails.ReleaseDate));
    this.productCsgDetails = productCsgDetails || {};
    this.isFirstAccessToStream = ko.observable(productCsgDetails.IsFirstAccessToStream || false);
    //this.rentalExpiryText = ko.observable();
  }
  return vm;
};