// START : Device information 
var deviceInfo = {
  ua: navigator.userAgent,
  browser: "",
  os: "",
  touch: "",
  mobile: "",
  tablet: "",
  model: "",
  deviceName: "",
  deviceGroup: "",
  deviceModel: "NA"
};
deviceInfo.browser = /Edge\/\d+/.test(deviceInfo.ua) ? 'ed' : /MSIE 9/.test(deviceInfo.ua) ? 'ie9' : /MSIE 10/.test(deviceInfo.ua) ? 'ie10' : /MSIE 11/.test(deviceInfo.ua) ? 'ie11' : /MSIE\s\d/.test(deviceInfo.ua) ? 'ie?' : /rv\:11/.test(deviceInfo.ua) ? 'ie11' : /Firefox\W\d/.test(deviceInfo.ua) ? 'ff' : /Chrom(e|ium)\W\d|CriOS\W\d/.test(deviceInfo.ua) ? 'gc' : /\bSafari\W\d/.test(deviceInfo.ua) ? 'sa' : /\bOpera\W\d/.test(deviceInfo.ua) ? 'op' : /\bOPR\W\d/i.test(deviceInfo.ua) ? 'op' : typeof MSPointerEvent !== 'undefined' ? 'ie?' : '';
deviceInfo.os = /Windows NT 10/.test(deviceInfo.ua) ? "win10" : /Windows NT 6\.0/.test(deviceInfo.ua) ? "winvista" : /Windows NT 6\.1/.test(deviceInfo.ua) ? "win7" : /Windows NT 6\.\d/.test(deviceInfo.ua) ? "win8" : /Windows NT 5\.1/.test(deviceInfo.ua) ? "winxp" : /Windows NT [1-5]\./.test(deviceInfo.ua) ? "winnt" : /Mac/.test(deviceInfo.ua) ? "mac" : /Linux/.test(deviceInfo.ua) ? "linux" : /X11/.test(deviceInfo.ua) ? "nix" : "";
deviceInfo.touch = 'ontouchstart' in document.documentElement;
deviceInfo.mobile = /IEMobile|Windows Phone|Lumia/i.test(deviceInfo.ua) ? 'w' : /iPhone|iP[oa]d/.test(deviceInfo.ua) ? 'i' : /Android/.test(deviceInfo.ua) ? 'a' : /BlackBerry|PlayBook|BB10/.test(deviceInfo.ua) ? 'b' : /Mobile Safari/.test(deviceInfo.ua) ? 's' : /webOS|Mobile|Tablet|Opera Mini|\bCrMo\/|Opera Mobi/i.test(deviceInfo.ua) ? 1 : 0;
deviceInfo.tablet = /Tablet|iPad/i.test(deviceInfo.ua) || !/Mobile/.test(deviceInfo.ua);
var ua_substr = deviceInfo.ua.substring(deviceInfo.ua.indexOf("(") + 1, deviceInfo.ua.indexOf(")"));
if (deviceInfo.ua_substr !== '') deviceInfo.model = ua_substr;
deviceInfo.deviceName = getDeviceName(deviceInfo);
deviceInfo.deviceGroup = deviceInfo.mobile === 0 ? "Web" : "Mobile Web";
function getDeviceName(dInfo) {
  var result = "";
  if (dInfo.mobile !== 0) {
    switch (dInfo.mobile) {
      case 'i':
        result += "iOS";
        break;
      case 'a':
        result += "Android";
        break;
      case 'b':
        result += "Blackberry";
        break;
      default:
        result += "Unknown";
        break;
    }
    if (dInfo.tablet) result += " " + "Tablet";else result += " " + "Phone";
  } else {
    if (/win/.test(dInfo.os)) result += "Windows PC";else if (/mac/.test(dInfo.os)) result += "MAC";else if (/linux/.test(dInfo.os)) result += "Linux";else result += "Unknown";
  }
  return result;
}

//Legend :

//browser:
//ed = Microsoft Edge
//ie9 = Explorer 9
//ie10 = Explorer 10
//ie11 = Explorer 11
//ie? = Explorer 8 and below
//ff = Firefox
//gc = Google Chrome
//sa = Safari
//op = Opera

//os:
//win7
//win8
//win9
//win10
//winvista
//winxp
//winnt
//mac
//linux
//nix

//mobile - including tablets:
//0 = Not a mobile or tablet device
//w = Windows Phone (Nokia Lumia)
//i = iOS (iPhone iPad)
//a = Android
//b = Blackberry
//s = Undetected mobile device running Safari
//1 = Undetected mobile device

//tablet:
//true
//false

//touch:
//true
//false

// END : Device information