(function () {
  'use strict';

  var storeApp = window.storeApp || {};
  storeApp.superTicketRedeemViewModel = function (ko, $, redeemModel, connect, csTracking, superTicketService) {
    //local variables
    var redemptionStatusEnum = {
      success: 1,
      alreadyRedeemeed: 4,
      superTicketNotAvailable: 105,
      validationError: [2, 5, 8]
    };
    var self = this;
    self.errors = "";
    self.redeemCode = ko.observable("").extend({
      required: {
        message: redeemModel.CodeRequiredErrorMsg
      }
    });
    self.superTicketRedeemLoading = ko.observable(false);
    self.showSuperTicketConnectLoginError = ko.observable(false);
    self.formVisible = ko.observable(true);
    self.redeemErrorMessage = ko.observable("");
    self.showRedeemErrorMessage = ko.observable(false);
    self.showRedeemRedirectModal = ko.observable(false);
    self.showContactHelpCenterModal = ko.observable(false);
    self.redeemedMovieTitle = ko.observable(redeemModel.defaultMovieTitle);
    self.redeemedMovieImgUrl = ko.observable("https://storemedia.cineplex.com/postermissing_460x682.jpg");
    self.redeemedMovieSplashImg = ko.observable("url(" + "https://storemedia.cineplex.com/Attachments/NewItems/pd-pframe-mobile_20170713210715_0.png" + ")");

    //self.EmailAddress = ko.observable();
    //self.OrderCode = ko.observable();
    //self.TrustedClientId = ko.observable();

    //self.productId = ko.observable();
    self.errors = ko.validation.group(self);
    self.redeemModalVisible = ko.computed(function () {
      return !self.showRedeemRedirectModal() && !self.showContactHelpCenterModal() && self.formVisible();
    });
    self.submitForm = submitRedeemForm;
    self.resetForm = resetForm;
    function submitRedeemForm() {
      self.redeemErrorMessage("");
      self.showRedeemErrorMessage(false);
      self.showSuperTicketConnectLoginError(false);
      if (self.errors().length === 0) {
        self.superTicketRedeemLoading(true);
        if (!connect.IsUserLoggedIn()) {
          connect.OpenConnectPage(1);
          self.superTicketRedeemLoading(false);
          self.formVisible(false);

          //Used to append old connect function to new one
          var connectOldCloseFunc = connect.Settings.Close;
          connect.Settings.Close = function () {
            connectOldCloseFunc();
            setTimeout(function () {
              $("body").addClass("modal-open"); //Readd class since connect removes it
              if (!connect.IsUserLoggedIn()) {
                self.showSuperTicketConnectLoginError(true);
                self.redeemErrorMessage(redeemModel.loginError);
                self.showRedeemErrorMessage(true);
                self.formVisible(true);
                $("#connectError-" + redeemModel.randomId).focus();
              } else {
                self.formVisible(true);
                self.submitForm();
              }
              connect.Settings.Close = connectOldCloseFunc;
            });
          };
        } else {
          superTicketService.redeemSuperTicket(self.redeemCode(), redemptionSuccess, redemptionError);
        }
      } else {
        redemptionError("error");
      }
    }
    function redemptionSuccess(data) {
      var response = JSON.parse(data);
      if (response.Status === redemptionStatusEnum.success) {
        if (response.ProductDetailsFound) {
          //omniture tracking
          var responseJson = response.SubmitOrderResult;
          var id = "";
          resultJson.TransactionId = id.concat(main.vmUserInfo().userProfileGuid, "_", main.checkoutDetailsObject.ProductId, "_", main.checkoutDetailsObject.PricingPlanId);
          csTracking.onSuccessfulPurchase(responseJson);
          self.redeemedMovieTitle(response.ProductName);
          self.redeemedMovieImgUrl(response.ProductThumbnailUrl);
          self.redeemedMovieSplashImg("url(" + "https://storemedia.cineplex.com/Attachments/NewItems/pd-pframe-mobile_20170713210715_0.png" + ")");
        }
        self.showRedeemRedirectModal(true);
      } else if (redemptionStatusEnum.validationError.indexOf(response.Status) > -1) {
        showRedeemError(redeemModel.validationError);
      } else if (response.Status === redemptionStatusEnum.superTicketNotAvailable) {
        self.showContactHelpCenterModal(true);
      } else if (response.Status === redemptionStatusEnum.alreadyRedeemeed) {
        showRedeemError(redeemModel.alreadyRedeemed);
      } else {
        showRedeemError(redeemModel.unexpectedError);
      }
      self.superTicketRedeemLoading(false);
    }
    function redemptionError(resp) {
      showRedeemError(self.errors()[0]);
      self.superTicketRedeemLoading(false);
    }
    function showRedeemError(errorMessage) {
      self.redeemErrorMessage(errorMessage);
      self.showRedeemErrorMessage(true);
    }
    function resetForm() {
      self.redeemCode("");
      self.superTicketRedeemLoading(false);
      self.showSuperTicketConnectLoginError(false);
      self.showContactHelpCenterModal(false);
      self.formVisible(true);
      self.redeemErrorMessage("");
      self.showRedeemErrorMessage(false);
      self.showRedeemRedirectModal(false);
      self.redeemedMovieTitle(redeemModel.defaultMovieTitle);
      self.redeemedMovieImgUrl("https://storemedia.cineplex.com/postermissing_460x682.jpg");
      self.redeemedMovieSplashImg("url(" + "https://storemedia.cineplex.com/Attachments/NewItems/pd-pframe-mobile_20170713210715_0.png" + ")");
    }
    return self;
  };
})();