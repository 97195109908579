(function () {
  var storeApp = window.storeApp || {};
  storeApp.superTicketService = function ($) {
    return {
      redeemSuperTicket: redeemSuperTicketFunction,
      upgradeSuperTicket: upgradeSuperTicketFunction
    };
    function redeemSuperTicketFunction(redemptionCode, successCallback, errorCallback) {
      $.ajax({
        url: "SuperTicket/SuperTicketVoucherRedeem",
        data: {
          'redeemCode': redemptionCode
        },
        success: successCallback,
        error: errorCallback
      });
    }
    function upgradeSuperTicketFunction(theatreId, transactionId, seqId, successCallback, errorCallback) {
      $.ajax({
        url: "SuperTicket/SuperTicketUpgradeVoucherSubmit",
        data: {
          'TheatreId': theatreId,
          'TransactionId': transactionId,
          'SeqId': seqId
        },
        success: successCallback,
        error: errorCallback
      });
    }
  }(jQuery);
})();