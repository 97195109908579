(function () {
  var storeApp = window.storeApp || {};
  storeApp.superTicketVM = function (ko, $, connect, csGlobal, csTracking, superTicketService) {
    var vm = this;
    vm.init = initFunction;
    function initFunction(model) {
      //Instantiation of viewModel Variables
      vm.redeemVM = ko.observable(new storeApp.superTicketRedeemViewModel(ko, $, model.redeemModel, connect, csTracking, superTicketService));
      vm.upgradeVM = ko.observable(new storeApp.superTicketUpgradeViewModel(ko, $, model.upgradeModel, connect, superTicketService));
      vm.upgradeTicketDropdownOpen = ko.observable(false);
      vm.jScrollPaneResizeDebouncer = null;
      vm.addCustomScrollBar = addCustomScrollBarFunction;
      vm.toggleUpgradeTicketInfoDropdown = toggleUpgradeTicketInfoDropdown;

      //Init redemptiondata
      populateUpgradeFormFromUrl();

      //Instantation of listeners etc.
      initFormCloseBehaviour();
    }
    function addCustomScrollBarFunction(elem) {
      var $elem = $(elem);
      $elem.modal();
      $elem.imagesLoaded(function () {
        updatejScrollPane($elem.find(".jScrollPaneTarget"));
      });
    }
    function updatejScrollPane($elem) {
      reinitjScrollPane($elem);
      $(window).on("resize", $elem, reinitjScrollOnResize);
    }
    function reinitjScrollOnResize($event) {
      var $elem = $event.data;
      clearTimeout(vm.jScrollPaneResizeDebouncer);
      vm.jScrollPaneResizeDebouncer = setTimeout(reinitjScrollPane, 200, $elem);
    }
    function reinitjScrollPane($elem) {
      if ($elem.data("jsp")) {
        var paneHeight = $elem.find(".jspPane").height();
        $elem.find(".jspContainer").height(paneHeight);
        $elem.data("jsp").reinitialise();
      } else {
        $elem.jScrollPane();
      }
    }
    function toggleUpgradeTicketInfoDropdown() {
      vm.upgradeTicketDropdownOpen(!vm.upgradeTicketDropdownOpen());
    }
    function initFormCloseBehaviour() {
      $("#superticket-upgrade-modal").on("hide.bs.modal", function () {
        vm.upgradeVM().resetForm();
        vm.upgradeTicketDropdownOpen(false);
      });
      $("#superticket-redeem-modal").on("hide.bs.modal", function () {
        vm.redeemVM().resetForm();
      });
    }
    function populateUpgradeFormFromUrl() {
      var redemptionCode = csGlobal.getUrlParam("code");
      var isRedemption = csGlobal.getUrlParam("redemption");
      var redemptionCodeArray;
      if (redemptionCode && isRedemption && redemptionCode.hasOwnProperty("length")) {
        if (isRedemption === "true") {
          vm.redeemVM().redeemCode(redemptionCode);
          $("#superticket-redeem-modal").modal("show");
        } else {
          redemptionCodeArray = redemptionCode.split("-");
          if (redemptionCodeArray.length === 3) {
            vm.upgradeVM().theatreId(redemptionCodeArray[0]);
            vm.upgradeVM().transactionId(redemptionCodeArray[1]);
            vm.upgradeVM().seqId(redemptionCodeArray[2]);
            $("#superticket-upgrade-modal").modal("show");
          }
        }
      }
    }
    return vm;
  };
})();