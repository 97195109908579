ConnectApp = window.ConnectApp || {};
function userInfo(status, userProfileGuid, firstName, lastName, language, email) {
  this.status = status;
  this.userProfileGuid = userProfileGuid;
  this.firstName = firstName;
  this.lastName = lastName;
  this.language = language;
  this.email = email;
}
ConnectApp.ConnectModule = function ($, connect) {
  'use strict';

  var main = {
      init: initFunction,
      getConnectUserInfo: getConnectUserInfoFunction,
      getSceneId: getSceneId,
      initAdobeSceneIdentifier: initAdobeSceneIdentifier
    },
    connectOpenScrollTop = 0,
    $body = $(document.body),
    $html = $("html"),
    dropdownInitialized = false;
  function initFunction() {
    var oldConnectInitializeFunction = window.connectInitializedCallback || function () {
      return;
    };
    window.connectInitializedCallback = function () {
      oldConnectInitializeFunction();
      if (Cineplex != null && Cineplex.Connect != null && Cineplex.Connect.IsUserLoggedIn()) {
        initUserLoggedIn();
      } else if (Cineplex != null && Cineplex.Connect != null) {
        var oldConnectCloseFun = Cineplex.Connect.Settings.Close || function () {
          return;
        };
        Cineplex.Connect.Settings.Close = function () {
          oldConnectCloseFun();
          if (Cineplex.Connect.IsUserLoggedIn()) {
            initUserLoggedIn();
          }
        };
      }
    };
  }
  function getConnectUserInfoFunction() {
    var connectUserStatus = connect.GetUserStatus();
    var userInfoData = new userInfo(connectUserStatus.Status, connectUserStatus.UserProfileGuid, connectUserStatus.FirstName, connectUserStatus.LastName, connectUserStatus.Language, "");
    connect.GetUserProfileInfo(function (connectUser) {
      userInfoData.email = connectUser.Email;
      //populate any required attributes here
    });
    return userInfoData;
  }
  function initUserLoggedIn() {
    dropdownInitialized = true;
    addProfileOpenListener();
    $(".connect-logged-in-only").css("display", "block");
    //Check if cookie subscriber exist
    var isSubscriberCookie = getCookie("isSubscriber");
    if (isSubscriberCookie != null) {
      var date = new Date();
      date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);
      var expires = "; expires=" + date.toUTCString();
      var setSubscriberCookie = "isSubscriber=true" + expires + "; path=/";
      document.cookie = setSubscriberCookie;
    }
    initAdobeSceneIdentifier();
  }
  function initAdobeSceneIdentifier() {
    if (window.digitalData) {
      var dd = window.digitalData;
      var newEvent = {
        eventName: "aaStorePostLogin",
        callString: "aaStorePostLogin",
        details: {
          sceneId: ""
        }
      };
      getSceneId().then(function (x) {
        newEvent.details = {
          SceneID: x !== null && x !== void 0 ? x : ""
        };
        digitalData.user.account = {
          authState: "logged in",
          connectID: getConnectUserInfoFunction().userProfileGuid,
          loyaltyID: x,
          profileID: ""
        };
        var events = dd.events || [];
        events.push(newEvent);
        dd.events = events;
        window.digitalData = dd;
      });
      var languageCode = getConnectUserInfoFunction().Language;
      digitalData.page.pageInfo.language = languageCode != null && languageCode == "fr-ca" ? "fr" : "en";
    }
  }
  function initUserLoggedOut() {
    //Used to append old connect close function to new one
    var connectOldCloseFunc = connect.Settings.Close;
    connect.Settings.Close = function () {
      connectOldCloseFunc();
      if (connect.IsUserLoggedIn() && !dropdownInitialized) {
        initUserLoggedIn();
      }
    };
  }
  function addProfileOpenListener() {
    $(".cineplex_connect .menu-account.dropdown a").click(function (e, element) {
      if ($(".cineplex_connect .menu-account.dropdown").hasClass("connect-dropdown-open")) {
        closeConnect();
      } else {
        openConnect();
      }
    });
  }
  function openConnect() {
    connectOpenScrollTop = Math.max($html.scrollTop(), $body.scrollTop());
    $(".cineplex_connect .menu-account.dropdown").addClass("connect-dropdown-open");
    $body.addClass("connect-open");
    $body.css({
      'top': -connectOpenScrollTop
    });
    $(document).on("focus click touchstart", connectOpenClickHandler);
    $(".primary-nav .menu-item.dropdown-toggle").on("click touchstart", closeConnect);
  }
  function closeConnect() {
    $(".cineplex_connect .menu-account.dropdown").removeClass("connect-dropdown-open");
    $("body").removeClass("connect-open");
    $body.css({
      'top': 'auto'
    });
    $html.scrollTop(connectOpenScrollTop);
    $(document).unbind("focus click touchstart", connectOpenClickHandler);
    $(".primary-nav .menu-item.dropdown-toggle").unbind("click touchstart", closeConnect);
  }
  function connectOpenClickHandler(e) {
    if (!($(e.target).hasClass(".cineplex_connect") || $(e.target).parents(".cineplex_connect").size())) {
      closeConnect();
    }
  }
  //Check Cookie name
  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  //Returns a promise for Async Connect call
  function getSceneId() {
    return new Promise(function (resolve, reject) {
      Cineplex.Connect.GetSceneInfo(function (foundCards) {
        //Returns the first Active Scene Card
        var activeSceneCard = foundCards.Cards.find(function (card) {
          return card.IsActive;
        });
        var sceneId = activeSceneCard ? activeSceneCard.MembershipId : "";
        resolve(sceneId);
      }, function (error) {
        reject("Could not find Scene Cards");
      });
    });
  }
  initFunction();
  return main;
}(jQuery, Cineplex.Connect);