(function () {
  var storeApp = window.storeApp || {};
  storeApp.promoBannerVM = function (ko, promoService) {
    var vm = this;
    vm.init = initialize;
    vm.promoDisplayed = ko.observable(false);
    vm.promoError = ko.observable(false);
    vm.dismissPromoBanner = dismissPromoBanner;
    return vm;
    function initialize(model) {
      var onCheckout = window.location.href.indexOf(model.checkoutUrl) >= 0;
      if (!!model.promoSessionData && !onCheckout) {
        vm.promoDisplayed(model.promoSessionData.couponCode !== '' && model.promoSessionData.showPromoBanner);
        vm.promoAvailable = ko.observable(true);
      } else if (!!model.promoSessionData && model.promoSessionData.couponCode !== '' && onCheckout) {
        pricingPlanId = csGlobal.getUrlParam("PricingPlanId");
        productId = model.productId;
        promoService.validatePromo(model.productId, model.productPricingPlan, hasPromoSuccess, hasPromoError);
        vm.promoAvailable = ko.observable(false);
      }
      vm.promoSuccess = ko.observable(false);
      vm.promoRedeemed = ko.observable(false);
      vm.promoError = ko.observable(false);
    }
    function hasPromoSuccess(data) {
      if (data.IsValid) {
        vm.promoSuccess(true);
      } else {
        vm.promoError(true);
      }
      vm.promoDisplayed(true);
    }
    function hasPromoError(err) {
      //Do Nothing
    }
    function dismissPromoBanner() {
      vm.promoDisplayed(false);
      promoService.dismissPromo();
    }
  };
})();