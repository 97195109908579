function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
var storeApp = window.storeApp || {};
storeApp.footerVM = function (ko, csGlobal, connect, newsLetterService) {
  var vm = this;
  vm.init = initialize;
  return vm;
  function initialize(communicationNewsletterId) {
    vm.signUpClick = signUpClick;
    vm.showSignUp = ko.observable(true);
    checkNewsLetterDisplayOnConnectLoad(communicationNewsletterId);
  }
  function checkNewsLetterDisplayOnConnectLoad(communicationNewsletterId) {
    var oldConnectInitializedCallback = window.connectInitializedCallback || function () {
      return;
    };
    window.connectInitializedCallback = function () {
      oldConnectInitializedCallback();
      isNewsLetterDisplayed(communicationNewsletterId);
    };
  }
  function isNewsLetterDisplayed(communicationNewsletterId) {
    newsLetterService.getNewsletterInfo(communicationNewsletterId, newsLetterFirstSuccessResp, newsLetterFailResp);
  }
  function newsLetterFirstSuccessResp(resp) {
    if (resp.IsSubscribedForStoreNewsletters) {
      vm.showSignUp(false);
    } else {
      initNewsLetterDisplayOnClose();
    }
  }
  function initNewsLetterDisplayOnClose() {
    if (connect != null && _typeof(connect.Settings) != null) {
      var oldConnectCloseFunction = typeof connect.Settings.Close === 'function' ? connect.Settings.Close : function () {
        return;
      };
      connect.Settings.Close = function () {
        oldConnectCloseFunction();
        if (connect.IsUserLoggedIn()) {
          newsLetterService.getNewsletterInfo(newsLetterSuccessResp, newsLetterFailResp);
        }
      };
    }
  }
  function newsLetterSuccessResp(resp) {
    vm.showSignUp(!resp.IsSubscribedForStoreNewsletters);
  }
  function newsLetterFailResp(resp) {
    //Do Nothing Best to Ensure Sign up displays regardless
  }
  function signUpClick() {
    if (Cineplex != null && connect != null && typeof connect.OpenCommunicationCentre === 'function') connect.OpenCommunicationCentre(4, false, '');
  }
};