var storeApp = window.storeApp || {};
storeApp.activateVM = function (ko, $, connect) {
  'use strict';

  var main = {
    init: init,
    initValidate: initValidate,
    showLoginModal: showLoginModal,
    validateQRCode: validateQRCode,
    keyPressHandler: keyPressHandler,
    setButton: setButton
  };
  return main;
  function init(disableSignUpLinkFlag) {
    var connectInitializedCallback = window.connectInitializedCallback || function () {
      return;
    };
    var oldConnectInitializeFunction = connectInitializedCallback;
    window.connectInitializedCallback = function () {
      oldConnectInitializeFunction();
      if (!connect.IsUserLoggedIn()) showLoginModal(disableSignUpLinkFlag);
    };
  }
  function initValidate() {
    setButton();
    if ($("#qr-code").val().length > 0) validateQRCode();
  }
  function showLoginModal(disableSignUpLinkFlag) {
    var connectCloseCallback = connect.Settings.Close || function () {
      return;
    };
    var oldConnectCloseFunction = connectCloseCallback;
    connect.Settings.Close = function () {
      oldConnectCloseFunction();
      if (connect.IsUserLoggedIn()) {
        showSpinner();
        window.location.reload();
      }
    };
    connect.Settings.PopupMode = true;
    if (disableSignUpLinkFlag && disableSignUpLinkFlag === "True") {
      connect.OpenConnectPage(connect.Page.Login, false, "DisableSignUpLink=true");
    } else {
      connect.OpenConnectPage(connect.Page.Login);
    }
  }
  function keyPressHandler(o, e) {
    $("#codeErrorMessage").html('');
    $("#qr-code").removeClass("error-message");
    return e.charCode >= 48 && e.charCode <= 57;
  }
  function setButton() {
    var o = $("#qr-code");
    $("#qrcValidateBtn").attr("disabled", $(o).val().length != $(o).attr("maxlength"));
  }
  function validateQRCode() {
    showSpinner();
    $.ajax({
      url: $("#actionURL").val(),
      type: "GET",
      dataType: "json",
      data: {
        "stCode": $("#qr-code").val()
      },
      success: successCall,
      error: failedCall
    });
  }
  function successCall(result) {
    hideSpinner();
    if (!result.IsSuccessful) {
      $("#codeErrorMessage").html(result.Message);
      $("#qr-code").addClass("error-message");
    } else {
      window.location.href = $("#nextActionURL").val();

      // setting data for Adobe DTM - code is successfully validated
      digitalData.event.eventName = "aaEventInteraction";
      digitalData.event.callString = "aaEventInteraction";
      digitalData.event.interactionName = "QRCode validation | successful";
    }
  }
  function failedCall(error) {
    hideSpinner();
    if (error.Message) {
      $("#codeErrorMessage").html(error.Message);
    }

    // setting data for Adobe DTM - error occured during code validation

    digitalData.event.eventName = "aaEventError";
    digitalData.event.callString = "aaEventError";
    digitalData.event.interactionName = "QRCode validation | failed";
    var newEvent = {
      callString: "aaEventError",
      details: error.Message || "QRcode validation failed! Error occured!",
      eventName: "aaEventError"
    };
    var events = digitalData.events || [];
    events.push(newEvent);
    digitalData.events = events;
  }
  function showSpinner() {
    $("#spinner-background").show();
    $("#lwdAjaxSpinner").show();
  }
  function hideSpinner() {
    $("#spinner-background").hide();
    $("#lwdAjaxSpinner").hide();
  }
};