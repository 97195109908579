(function () {
  var storeApp = window.storeApp || {};
  storeApp.pricingService = function () {
    var self = this;
    self.getPricingPlanByType = getPricingPlanByType;
    self.mapPricingPlan = mapPricingPlan;
    self.getHarmonizedPricingPlan = getHarmonizedPricingPlan;
    return self;
    function mapPricingPlan(pricingPlan, language) {
      var rewardData;
      if (pricingPlan.ScenePurchaseRewardList.length > 0) {
        rewardData = new ProductReward(pricingPlan.PurchaseRewardList[0].GrantCouponId, pricingPlan.ScenePurchaseRewardList[0].Id, pricingPlan.ScenePurchaseRewardList[0].LoyaltyPointAmount, pricingPlan.ScenePurchaseRewardList[0].Name, pricingPlan.ScenePurchaseRewardList[0].OrderTypeCode, pricingPlan.ScenePurchaseRewardList[0].ProductSpecific, pricingPlan.ScenePurchaseRewardList[0].RewardRequiresPurchase);
      }
      return new ProductPricingPlan(rewardData, formatAsCurrency(pricingPlan.ChargeAmount, language), formatAsCurrency(pricingPlan.DiscountedAmount, language), pricingPlan.IsStandalone, pricingPlan.PricingPlanDisplayName, pricingPlan.PricingPlanId, pricingPlan.BaseScenePoints, pricingPlan.BonusScenePoints, pricingPlan.ScreenQualityType, pricingPlan.ContentType, pricingPlan.IsPurchasedByUser, pricingPlan.ExpiresInHours, pricingPlan.DigitalPurchaseType, pricingPlan.AvailabilityStart, pricingPlan.AvailabilityEnd, pricingPlan.IsVip, pricingPlan.RelevantProductId, pricingPlan.IsUpgradeSuperTicket, pricingPlan.IsHDUpgradeSuperTicket, pricingPlan.PricingPlanType);
    }
    function getHarmonizedPricingPlan(serverData, contentType, language) {
      var filteredResults;
      var ScreenQualityLevels = CineplexAppConstants.ScreenQualityLevels;
      var contentPlans = serverData[contentType];
      if (contentPlans && contentPlans.length > 0) {
        filteredResults = contentPlans.filter(function (pricingPlan) {
          return pricingPlan.PricingPlanType.toLowerCase() === CineplexAppConstants.HarmonizedName.toLowerCase();
        });
        if (filteredResults.length > 0) {
          return mapPricingPlan(filteredResults[0], language);
        }
      }
      return null;
    }
    function getPricingPlanByType(serverData, contentType, screenQualityType, isPreorder, language) {
      var filteredResults;
      if (serverData.hasOwnProperty(contentType)) {
        filteredResults = serverData[contentType].filter(function (pricingPlan) {
          return screenQualityType.toLowerCase() === pricingPlan.ScreenQualityType.toLowerCase();
        });
        if (filteredResults.length > 0) {
          return mapPricingPlan(filteredResults[0], language);
        }
      }
      return null;
    }
    function formatAsCurrency(value, language) {
      if (language === "French") {
        return parseFloat(value).toFixed(2).replace('.', ',') + " $";
      } else {
        return "$" + parseFloat(value).toFixed(2);
      }
    }
  }();
})();