(function () {
  ko.bindingHandlers.playlistSlider = {
    init: function init(element, valueAccessor) {
      var configurations = ko.unwrap(valueAccessor());
      setSliderElements(element, configurations.numItemsXl, configurations.numItemsDesktop, configurations.numItemsTablet, configurations.numItemsMobile);
      $(element).on("init", slickcarouselinit).slick({
        centerPadding: '60px',
        slidesToShow: 8,
        slidesToScroll: 8,
        infinite: false,
        lazyLoad: 'ondemand',
        nextArrow: "<div class=\"playlist-indicator playlist-next-indicator\"><div class=\"rail-icon store-icon-railright\" aria-hidden=\"true\"></div><span class=\"sr-only\">Next</span></div>",
        prevArrow: "<div class=\"playlist-indicator playlist-back-indicator\"><div class=\"rail-icon store-icon-railleft\" aria-hidden=\"true\"></div><span class=\"sr-only\">Previous</span></div>",
        responsive: [{
          breakpoint: 1801,
          settings: {
            arrows: true,
            centerPadding: '60px',
            slidesToShow: 6,
            slidesToScroll: 6
          }
        }, {
          breakpoint: 901,
          settings: {
            arrows: true,
            centerPadding: '40px',
            slidesToShow: 4,
            slidesToScroll: 4
          }
        }, {
          breakpoint: 601,
          settings: {
            arrows: true,
            centerPadding: '40px',
            slidesToShow: 3,
            slidesToScroll: 3
          }
        }]
      });
    }
  };
  function setSliderElements(element, numItemsXl, numItemsDesktop, numItemsTablet, numItemsMobile) {
    var screenSize = document.body.clientWidth,
      numItems = 0;
    switch (screenSize) {
      case screenSize >= 1800:
        numItems = numItemsXl;
        break;
      case screenSize >= 900:
        numItems = numItemsDesktop;
        break;
      case screenSize >= 600:
        numItems = numItemsTablet;
        break;
      default:
        numItems = numItemsMobile;
    }
    var movieElements = $(element).find(".movie-card-wrap");
    if (movieElements.length > numItems) {
      var diff = numItems - movieElements.length;
      removeElements = movieElements.slice(diff);
      removeElements.remove();
    }
  }
  function slickcarouselinit(slick) {
    var resizeDebounce = null;

    //initMovieCarouselText(slick);
    //setImageWidthWholeNum(slick);
    fixCarouselHeight(slick);
    $(window).on("resize", function () {
      clearTimeout(resizeDebounce);
      resizeDebounce = setTimeout(function () {
        fixCarouselHeight(slick);
      }, 300);
      //setImageWidthWholeNum(slick);
    });
  }
  function initMovieCarouselText(slick) {
    $(slick.target).find(".card-title").each(function (index, elem) {
      var $elem = $(elem);
      var elemheight = parseInt($elem.css("height"));
      var allowableheight = 3 * parseInt($("body").css("font-size"));
      if (elemheight > allowableheight) {
        cropCarouselText($elem, allowableheight);
      }
    });
  }
  function cropCarouselText($elem, allowableheight) {
    var wordArray = $elem.text().split(" "),
      newWordArray = $elem.text().split(" ");
    for (var i = 0; i < wordArray.length; i++) {
      newWordArray.pop();
      if (checkCarouselTextHeight(newWordArray, $elem, allowableheight)) {
        i = wordArray.length;
      }
    }
  }
  function checkCarouselTextHeight(newWordArray, $elem, allowableheight) {
    var movieText = newWordArray.join(" ");
    $elem.text(movieText);
    var elemheight = parseInt($elem.css("height"));
    if (elemheight > allowableheight) {
      return false;
    } else {
      addEllipsisToCarouselText(movieText, $elem);
      return true;
    }
  }
  function addEllipsisToCarouselText(movieText, $elem) {
    var endText = movieText.substring(0, movieText.length - 3);
    endText += "...";
    $elem.text(endText);
  }
  function fixCarouselHeight(slick) {
    $elem = $($(slick.target).find(".slick-track"));
    $elem.css("height", "auto");
    $elem.height($elem.height());
  }
})();