(function () {
  var storeApp = window.storeApp || {};
  storeApp.newsLetterService = function ($) {
    return {
      getNewsletterInfo: getNewsletterInfo,
      saveNewsletterInfo: saveNewsletterInfo
    };
    function getNewsletterInfo(newsletterId, successCallBack, failCallBack) {
      Cineplex.Connect.GetNewsletterSubscriptions(function (data) {
        var isSignedUpForNewsletter = false;
        if (data && data.NewsletterSubscriptions) {
          for (var i = 0; i < data.NewsletterSubscriptions.length; i++) {
            if (data.NewsletterSubscriptions[i].CommunicationNewsletterId === newsletterId) {
              isSignedUpForNewsletter = true;
            }
          }
        }
        var resp = {
          IsSubscribedForStoreNewsletters: isSignedUpForNewsletter
        };
        successCallBack(resp);
      }, function (error) {
        failCallBack(error);
      });
    }
    function saveNewsletterInfo(isOptingIn, successCallBack, failCallBack) {
      $.ajax({
        type: "POST",
        dataType: "json",
        data: {
          "emailOptIn": isOptingIn
        },
        url: "/Checkout/SaveUserNewsletterSubscription",
        success: successCallBack,
        error: failCallBack
      });
    }
  }(jQuery);
})();