var storeApp = window.storeApp || {};
storeApp.storeGlobalFunctions = function ($) {
  'use strict';

  var DEFAULT_SEARCH_QUERY_STRING = "&fPage=1&fPageSize=30";
  var main = {
    AddAjaxOverlay: function AddAjaxOverlay() {
      $("#loader").show();
    },
    RemoveAjaxOverlay: function RemoveAjaxOverlay() {
      $("#loader").hide();
    },
    getDTMPageName: getDTMPageNameFunction,
    getUrlParam: getUrlParamFunction,
    getDeviceId: getDeviceId,
    getPlatformId: getPlatformId,
    formatDateToDayMonthYear: formatDateToDayMonthYearFunction,
    urlParams: urlSearchParams,
    setCookie: setCookieFunction,
    getCookie: getCookieFunction,
    toQueryString: toQueryStringFunction,
    updateUrl: updateUrlFunction,
    roundUp: roundUpFunction,
    resizeLargeTitle: resizeLargeTitleFunction
    //cropMovieText: cropMovieTextFunction
  };
  function getDeviceId() {
    var platforms = {
      WEB_PC_CHROME: 'web-pc-chrome',
      WEB_PC_FIREFOX: 'web-pc-firefox',
      WEB_PC_IE: 'web-pc-ie',
      WEB_MAC_SAFARI: 'web-mac-safari',
      WEB_MAC_CHROME: 'web-mac-chrome',
      WEB_MAC_FIREFOX: 'web-mac-firefox',
      ANDROID_MOBILE: 'android-mobile',
      IOS_IPHONE: 'ios-iphone'
    };
    var platformId = "";
    var browser = "ie",
      os,
      ua = window.navigator.userAgent,
      platform = window.navigator.platform;
    if (/MSIE/.test(ua)) {
      browser = 'ie';
    } else if (/Chrome/.test(ua)) {
      browser = 'chrome';
      if (/CrOS/.test(ua)) platformId = platforms.ANDROID_MOBILE;
    } else if (/Android/.test(ua)) {
      platformId = platforms.ANDROID_MOBILE;
    } else if (/Firefox/.test(ua)) {
      browser = 'firefox';
    } else if (/Safari/.test(ua)) {
      browser = 'safari';
      if (/iPhone/.test(ua) || /iPad/.test(ua) || /iPod/.test(ua)) {
        platformId = platforms.IOS_IPHONE;
      }
    }
    if (platform === 'MacIntel' || platform === 'MacPPC') {
      os = 'mac';
    } else {
      os = 'pc';
    }
    if (platformId == "") platformId = "web-".concat(os, "-").concat(browser);
    return platformId;
  }
  function getPlatformId() {
    var platformId = "";
    var mobile = ["iphone", "android", "ipad", "blackberry"];
    if (mobile.includes(window.navigator.platform.toLowerCase())) {
      platformId = "mobile web";
    } else {
      platformId = "web";
    }
    return platformId;
  }
  function getUrlParamFunction(key) {
    key = key.replace(/[*+?^$.\[\]{}()|\\\/]/g, "\\$&"); // escape RegEx meta chars
    var match = location.search.match(new RegExp("[?&]" + key + "=([^&]+)(&|$)"));
    return match && decodeURIComponent(match[1].replace(/\+/g, " "));
  }
  function urlSearchParams(name, url) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
  function formatDateToDayMonthYearFunction(d) {
    var date = new Date(d);
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    return d = dd + '/' + mm + '/' + yyyy;
  }
  function getDTMPageNameFunction(path, agilityPageName) {
    var pageName = "";
    var genre = main.getUrlParam("genre");
    var collectionType = main.getUrlParam("type");
    var connectStartupPage = main.getUrlParam("ConnectStartUpPage");
    console.log(agilityPageName);
    if (agilityPageName !== null) {
      if (agilityPageName === "redeem-lp") {
        //check for "Redeem Pin Codes"
        pageName += "redeem|pin-codes";
      } else if (path.indexOf("/promo") === 0) {
        //check for "Promo Pages"
        pageName += "promo|" + agilityPageName;
      } else if (agilityPageName === "search") {
        //check for "Search Pages"
        if (genre !== null && genre !== "") {
          //check for "Genre Pages"
          pageName += "genre|" + genre;
        } else {
          pageName += "search";
        }
      } else if (agilityPageName === "collection") {
        //check for "Collection Pages"
        pageName += "collection";
        if (collectionType !== null && collectionType !== "") {
          pageName += "|" + collectionType;
        }
      } else if (agilityPageName === "default") {
        pageName += "homepage";
      } else if (connectStartupPage === "41") {
        pageName += "redeem|digital-cards";
      } else {
        pageName += agilityPageName;
      }
    }
    return pageName;
  }
  function setCookieFunction(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toGMTString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
  function getCookieFunction(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  function toQueryStringFunction(obj) {
    var parts = [];
    for (var i in obj) {
      if (obj.hasOwnProperty(i)) {
        if (encodeURIComponent(obj[i])) parts.push(encodeURIComponent(i) + "=" + encodeURIComponent(obj[i]));
      }
    }
    return parts.join("&");
  }
  function updateUrlFunction(queryString) {
    var oldUrl = window.location.href;
    var newUrl = oldUrl;
    if (history.replaceState) {
      newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname;
      if (queryString) newUrl += "?" + queryString;
      if (newUrl.localeCompare(oldUrl) !== 0 && newUrl.localeCompare(oldUrl + DEFAULT_SEARCH_QUERY_STRING)) {
        window.history.replaceState({
          path: newUrl
        }, '', newUrl);
      }
    }
  }
  function roundUpFunction(num, decimalPoints) {
    var precision = Math.pow(10, decimalPoints);
    return Math.ceil(num * precision) / precision;
  }
  function resizeLargeTitleFunction($elem, numLines, smallFontSize, callback) {
    $elem.css({
      "font-size": ""
    }); // Reset height of element

    var maxAllowableHeight = Math.floor(numLines * parseInt($elem.css("line-height"))),
      elemHeight = parseInt($elem.css("height"));
    if (elemHeight > maxAllowableHeight) {
      $elem.css("font-size", smallFontSize);
    }
    if (callback != null && typeof callback === "function") {
      callback($elem);
    }
  }
  return main;
}(jQuery);