CSOmnitureTrackingApp = window.csOmnitureTrackingModule || {};
CSOmnitureTrackingApp.csOmnitureTrackingModule = function ($) {
  'use strict';

  var DIRECT_CALL_RULE = "dtmAction";
  var PURCHASE_EVENT_RULE = "dtmPurchase";
  var SEARCH_EVENT_RULE = "dtmSearch";
  var EXPLORE_EVENT_RULE = "dtmExploreevent";
  var omnitureTrackCustomAction = function omnitureTrackCustomAction(str) {
    try {
      _satellite.track(str);
    } catch (ex) {
      console.log("Error: " + ex);
      //catch error
    }
  };
  var main = {
    homePage: {
      exploreClickEvent: function exploreClickEvent(exploreSection, exploreSelected, exploreItemPosition) {
        digitalData.component.componentInfo.componentName = exploreSection; //title of Section
        digitalData.component.componentInfo.componentItem = exploreSelected; //movie item title or "See More"
        digitalData.component.componentInfo.componentItemPosition = exploreItemPosition; // movie item index + 1
        omnitureTrackCustomAction(EXPLORE_EVENT_RULE);
      }
    },
    carousel: {
      clickCarouselControl: function clickCarouselControl(type) {
        digitalData.event.eventName = "carousel";
        digitalData.event.callString = type;
        omnitureTrackCustomAction(DIRECT_CALL_RULE);
      }
    },
    mainMenu: {
      clickMenuItem: function clickMenuItem(menuValue) {
        digitalData.event.eventName = "nav-menu";
        digitalData.event.callString = menuValue;
        omnitureTrackCustomAction(DIRECT_CALL_RULE);
      }
    },
    search: {
      checkFilter: function checkFilter(type, value) {
        switch (type) {
          case "Genres":
            digitalData.event.eventName = "filter-genre";
            digitalData.event.callString = value;
            break;
          case "ReleaseYear":
            digitalData.event.eventName = "filter-year";
            digitalData.event.callString = value;
            break;
          case "Language":
            digitalData.event.eventName = "filter-language";
            switch (value) {
              case "English":
                digitalData.event.callString = "en";
                break;
              case "French":
                digitalData.event.callString = "fr";
                break;
              default:
                digitalData.event.callString = value;
                break;
            }
            break;
          default:
            digitalData.event.eventName = "filter-" + type;
            break;
        }
      },
      autoCompleteResult: function autoCompleteResult(query, totalRecords) {
        digitalData.user.search.searchType = "sayt";
        digitalData.user.search.searchTerm = query;
        digitalData.user.search.searchResultsNumber = totalRecords;
        digitalData.event.eventName = "search-start";
        digitalData.event.callString = "aaEventSearchStart";
        if (!digitalData.events.find(function (x) {
          return x.eventName === "search-start";
        })) {
          digitalData.events.push(digitalData.event);
        }
        omnitureTrackCustomAction(SEARCH_EVENT_RULE);
      },
      dropDownItemSelect: function dropDownItemSelect() {
        digitalData.event.eventName = "search-complete";
        digitalData.event.callString = "aaEventSearchComplete";
        digitalData.events.push(digitalData.event);
        omnitureTrackCustomAction(SEARCH_EVENT_RULE);
      },
      searchResult: function searchResult(query, totalRecords) {
        digitalData.user.search.searchType = "explicit";
        digitalData.user.search.searchTerm = query;
        digitalData.user.search.searchResultsNumber = totalRecords;
        if (query.length > 2 && window.location.pathname.replace(/^\/([^\/]*).*$/, '$1') !== 'Search') {
          digitalData.event.eventName = "search-start";
          digitalData.event.callString = "aaEventSearchStart";
        } else {
          digitalData.event.eventName = "search-complete";
          digitalData.event.callString = "aaEventSearchComplete";
        }
        digitalData.events.push(digitalData.event);
        omnitureTrackCustomAction(DIRECT_CALL_RULE);
        omnitureTrackCustomAction(SEARCH_EVENT_RULE);
      }
    },
    superticket: {
      isRedeemed: function isRedeemed() {
        digitalData.product.productCategory = "Superticket Redeem";
        digitalData.content.contentType = "SD"; // Movie quality for SuperTicket (prop18) should default to SD unless the purchase is an upgrade to HD
        digitalData.event.eventName = "st-redeem";
        digitalData.event.callString = "";
        omnitureTrackCustomAction(DIRECT_CALL_RULE);
      }
    },
    dcplus: {
      isRedeemed: function isRedeemed() {
        digitalData.product.productCategory = "DC-Plus Redeem";
      }
    },
    pdp: {
      clickRent: function clickRent() {
        digitalData.event.eventName = "rent";
        digitalData.event.callString = "click";
        omnitureTrackCustomAction(DIRECT_CALL_RULE);
      },
      clickBuy: function clickBuy() {
        digitalData.event.eventName = "buy";
        digitalData.event.callString = "click";
        omnitureTrackCustomAction(DIRECT_CALL_RULE);
      },
      clickCheckoutCart: function clickCheckoutCart(contentType, quality) {
        digitalData.event.eventName = "addtocart";
        digitalData.event.callString = "click";
        switch (contentType) {
          case "Buy":
            switch (quality) {
              case "sd":
                digitalData.event.callString = "buy-sd";
                break;
              case "hd":
                digitalData.event.callString = "buy-hd";
                break;
              case "uhd":
                digitalData.event.callString = "buy-uhd";
                break;
            }
            break;
          case "Rent":
            switch (quality) {
              case "sd":
                digitalData.event.callString = "rent-sd";
                break;
              case "hd":
                digitalData.event.callString = "rent-hd";
                break;
              case "uhd":
                digitalData.event.callString = "rent-hd";
                break;
            }
            digitalData.event.eventName = "rent";
            break;
        }
        omnitureTrackCustomAction(DIRECT_CALL_RULE);
      },
      clickCloseButton: function clickCloseButton() {
        digitalData.event.eventName = "cancel";
        digitalData.event.callString = "click";
        omnitureTrackCustomAction(DIRECT_CALL_RULE);
      },
      clickRelatedItem: function clickRelatedItem(movie) {
        var events = window.digitalData.events || [];
        digitalData.event.eventName = "related_titles | " + movie;
        digitalData.event.callString = "aaEventInteraction";
        var related = {
          eventName: "interaction-event",
          callString: "aaEventInteraction",
          interactionName: "related_titles | " + movie,
          interactionType: "click"
        };
        events.push(related);
        omnitureTrackCustomAction(DIRECT_CALL_RULE);
      }
    },
    checkout: {
      clickEditButton: function clickEditButton(section) {
        digitalData.event.eventName = "checkout-edit";
        digitalData.event.callString = section;
        omnitureTrackCustomAction(DIRECT_CALL_RULE);
      },
      clickAddButton: function clickAddButton(section) {
        digitalData.event.eventName = "checkout-add";
        digitalData.event.callString = section;
        omnitureTrackCustomAction(DIRECT_CALL_RULE);
      },
      clickApplyPromoCode: function clickApplyPromoCode() {
        digitalData.event.eventName = "promo";
        digitalData.event.callString = "click";
        omnitureTrackCustomAction(DIRECT_CALL_RULE);
      }
    },
    onSuccessfulPurchase: function onSuccessfulPurchase(orderResult) {
      if (orderResult != null) {
        var _orderResult$PaymentM, _digitalData$product$, _digitalData$cart$pro, _orderResult$PromoCod, _orderResult$ScenePoi, _orderResult$ScenePoi2, _orderResult$Transact;
        digitalData.event.eventName = "order-completed";
        digitalData.event.callString = "";
        digitalData.cart.paymentType = digitalData.cart.promoApplied ? "Promo Code" : (_orderResult$PaymentM = orderResult.PaymentMethod) !== null && _orderResult$PaymentM !== void 0 ? _orderResult$PaymentM : "";
        digitalData.cart.products = (_digitalData$product$ = digitalData.product.productName) !== null && _digitalData$product$ !== void 0 ? _digitalData$product$ : "";
        digitalData.cart.promoApplied = (_digitalData$cart$pro = digitalData.cart.promoApplied) !== null && _digitalData$cart$pro !== void 0 ? _digitalData$cart$pro : false;
        digitalData.cart.promoCode = (_orderResult$PromoCod = orderResult.PromoCodeType) !== null && _orderResult$PromoCod !== void 0 ? _orderResult$PromoCod : "";
        digitalData.cart.quantity = 1;
        digitalData.cart.revenue = orderResult.TotalAmount;
        digitalData.cart.scenePointsEarned = (_orderResult$ScenePoi = orderResult.ScenePointsEarned) !== null && _orderResult$ScenePoi !== void 0 ? _orderResult$ScenePoi : "";
        digitalData.cart.scenePointsRedeemed = (_orderResult$ScenePoi2 = orderResult.ScenePointsRedeemed) !== null && _orderResult$ScenePoi2 !== void 0 ? _orderResult$ScenePoi2 : "";
        digitalData.cart.transactionID = (_orderResult$Transact = orderResult.TransactionId) !== null && _orderResult$Transact !== void 0 ? _orderResult$Transact : "";
        omnitureTrackCustomAction(PURCHASE_EVENT_RULE);
      }
    }
  };
  return main;
}(jQuery);