$(function () {
  $(window).scroll(function () {
    var scroll = Math.max($("html").scrollTop(), $("body").scrollTop());
    if (scroll > 0) {
      $(".primary-nav.navbar.navbar-default").addClass("primary-nav-scrolled");
    } else {
      $(".primary-nav.navbar.navbar-default").removeClass("primary-nav-scrolled");
    }
  });
  $(".primary-nav .dropdown").on('show.bs.dropdown', function (e) {
    $(".primary-nav.navbar.navbar-default").addClass("primary-nav-dropdown-open");
  });
  $(".primary-nav .dropdown").on('hide.bs.dropdown', function (e) {
    $(".primary-nav.navbar.navbar-default").removeClass("primary-nav-dropdown-open");
  });
  initMenuHighlights();
  function initMenuHighlights() {
    if (window.location.href.indexOf("/search") > -1) {
      $(".primary-nav-search a").each(function (index, elem) {
        var $elem = $(elem);
        //$elem.addClass("current");
      });
    } else {
      $(".primary-nav .navbar-nav > li > a").each(function (index, elem) {
        var $elem = $(elem);
        if ($elem.attr("href") === document.location.pathname) $elem.addClass("current");
      });
    }
  }

  /* SCRIPTS TO ENABLE POLYFILL FOR FLEXBOXES */

  if (Modernizr.flexbox) {} else {
    flexibility(document.documentElement);
  }
});