ko.bindingHandlers.invisible = {
  update: function update(element, valueAccessor) {
    var hidden = ko.unwrap(valueAccessor()),
      elementVisible = element.style.visibility !== "hidden";
    if (hidden && elementVisible) {
      element.style.visibility = "hidden";
    } else {
      element.style.visibility = "visible";
    }
  }
};