(function () {
  var storeApp = window.storeApp || {};
  storeApp.pricingModalViewModel = function (params) {
    var vm = this;
    var connectModule = params.model.connectModule;
    var checkoutUrl = params.model.checkoutUrl;

    //Populating static fields for model
    vm.rentSdOrHdText = params.model.rent;
    vm.rentHDUnavailableText = params.model.rentHDUnavailableText;
    vm.rentUHDButtonUnavailableText = params.model.rentUHDButtonUnavailableText;
    vm.rentSdOrHdText = params.model.rentSdOrHdText;
    vm.rentSDUnavailableText = params.model.rentSDUnavailableText;
    vm.noRentalPricingPlansAvailableText = params.model.noRentalPricingPlansAvailableText;
    vm.buySdOrHdText = params.model.buySdOrHdText;
    vm.buyUHDUnavailableText = params.model.buyUHDUnavailableText;
    vm.buyHDUnavailableText = params.model.buyHDUnavailableText;
    vm.buySDUnavailableText = params.model.buySDUnavailableText;
    vm.noBuyPlansAvailableText = params.model.noBuyPlansAvailableText;
    vm.preOrderSDOrHDText = params.model.preOrderSDOrHDText;
    vm.modalId = params.model.modalId;
    vm.harmonizedPricingMessage = new DOMParser().parseFromString(params.model.harmonizedPricingMessage, 'text/html').body.textContent;
    vm.hdText = params.model.hdText;
    vm.sdText = params.model.sdText;

    //Populating observables for modal
    vm.productName = params.productName;
    vm.releaseYear = params.releaseYear;
    vm.mpaaRating = params.mpaaRating;
    vm.isGenericMobile = params.isGenericMobile;
    vm.mobileMarqueeSrcSet = params.mobileMarqueeSrcSet;
    vm.mobileImgSrc = params.mobileImgSrc;
    vm.pricingModalVisible = params.pricingModalVisible;
    vm.rentHarmoPricingPlan = params.rentHarmoPricingPlan;
    vm.rentUHDPricingPlan = params.rentUHDPricingPlan;
    vm.rentHDPricingPlan = params.rentHDPricingPlan;
    vm.rentSDPricingPlan = params.rentSDPricingPlan;
    vm.buyHarmoPricingPlan = params.buyHarmoPricingPlan;
    vm.buyUHDPricingPlan = params.buyUHDPricingPlan;
    vm.buyHDPricingPlan = params.buyHDPricingPlan;
    vm.buySDPricingPlan = params.buySDPricingPlan;
    vm.preorderSDPricingPlan = params.preorderSDPricingPlan;
    vm.preorderHDPricingPlan = params.preorderHDPricingPlan;
    //PreOrderHarmonizedPricingPlan
    vm.preorderHarmoPricingPlan = params.preorderHarmoPricingPlan;

    //Public Functions
    vm.modalClosed = params.pricingModalClose;
    vm.proceedToCheckout = proceedToCheckout;

    //Computeds

    vm.isHarmonizedPricing = ko.computed(function () {
      return vm.buyHarmoPricingPlan() && vm.buyHarmoPricingPlan().PricingPlanType.toLowerCase() === CineplexAppConstants.HarmonizedName || vm.rentHarmoPricingPlan() && vm.rentHarmoPricingPlan().PricingPlanType.toLowerCase() === CineplexAppConstants.HarmonizedName;
    });
    vm.isNonHarmonizedBuyBtnsVisible = ko.computed(function () {
      return !vm.isHarmonizedPricing() && (!!vm.buyUHDPricingPlan() && !vm.buyUHDPricingPlan().IsPurchasedByUser || !!vm.buyHDPricingPlan() && !vm.buyHDPricingPlan().IsPurchasedByUser || !!vm.buySDPricingPlan() && !vm.buySDPricingPlan().IsPurchasedByUser);
    });
    vm.isHarmonizedBuyBtnVisible = ko.computed(function () {
      return vm.isHarmonizedPricing() && !!vm.buyHarmoPricingPlan() && !vm.buyHarmoPricingPlan().IsPurchasedByUser;
    });
    vm.isNonHarmonizedRentBtnsVisible = ko.computed(function () {
      return !vm.isHarmonizedPricing() && (!!vm.rentUHDPricingPlan() && !vm.rentUHDPricingPlan().IsPurchasedByUser || !!vm.rentHDPricingPlan() && !vm.rentHDPricingPlan().IsPurchasedByUser || !!vm.rentSDPricingPlan() && !vm.rentSDPricingPlan().IsPurchasedByUser);
    });
    vm.isHarmonizedRentBtnVisible = ko.computed(function () {
      return vm.isHarmonizedPricing() && !!vm.rentHarmoPricingPlan() && !vm.rentHarmoPricingPlan().IsPurchasedByUser;
    });

    //Visibility Computeds
    vm.buyBtnsVisible = ko.computed(function () {
      return vm.isNonHarmonizedBuyBtnsVisible() || vm.isHarmonizedBuyBtnVisible();
    });
    vm.rentBtnsVisible = ko.computed(function () {
      return vm.isNonHarmonizedRentBtnsVisible() || vm.isHarmonizedRentBtnVisible();
    });
    vm.preorderBtnsVisible = ko.computed(function () {
      return (!!vm.preorderSDPricingPlan() || !!vm.preorderHDPricingPlan() || !!vm.preorderHarmoPricingPlan()) && !(!!vm.preorderSDPricingPlan() && vm.preorderSDPricingPlan().IsPurchasedByUser || !!vm.preorderHDPricingPlan() && vm.preorderHDPricingPlan().IsPurchasedByUser || !!vm.preorderHarmoPricingPlan() && vm.preorderHarmoPricingPlan().IsPurchasedByUser) && !(!!vm.buySDPricingPlan() && vm.buySDPricingPlan().IsPurchasedByUser) && !(!!vm.buyHDPricingPlan() && vm.buyHDPricingPlan().IsPurchasedByUser) && !(!!vm.buyHarmoPricingPlan() && vm.buyHarmoPricingPlan().IsPurchasedByUser);
    });
    vm.availablePricingPlans = ko.computed(function () {
      var pricingPlanArray = [vm.buyUHDPricingPlan(), vm.buyHDPricingPlan(), vm.buySDPricingPlan(), vm.rentUHDPricingPlan(), vm.rentHDPricingPlan(), vm.rentSDPricingPlan(), vm.preorderHDPricingPlan(), vm.preorderSDPricingPlan()];
      return pricingPlanArray.filter(function (pricingPlan) {
        return pricingPlan !== null && !pricingPlan.IsPurchasedByUser;
      });
    });

    //Checking Pricing, UHD
    vm.nonHarmonizedHighestQuality = ko.computed(function () {
      var qualityLevel = CineplexAppConstants.ScreenQualityLevels;
      if (!vm.availablePricingPlans() || vm.availablePricingPlans().length === 0) {
        return null;
      }
      return vm.availablePricingPlans().reduce(function (prev, current) {
        return qualityLevel[prev.ScreenQualityType.toLowerCase()] > qualityLevel[current.ScreenQualityType.toLowerCase()] ? prev : current;
      }).ScreenQualityType.toUpperCase();
    });
    //Checking Pricing, UHD
    vm.isNonHarmonizedUHDCapable = ko.computed(function () {
      if (!vm.availablePricingPlans() || vm.availablePricingPlans().length === 0) {
        return false;
      }
      return vm.availablePricingPlans().filter(function (x) {
        return x.ScreenQualityType.toLowerCase() === 'uhd';
      }).length > 0;
    });
    vm.availableHarmoPricingPlans = ko.computed(function () {
      var pricingPlanArray = [vm.buyHarmoPricingPlan(), vm.rentHarmoPricingPlan()];
      return pricingPlanArray.filter(function (pricingPlan) {
        return pricingPlan !== null && !pricingPlan.IsPurchasedByUser;
      });
    });
    vm.isHarmonizedUHDCapable = ko.computed(function () {
      if (!vm.isHarmonizedPricing()) {
        return false;
      }
      return vm.availableHarmoPricingPlans().filter(function (x) {
        return x.ScreenQualityType.toLowerCase() === 'uhd';
      }).length > 0;
    });

    //Scene Pts Text
    vm.rentHarmoBasePtsTxt = ko.computed(function () {
      return getScenePointsText(vm.rentHarmoPricingPlan());
    });
    vm.rentHarmoBonusPtsTxt = ko.computed(function () {
      return getScenePointsText(vm.rentHarmoPricingPlan(), true);
    });
    vm.rentUHDBasePtsTxt = ko.computed(function () {
      return getScenePointsText(vm.rentUHDPricingPlan());
    });
    vm.rentUHDBonusPtsTxt = ko.computed(function () {
      return getScenePointsText(vm.rentUHDPricingPlan(), true);
    });
    vm.rentHDBasePtsTxt = ko.computed(function () {
      return getScenePointsText(vm.rentHDPricingPlan());
    });
    vm.rentHDBonusPtsTxt = ko.computed(function () {
      return getScenePointsText(vm.rentHDPricingPlan(), true);
    });
    vm.rentSDBasePtsTxt = ko.computed(function () {
      return getScenePointsText(vm.rentSDPricingPlan());
    });
    vm.rentSDBonusPtsTxt = ko.computed(function () {
      return getScenePointsText(vm.rentSDPricingPlan(), true);
    });
    vm.buyHarmoBasePtsTxt = ko.computed(function () {
      return getScenePointsText(vm.buyHarmoPricingPlan());
    });
    vm.buyHarmoBonusPtsTxt = ko.computed(function () {
      return getScenePointsText(vm.buyHarmoPricingPlan(), true);
    });
    vm.buyUHDBasePtsTxt = ko.computed(function () {
      return getScenePointsText(vm.buyUHDPricingPlan());
    });
    vm.buyUHDBonusPtsTxt = ko.computed(function () {
      return getScenePointsText(vm.buyUHDPricingPlan(), true);
    });
    vm.buyHDBasePtsTxt = ko.computed(function () {
      return getScenePointsText(vm.buyHDPricingPlan());
    });
    vm.buyHDBonusPtsTxt = ko.computed(function () {
      return getScenePointsText(vm.buyHDPricingPlan(), true);
    });
    vm.buySDBasePtsTxt = ko.computed(function () {
      return getScenePointsText(vm.buySDPricingPlan());
    });
    vm.buySDBonusPtsTxt = ko.computed(function () {
      return getScenePointsText(vm.buySDPricingPlan(), true);
    });
    vm.preorderHDBasePtsTxt = ko.computed(function () {
      return getScenePointsText(vm.preorderHDPricingPlan());
    });
    vm.preorderHDBonusPtsTxt = ko.computed(function () {
      return getScenePointsText(vm.preorderHDPricingPlan(), true);
    });
    vm.preorderSDBasePtsTxt = ko.computed(function () {
      return getScenePointsText(vm.preorderSDPricingPlan());
    });
    vm.preorderSDBonusPtsTxt = ko.computed(function () {
      return getScenePointsText(vm.preorderSDPricingPlan(), true);
    });
    vm.preorderHarmoBasePtsTxt = ko.computed(function () {
      return getScenePointsText(vm.preorderHarmoPricingPlan());
    });
    vm.preorderHarmoBonusPtsTxt = ko.computed(function () {
      return getScenePointsText(vm.preorderHarmoPricingPlan(), true);
    });
    function getScenePointsText(pricingPlan, addBonus) {
      var base = 0,
        bonus = 0;
      if (pricingPlan) {
        base = pricingPlan.BaseScenePoints;
        bonus = pricingPlan.BonusScenePoints;
      }
      if (addBonus) return params.model.bonusScenePtsTxt.replace('{0}', base).replace('{1}', bonus);else return params.model.baseScenePtsTxt.replace('{0}', base);
    }
    function proceedToCheckout(purchaseType, productId, pricingPlanId, qualityType) {
      vm.pricingModalVisible(false);

      //omniture tracking
      csTracking.pdp.clickCheckoutCart(purchaseType, qualityType);
      if (purchaseType === "Rent") {
        csTracking.pdp.clickRent();
      } else if (purchaseType === "Buy") {
        csTracking.pdp.clickBuy();
      }

      //$("#pdp-price-modal").modal("hide");
      if (connectModule.IsUserLoggedIn()) {
        window.location.href = checkoutUrl + productId + "?PricingPlanId=" + pricingPlanId;
      } else {
        //Used to append old connect close function to new one
        var connectOldCloseFunc = connectModule.Settings.Close;
        connectModule.Settings.Close = function () {
          setTimeout(function () {
            connectOldCloseFunc();
            if (connectModule.IsUserLoggedIn()) {
              window.location.href = checkoutUrl + productId + "?PricingPlanId=" + pricingPlanId;
            } else {
              params.connectFailedLoginCallback();
              connectModule.Settings.Close = function () {
                connectOldCloseFunc();
              };
            }
          }, 50);
        };
        connectModule.OpenConnectPage(1);
      }
    }
  };
})();