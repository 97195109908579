var storeApp = window.storeApp || {};
storeApp.searchPageVM = function (ko, $, connect, csGlobal, csTracking) {
  var vm = {
    init: initFunction,
    initialized: false,
    vmLanguageSetting: "English",
    getPlatformId: "samsung- tizen",
    query: "",
    fGenre: [],
    fYear: [],
    fLang: [],
    fSortBy: "",
    queryDisplay: "",
    genreDisplay: ko.observable(""),
    searchInputText: ko.observable(""),
    sortFilterInit: true,
    pageSelected: ko.observable(1),
    availablePageSize: ko.observableArray([]),
    selectedPageSize: ko.observable(30),
    availableSort: ko.observableArray([]),
    selectedSort: ko.observable(''),
    dataCallOccurred: ko.observable(false),
    records: ko.observableArray([]),
    totalRecordCount: ko.observable(0),
    pageInfo: ko.observableArray([]),
    pagesAvailable: ko.observableArray([]),
    getDisplayTitle: getDisplayTitleFunction,
    searchResultTemplate: 'search-result-store',
    fromSource: 'store',
    dataProperty: 'Store',
    RefinementsList: ko.observableArray([]).extend({
      notify: 'always'
    }),
    //Has categories and nested refinements
    filterOverlayVisible: ko.observable(false),
    toggleFilterOverlay: toggleFilterOverlayFunction,
    mobileRefinementsList: ko.observableArray([]),
    windowSize: ko.observable(findWindowSize()).extend({
      notify: 'always'
    }),
    createImgSrc: createImgSrcFunction,
    searchKeywordSectionVisible: ko.observable(false),
    searchKeywordSections: ko.observableArray([]),
    searchKeywordContent: ko.observable(''),
    isLoaded: ko.observable(false),
    clearAllFilters: clearAllFilters,
    //initCropMovieText: cropMovieTextFunction,
    isFrenchMovie: isFrenchMovieFunction,
    initMoviesGrid: initMoviesGrid,
    resultsLoadingLoader: ko.observable(false),
    correctScrollHeightNeeded: 10000000000000,
    onLastPage: false,
    onLastSearchPage: ko.observable(false),
    fPage: 1,
    fPageSize: 30,
    itemsPerRowXS: 3,
    itemsPerRowSM: 4,
    itemsPerRowMD: 6,
    itemsPerRowLG: 8,
    itemsPerLoadXS: 15,
    itemsPerLoadSM: 30,
    itemsPerLoadMD: 45,
    itemsPerLoadLG: 60,
    deviceSpecificGenresURL: "",
    scrollDebounce: null,
    windowResizeTimeout: null,
    displayNumFilters: ko.observable(false)
  };

  //Subscribers are here

  return vm;
  function updateResults(restartPaging, firstCall) {
    if (restartPaging) {
      vm.pageSelected(1);
      vm.fPage = 1;
      vm.onLastPage = false;
      vm.onLastSearchPage(false);
    } else {
      vm.pageSelected(vm.pageSelected() + 1);
      vm.fPage++;
    }
    var param2 = 'query=' + vm.query + '&from=' + vm.fromSource + getRefinementsUrlString() + '&page=' + vm.pageSelected() + '&pageSize=' + vm.fPageSize + '&sortBy=' + vm.selectedSort() + '&lang=' + $("meta[http-equiv='content-language']").attr("content");
    getSearchResults(param2, function (data) {
      var selectedData = data;
      if (selectedData != null) {
        //RePopulate ViewModel
        //check if redirect is required
        if (!(selectedData.redirect != null && selectedData.redirect !== "")) {
          if (firstCall) {
            //TODO: Add Way of Getting user's page for request
            //Done to determine what page we're currently on if user came back to page after loading more pages
            //var moviesPerPage = getNumMoviesPerPage(vm.itemsPerLoadLG, vm.itemsPerLoadMD, vm.itemsPerLoadSM, vm.itemsPerLoadXS);
            //var calibratedPage = selectedData.Records.length / moviesPerPage;
            populateRefinements(selectedData);
            getInitialFilters();
            //vm.selectedPageSize(moviesPerPage);
            //vm.fPageSize = moviesPerPage;
            //vm.pageSelected(calibratedPage);
            //vm.fPage = calibratedPage;
          }
          var totalRecords = restartPaging ? selectedData.Records.length : vm.records().length + selectedData.Records.length;
          if (totalRecords === data.totalRecordCount) {
            vm.onLastPage = true;
            vm.onLastSearchPage(true);
          }
          vm.pageInfo(selectedData.pageInfo);
          restartPaging ? vm.records(selectedData.Records) : vm.records(vm.records().concat(selectedData.Records));
          vm.pagesAvailable(selectedData.pages);
          vm.totalRecordCount(selectedData.totalRecordCount);

          //omniture tracking for search results
          var validSearch = vm.query === "" ? "*" : vm.query;
          csTracking.search.searchResult(validSearch, selectedData.totalRecordCount);
        }
      }
    });
  }
  function initFunction(dataModel, language, storeCollectionSetting, searchSaytCollection, saytAreaSetting, productDetailPageUrl, searchPageUrl, itemsPerLoadLG, itemsPerLoadMD, itemsPerLoadSM, itemsPerLoadXS, numItemsPerRowXS, numItemsPerRowSM, numItemsPerRowMD, numItemsPerRowLG, deviceSpecificGenresURL) {
    //var currentPageUrlParam = csGlobal.getUrlParam("fPage");
    //var currentPage = currentPageUrlParam ? parseInt(currentPageUrlParam) : 1;
    //var pageLengthUrlParam = csGlobal.getUrlParam("fPageSize");
    //var pageLength = pageLengthUrlParam ? parseInt(pageLengthUrlParam) : getNumMoviesPerPage(itemsPerLoadLG, itemsPerLoadMD, itemsPerLoadSM, itemsPerLoadXS);
    //var initialRequestSize = pageLength * currentPage;

    //vm.fPage = currentPage;
    //vm.pageSelected(currentPage);

    // ADOBE DTM parameteres
    if (digitalData !== null || digitalData !== 'undefined') {
      digitalData.user.account.authState = dataModel.isUserLoggedIn;
      digitalData.user.account.connectID = dataModel.userConnectGuid;
    }
    //END: ADOBE DTM parameteres

    vm.selectedPageSize(getNumMoviesPerPage(itemsPerLoadLG, itemsPerLoadMD, itemsPerLoadSM, itemsPerLoadXS));
    vm.fPageSize = getNumMoviesPerPage(itemsPerLoadLG, itemsPerLoadMD, itemsPerLoadSM, itemsPerLoadXS);
    vm.itemsPerRowXS = numItemsPerRowXS;
    vm.itemsPerRowSM = numItemsPerRowSM;
    vm.itemsPerRowMD = numItemsPerRowMD;
    vm.itemsPerRowLG = numItemsPerRowLG;
    vm.itemsPerLoadXS = itemsPerLoadXS;
    vm.itemsPerLoadSM = itemsPerLoadSM;
    vm.itemsPerLoadMD = itemsPerLoadXS;
    vm.itemsPerLoadLG = itemsPerLoadXS;
    vm.deviceSpecificGenresURL = deviceSpecificGenresURL;
    vm.vmLanguageSetting = language;
    vm.vmSearchApiUrl = dataModel.searchApiUrl;
    vm.searchPageUrl = searchPageUrl;
    vm.goToSearchPage = goToSearchPageFunction;
    vm.availableSort(dataModel.SortDropDownData);
    vm.availablePageSize(dataModel.PageSizeDropDownData);

    //set special search keywords
    vm.searchKeywordSections(dataModel.searchKeywordSections);
    if (language === "French") {
      vm.genreDisplay(csGlobal.getUrlParam("genrefr"));
    } else {
      vm.genreDisplay(csGlobal.getUrlParam("genre"));
    }

    //Computed functions

    vm.numFilters = ko.computed(function () {
      var total = 0;
      ko.utils.arrayForEach(vm.RefinementsList(), function (refinementItems) {
        ko.utils.arrayForEach(refinementItems.Refinements, function (refinement) {
          if (refinement.Selected()) {
            total++;
          }
        });
      });
      if (total > 0) {
        vm.displayNumFilters(true);
      } else {
        vm.displayNumFilters(false);
      }
      return total;
    });
    vm.resultsPerRow = ko.computed(function () {
      var windowWidth = vm.windowSize();
      if (windowWidth >= 1800) {
        return vm.itemsPerRowLG;
      } else if (windowWidth >= 900) {
        return vm.itemsPerRowMD;
      } else if (windowWidth >= 600) {
        return vm.itemsPerRowSM;
      } else {
        return vm.itemsPerRowXS;
      }
    }).extend({
      notify: 'always'
    });
    vm.resultRows = ko.pureComputed(function () {
      var result = [],
        row,
        colLength = parseInt(vm.resultsPerRow(), 10);
      for (var i = 0, j = vm.records().length; i < j; i++) {
        if (i % colLength === 0) {
          if (row) {
            result.push(row);
          }
          row = [];
        }
        row.push(vm.records()[i]);
      }
      if (row) result.push(row);
      return result;
    });
    vm.noResults = ko.pureComputed(function () {
      if (vm.dataCallOccurred() && (vm.records() == null || vm.records().length === 0)) {
        return true;
      } else {
        return false;
      }
    });

    //Misc

    initWindowResize();
    initialSearchCall();

    //Setup Subscriptions

    vm.selectedPageSize.subscribe(function (value) {
      vm.fPageSize = value;
      if (vm.initialized) {
        updateResults(true, false);
      }
    }.bind(vm));
    vm.selectedSort.subscribe(function (value) {
      vm.fSortBy = value;
      if (vm.initialized) {
        updateResults(true, false);
      }
    }.bind(vm));
    window.onbeforeunload = updateUrlWithFilters;

    //Set up sayt for large search box
    initSearchAutocomplete(storeCollectionSetting, searchSaytCollection, saytAreaSetting, productDetailPageUrl);
    vm.initialized = true;
  }
  function initWindowResize() {
    $(window).on("resize", function () {
      clearTimeout(vm.windowResizeTimeout);
      vm.windowResizeTimeout = setTimeout(windowResize, 500);
    });
  }
  function windowResize() {
    vm.windowSize(findWindowSize());
    //if (vm.windowSize() > 900) {
    //    $('.dropdown-menu.filters-dropdown').on('click touchstart', stopPropogationEvent);
    //}
    //else {
    //    $('.dropdown-menu.filters-dropdown').unbind('click touchstart', stopPropogationEvent);
    //}
    vm.fPageSize = getNumMoviesPerPage(vm.itemsPerLoadLG, vm.itemsPerLoadMD, vm.itemsPerLoadSM, vm.itemsPerLoadXS);
    vm.selectedPageSize(getNumMoviesPerPage(vm.itemsPerLoadLG, vm.itemsPerLoadMD, vm.itemsPerLoadSM, vm.itemsPerLoadXS));
  }
  function getNumMoviesPerPage(itemsPerLoadLG, itemsPerLoadMD, itemsPerLoadSM, itemsPerLoadXS) {
    var windowWidth = vm.windowSize();
    if (windowWidth >= 1800) {
      return itemsPerLoadLG;
    } else if (windowWidth >= 900) {
      return itemsPerLoadMD;
    } else if (windowWidth >= 600) {
      return itemsPerLoadSM;
    } else {
      return itemsPerLoadXS;
    }
  }
  function getCorrectScrollDistance() {
    var $elemTarget = $(".result-container .search-records");
    var elemScrollTop = $elemTarget.offset().top;
    var elemHeight = $elemTarget.height();
    var elemTotalHeight = elemScrollTop + elemHeight;
    vm.correctScrollHeightNeeded = elemTotalHeight - $(window).height() * 0.75;
    $(window).scroll(loadMoreScrollingCheck);
  }
  function initMoviesGrid() {
    var $resultContainer = $(".result-container"),
      $searchRecords = $(".result-container .search-records");
    if ($searchRecords.children().length === vm.resultRows().length) {
      $resultContainer.imagesLoaded().always(function () {
        vm.resultsLoadingLoader(false);
        $resultContainer.animate({
          "height": $searchRecords.outerHeight() + 60
        }, 500, getCorrectScrollDistance);
      });
    }
  }
  function loadMoreScrollingCheck() {
    clearTimeout(vm.scrollDebounce);
    vm.scrollDebounce = setTimeout(function () {
      if ($(window).scrollTop() > vm.correctScrollHeightNeeded && !vm.onLastPage) {
        vm.resultsLoadingLoader(true);
        updateResults(false, false);
        $(window).unbind('scroll', loadMoreScrollingCheck);
      }
    }, 200);
  }
  function RefinementCategory(name, displayName, refinements) {
    'use strict';

    this.Name = name;
    this.DisplayName = displayName;
    this.Refinements = refinements;
  }
  function RefinementModel(name, count, type, value, selected) {
    'use strict';

    this.Name = name;
    this.Count = count;
    this.Type = type;
    this.Value = value;
    this.Selected = ko.observable(selected);

    //hide the current genre in filter list
    if (csGlobal.getUrlParam("genre") !== null) this.Hidden = csGlobal.getUrlParam("genre").toLowerCase() === value.toLowerCase() ? true : false;else this.Hidden = false;
    this.Selected.subscribe(function (newval) {
      //omniture tracking for filters
      csTracking.search.checkFilter(name, value);
      if (newval) {
        switch (name) {
          case "Genres":
            var genres = vm.fGenre;
            genres.push(value);
            vm.fGenre = _.uniq(genres);
            break;
          case "ReleaseYear":
            var years = vm.fYear;
            years.push(value);
            vm.fYear = _.uniq(years);
            break;
          case "Language":
            var languages = vm.fLang;
            languages.push(value);
            vm.fLang = _.uniq(languages);
            break;
          default:
            break;
        }
      } else {
        switch (name) {
          case "Genres":
            vm.fGenre = _.filter(vm.fGenre, function (item) {
              return item !== value;
            });
            break;
          case "ReleaseYear":
            vm.fYear = _.filter(vm.fYear, function (item) {
              return item !== value;
            });
            break;
          case "Language":
            vm.fLang = _.filter(vm.fLang, function (item) {
              return item !== value;
            });
            break;
          default:
            break;
        }
      }
      updateResults(true, false);
    });
  }
  function FiltersQuerystring(fgenre, fyear, flang, fsort, fpage, fpagesize) {
    'use strict';

    this.query = csGlobal.getUrlParam("query") ? csGlobal.getUrlParam("query") : "";
    this.genre = csGlobal.getUrlParam("genre") ? csGlobal.getUrlParam("genre") : "";
    this.genrefr = csGlobal.getUrlParam("genrefr") ? csGlobal.getUrlParam("genrefr") : "";
    this.fGenre = fgenre ? fgenre : [];
    this.fYear = fyear ? fyear : [];
    this.fLang = flang ? flang : [];
    this.fSortBy = fsort ? fsort : vm.selectedSort();
    this.fPage = fpage ? fpage : 1;
    this.fPageSize = fpagesize ? fpagesize : vm.selectedPageSize();
  }
  function FiltersQuerystringObject(fgenre, fyear, flang, fsort, fpage, fpagesize, query) {
    'use strict';

    this.query = query;
    this.genre = csGlobal.getUrlParam("genre") ? csGlobal.getUrlParam("genre") : "";
    this.fGenre = fgenre ? fgenre.split(',') : [];
    this.fYear = fyear ? fyear.split(',') : [];
    this.fLang = flang ? flang.split(',') : [];
    this.fSortBy = fsort ? fsort : vm.selectedSort();
    this.fPage = fpage ? fpage : 1;
    this.fPageSize = fpagesize ? fpagesize : vm.selectedPageSize();
  }
  function findWindowSize() {
    return window.innerWidth;
  }
  function initialSearchCall() {
    var queryUrl = vm.genreDisplay() === null && csGlobal.getUrlParam('query') !== null ? csGlobal.getUrlParam('query') : '';
    vm.queryDisplay = queryUrl.replace(/["]/g, "");
    vm.searchInputText(vm.queryDisplay);
    //Resolve query for search api
    var queryValue = queryUrl;
    if (queryValue.indexOf("\"") >= 0) vm.query = queryUrl;else vm.query = queryUrl;
    vm.selectedSort(vm.fSortBy);
    vm.selectedPageSize(vm.fPageSize);
    var newPage = parseInt(vm.fPage);
    if (vm.searchKeywordSections() !== null && vm.searchKeywordSections().length > 0) {
      var searchKeywordSectionData = ko.utils.arrayFirst(vm.searchKeywordSections(), function (searchKeywordSection) {
        var searchKeyword = ko.utils.arrayFirst(searchKeywordSection.searchRelatedKeywords, function (keyword) {
          return keyword.relatedKeyword.toLowerCase() === vm.queryDisplay.toLowerCase();
        });
        return searchKeyword;
      });
      if (searchKeywordSectionData !== null) {
        vm.searchKeywordContent(searchKeywordSectionData.searchKeywordContent);
        vm.searchKeywordSectionVisible(true);
      }
    }
    updateResults(true, true);
  }
  function getSearchResults(params, callback) {
    csGlobal.AddAjaxOverlay();
    var redirectUrl = "";
    if ($.isFunction(callback)) {
      $.getJSON(vm.vmSearchApiUrl + params, function (data) {
        if (data != null) {
          callback(data);
          //check if redirect is required
          if (data.redirect != null && data.redirect !== "") {
            redirectUrl = data.redirect;
          }
        }
      }).always(function () {
        //go to redirect url if exists
        if (redirectUrl !== "") {
          window.location.href = redirectUrl;
        } else {
          var attachFilterDropdownHandler = !vm.dataCallOccurred();
          var firstLoadSortFilter = vm.sortFilterInit;
          vm.dataCallOccurred(true);
          if (attachFilterDropdownHandler) {
            initFilterDropdownBehaviour();
          }
          if (firstLoadSortFilter) {
            $(".dropdown-menu.filters-dropdown input[type='checkbox']").on("keyup", function (event) {
              if (event.keyCode === 13) {
                $(this).trigger("click");
              }
            });
            $(".dropdown-menu.filters-dropdown input[type='checkbox']").on("focus", function (event) {
              $(this).siblings('.checkbox-btn').addClass('focused-outline');
            });
            $(".dropdown-menu.filters-dropdown input[type='checkbox']").on("blur", function (event) {
              $(this).siblings('.checkbox-btn').removeClass('focused-outline');
            });
            $(".filter-item-name input.refinement-chkbox[type='radio']").on("keyup", function (event) {
              if (event.keyCode === 13) {
                $(this).prop("checked", true).trigger("click");
              }
            });
            $(".filter-item-name input.refinement-chkbox[type='radio']").on("focus", function (event) {
              $(this).siblings('.checkbox-btn').addClass('focused-outline');
            });
            $(".filter-item-name input.refinement-chkbox[type='radio']").on("blur", function (event) {
              $(this).siblings('.checkbox-btn').removeClass('focused-outline');
            });
          }
          vm.sortFilterInit = false;
          vm.isLoaded(true);
        }
      });
    }
  }
  function initFilterDropdownBehaviour() {
    //if (vm.windowSize() > 900) {
    $('.dropdown-menu.filters-dropdown').on('click touchstart', stopPropogationEvent);
    //}
  }
  function populateRefinements(groupByResult) {
    //Call Backstage API to Get Genres
    var requestData = {
      "device": vm.getPlatformId,
      "language": vm.vmLanguageSetting == "English" ? 1 : 2
    };
    $.ajax({
      type: "POST",
      url: vm.deviceSpecificGenresURL,
      data: JSON.stringify(requestData),
      contentType: "application/json; charset=utf-8",
      crossDomain: true,
      dataType: "json",
      success: function success(data, textStatus, jqXHR) {
        generateRefinementsFunction(groupByResult, data.Items);
      },
      error: function error(data, errorMessage, jqXHR) {
        generateRefinementsFunction(groupByResult, []);
        console.log(errorMessage);
      }
    });
  }
  function getInitialFilters() {
    //Generate the refinements url query string portion
    var refinementList = vm.RefinementsList();
    var initialFilters = new FiltersQuerystringObject(csGlobal.getUrlParam("fGenre"), csGlobal.getUrlParam("fYear"), csGlobal.getUrlParam("fLang"), csGlobal.getUrlParam("fSortBy"), csGlobal.getUrlParam("fPage"), csGlobal.getUrlParam("fPageSize"), csGlobal.getUrlParam("query"), csGlobal.getUrlParam("genre"));
    var currUrlRefinementCount = 0;
    for (var i = 0; i < refinementList.length; i++) {
      //Go through categories
      var currCategory = refinementList[i];
      for (var j = 0; j < currCategory.Refinements.length; j++) {
        //Go through their refinements
        var currRefinement = currCategory.Refinements[j];
        var matchgenre = null;
        var matchyear = null;
        var matchlanguage = null;
        if (initialFilters.fGenre && currCategory.Name === "Genres") {
          for (var gi = 0; gi < initialFilters.fGenre.length; gi++) {
            matchgenre = _.findWhere(currCategory.Refinements, {
              Value: initialFilters.fGenre[gi]
            });
            if (matchgenre) matchgenre.Selected(true);
          }
        }
        if (initialFilters.fYear && currCategory.Name === "ReleaseYear") {
          for (var yi = 0; yi < initialFilters.fYear.length; yi++) {
            matchyear = _.findWhere(currCategory.Refinements, {
              Value: initialFilters.fYear[yi]
            });
            if (matchyear) matchyear.Selected(true);
          }
        }
        if (initialFilters.fLang && currCategory.Name === "Language") {
          for (var li = 0; li < initialFilters.fLang.length; li++) {
            matchlanguage = _.findWhere(currCategory.Refinements, {
              Value: initialFilters.fLang[li]
            });
            if (matchlanguage) matchlanguage.Selected(true);
          }
        }
      }
    }
  }
  function getRefinementsUrlString() {
    //Generate the refinements url query string portion
    var refinementsUrlStr = '';
    var refinementList = vm.RefinementsList();
    var currUrlRefinementCount = 0;
    for (var i = 0; i < refinementList.length; i++) {
      //Go through categories
      var currCategory = refinementList[i];
      for (var j = 0; j < currCategory.Refinements.length; j++) {
        //Go through their refinements
        var currRefinement = currCategory.Refinements[j];
        if (currRefinement.Selected() == true) {
          refinementsUrlStr += '&';
          refinementsUrlStr += '[' + currUrlRefinementCount + '].Count=' + currRefinement.Count + '&[' + currUrlRefinementCount + '].Type=' + currRefinement.Type + '&[' + currUrlRefinementCount + '].Value=' + encodeURIComponent(currRefinement.Value) + '&[' + currUrlRefinementCount + '].Name=' + currRefinement.Name;
          currUrlRefinementCount++;
        }
      }
    }
    if (vm.genreDisplay() !== null) {
      refinementsUrlStr += '&';
      refinementsUrlStr += '[' + currUrlRefinementCount + '].Type=' + "Value" + '&[' + currUrlRefinementCount + '].Value=' + encodeURIComponent(csGlobal.getUrlParam('genre')) + '&[' + currUrlRefinementCount + '].Name=' + "Genres";
      currUrlRefinementCount++;
    }
    return refinementsUrlStr;
  }
  function generateRefinementsFunction(data, genres) {
    var totalRefinementArr = [];
    //Genres from the backstage
    var getGenres = genres.map(function (value) {
      return value.GenreName;
    });
    if (data.availableNavigation != null) {
      //Build a total-refinements array
      for (var i = 0; i < data.availableNavigation.length; i++) {
        var categoryRefinementArr = [];
        var currCategory = data.availableNavigation[i];
        for (var j = 0; j < currCategory.refinements.length; j++) {
          var refinementInCategory = currCategory.refinements[j];
          var newRefinement = new RefinementModel(currCategory.name, refinementInCategory.count, refinementInCategory.type, refinementInCategory.value, checkIfSelectedRefinement(data, currCategory.name, refinementInCategory.type, refinementInCategory.value));
          if (currCategory.name.toLowerCase() == "genres") {
            //Filtering out the genres from group by with backstage genres
            if (getGenres.includes(newRefinement.Value)) categoryRefinementArr.push(newRefinement);
          } else {
            categoryRefinementArr.push(newRefinement);
          }
        }
        var categoryContainer = new RefinementCategory(data.availableNavigation[i].name, data.availableNavigation[i].displayName, categoryRefinementArr);
        //Build category object
        totalRefinementArr.push(categoryContainer);
      }
    }
    vm.RefinementsList(totalRefinementArr);
  }
  function updateUrlWithFilters() {
    var exitParams = csGlobal.toQueryString(new FiltersQuerystring(vm.fGenre, vm.fYear, vm.fLang, vm.fSortBy, vm.pageSelected(), vm.selectedPageSize()));
    csGlobal.updateUrl(exitParams);
  }
  function checkIfSelectedRefinement(data, categoryName, refinementType, refinementValue) {
    //Check if any refinements have already been selected and apply them to the ViewModel
    if (data.selectedNavigation != null) {
      for (var k = 0; k < data.selectedNavigation.length; k++) {
        //Go through each selected category
        if (categoryName != data.selectedNavigation[k].name || data.selectedNavigation[k].refinements == null) {
          //category name
          continue;
        }
        for (var l = 0; l < data.selectedNavigation[k].refinements.length; l++) {
          //Go through each selected refinement
          if (data.selectedNavigation[k].refinements[l].value == refinementValue && data.selectedNavigation[k].refinements[l].type == refinementType) {
            return true;
          }
        }
      }
      return false;
    }
    return false;
  }
  function toggleFilterOverlayFunction() {
    vm.filterOverlayVisible(!vm.filterOverlayVisible());
  }

  //helper methods 

  function getDisplayTitleFunction(allMeta, languageString) {
    if (allMeta != null) {
      var displayTitle = allMeta.title;
      var rdate = allMeta.ReleaseDate;
      if (rdate != null && rdate != "") {
        displayTitle += " (" + rdate.substring(0, rdate.indexOf("/")) + ")";
      }
      if (languageString !== "lang_en") {
        displayTitle += " (In French)";
      }
      return displayTitle;
    }
    return "";
  }
  function createImgSrcFunction(src) {
    if (src != null) {
      return 'https://cineplex.cpxstoreimages.com/cineplex?set=imageSize[large550],imageURL[' + src + ']&call=url[file:web/sizing]';
      //return src;
    } else {
      return 'https://storemedia.cineplex.com/postermissing_460x682.jpg';
    }
  }
  function isFrenchMovieFunction(langString) {
    return langString !== "lang_en";
  }
  function initSearchAutocomplete(storeCollectionSetting, searchSaytCollection, saytAreaSetting, productDetailPageUrl) {
    $("#search-page-search-form .search-box-text").sayt({
      subdomain: 'cineplex',
      collection: storeCollectionSetting,
      saytCollection: searchSaytCollection,
      saytArea: saytAreaSetting,
      autocompleteTemplate: 'autocompleteTemplate',
      productTemplate: 'productTemplate',
      defaultImage: 'https://storemedia.cineplex.com/postermissing_460x682.jpg',
      selectSearchTerm: function selectSearchTerm(data) {
        $('#search-page-search-form .search-box-text').val(data.value);
        $('#search-page-search-form').submit();
      },
      selectNavigation: function selectNavigation(data) {
        $('#search-page-search-form .search-box-text').val(data.value);
        $('#search-page-search-form').submit();
      },
      selectProduct: function selectProduct(data) {
        if (data.ProductUrlName != null) window.location.href = productDetailPageUrl + data.ProductUrlName;
      }
    });
  }
  function clearAllFilters(element, event) {
    var newArray = [];
    vm.RefinementsList.peek().forEach(function (refinementCat, index, array) {
      var newRefinements = [];
      refinementCat.Refinements.forEach(function (refinement) {
        newRefinements.push(new RefinementModel(refinement.Name, refinement.Count, refinement.Type, refinement.Value, false));
      });
      newArray.push(new RefinementCategory(refinementCat.Name, refinementCat.DisplayName, newRefinements));
    });
    vm.RefinementsList(newArray);
    vm.fGenre = [];
    vm.fYear = [];
    vm.fLang = [];
    vm.sortFilterInit = true;
    updateResults(true, false);
  }
  function stopPropogationEvent(event) {
    if (!$(event.target).hasClass("dropdown-toggle")) {
      event.stopPropagation();
    }
  }
  function goToSearchPageFunction() {
    if (vm.searchInput().length > 0) window.location.href = vm.searchPageUrl + vm.searchInput();
  }
};