(function () {
  'use strict';

  var storeApp = window.storeApp || {};
  storeApp.superTicketUpgradeViewModel = function (ko, $, upgradeModel, connect, superTicketService) {
    var self = this;
    self.theatreId = ko.observable("").extend({
      required: {
        message: upgradeModel.theatreIDError
      }
    });
    self.transactionId = ko.observable("").extend({
      required: {
        message: upgradeModel.transactionIDError
      }
    });
    self.seqId = ko.observable("").extend({
      required: {
        message: upgradeModel.sequenceIDError
      }
    });
    self.upgradeErrorMessage = ko.observable("");
    self.showUpgradeErrorMessage = ko.observable(false);
    self.productId = ko.observable();
    self.pricingPlanId = ko.observable();
    self.showSuperTicketConnectLoginError = ko.observable(false);
    self.superTicketUpgradeLoading = ko.observable(false);
    self.formVisible = ko.observable(true);
    self.errors = ko.validation.group(self);
    self.upgradeVoucher = handleUpgradeSubmission;
    self.resetForm = resetForm;

    //Add listeners to autofocus next field
    self.theatreId.subscribe(function (newVal) {
      if (newVal.length === 4) {
        $("#st-upgrade-transaction-id").focus();
      }
    });
    self.transactionId.subscribe(function (newVal) {
      if (newVal.length === 8) {
        $("#st-upgrade-sequence-id").focus();
      } else if (newVal.length === 0) {
        $("#st-upgrade-theatre").focus();
      }
    });
    self.seqId.subscribe(function (newVal) {
      if (newVal.length === 0) {
        $("#st-upgrade-transaction-id").focus();
      }
    });
    function handleUpgradeSubmission() {
      self.showSuperTicketConnectLoginError(false);
      self.upgradeErrorMessage("");
      self.showUpgradeErrorMessage(false);
      if (self.errors().length === 0) {
        self.superTicketUpgradeLoading(true);
        if (!connect.IsUserLoggedIn()) {
          connect.OpenConnectPage(1);
          self.superTicketUpgradeLoading(false);
          self.formVisible(false);
          //Used to append old connect function to new one
          var connectOldCloseFunc = connect.Settings.Close;
          connect.Settings.Close = function () {
            connectOldCloseFunc();
            setTimeout(function () {
              $("body").addClass("modal-open"); //Readd class since connect removes it
              if (!connect.IsUserLoggedIn()) {
                self.showSuperTicketConnectLoginError(true);
                self.upgradeErrorMessage(upgradeModel.logInError);
                self.showUpgradeErrorMessage(true);
                self.formVisible(true);
              } else {
                self.formVisible(true);
                self.upgradeVoucher();
              }
              connect.Settings.Close = connectOldCloseFunc;
            });
          };
        } else {
          superTicketService.upgradeSuperTicket(self.theatreId(), self.transactionId(), self.seqId(), upgradeSuccess, upgradeFailure);
        }
      } else {
        var errorMessage = upgradeModel.fieldsRequired,
          incorrectFields = self.errors().join(", "),
          newErrorMessage = errorMessage.replace("{0}", incorrectFields);
        showUpgradeError(newErrorMessage);
      }
    }
    function upgradeSuccess(responseData) {
      var response = JSON.parse(responseData);
      if (response.Status === 1) {
        redirectToCheckout(response.ProductId, response.PricingPlanId);
      } else if (response.IsSuperTicketUpgradeUsed) {
        showUpgradeError(upgradeModel.upgradeTicketUsed);
        self.superTicketUpgradeLoading(false);
      } else {
        showUpgradeError(upgradeModel.validationError);
        self.superTicketUpgradeLoading(false);
      }
    }
    function upgradeFailure(responseData) {
      showUpgradeError(upgradeModel.unexpectedError);
      self.superTicketUpgradeLoading(false);
    }
    function showUpgradeError(message) {
      self.upgradeErrorMessage(message);
      self.showUpgradeErrorMessage(true);
    }
    function redirectToCheckout(productId, pricingPlanId) {
      window.location = "/Checkout/" + productId + "?PricingPlanId=" + pricingPlanId;
    }
    function resetForm() {
      self.theatreId("");
      self.transactionId("");
      self.seqId("");
      self.upgradeErrorMessage("");
      self.showUpgradeErrorMessage(false);
      self.productId("");
      self.pricingPlanId("");
      self.showSuperTicketConnectLoginError(false);
      self.superTicketUpgradeLoading(false);
      self.formVisible(true);
    }
    return self;
  };
})();