(function () {
  var storeApp = window.storeApp || {};
  storeApp.productDataService = function ($) {
    var main = {
      getProductPricingData: getProductPricingDataFunction
    };
    function getProductPricingDataFunction(productId, productDataUrl, callback) {
      if ($.isFunction(callback) && productDataUrl != null && productId != null && productId !== 0) {
        $.getJSON(productDataUrl + "?productId=" + productId, function (data) {
          callback(data.ProductCsgDetails);
        });
      }
    }
    return main;
  }(jQuery);
})();