/*
THE PURPOSE OF THIS FILE IS TO CONTAIN ALL OTHER VIEWMODELS TO BE USED ACROSS THE SITE
Proper usage would be to add other view models as an observable value to this view model, use the
custom "initialize:" binding feeding in the initialize method of the view model to be used and then
call the "with:" binding on the html area set up for use with that view model
*/

(function () {
  var storeApp = window.storeApp || {};
  storeApp.mainVM = function (ko, $, connect, moment, bowser, csTracking) {
    var vm = this;
    vm.promoBannerVM = ko.observable(new storeApp.promoBannerVM(ko, storeApp.promoService));
    vm.cineclubAlertBarVM = ko.observable(new storeApp.cineclubAlertBarVM(ko));
    vm.menuVM = ko.observable(new storeApp.menuVM(ko, $, storeApp.storeGlobalFunctions, csTracking));
    vm.footerVM = ko.observable(new storeApp.footerVM(ko, storeApp.storeGlobalFunctions, connect, storeApp.newsLetterService));
    vm.searchPageVM = ko.observable(new storeApp.searchPageVM(ko, $, connect, storeApp.storeGlobalFunctions, csTracking));
    vm.pdpVM = ko.observable(new storeApp.pdpViewModel(ko, $, moment, bowser, storeApp.productDataService, storeApp.digitalCopyService, storeApp.pricingService, connect, csTracking, storeApp.storeGlobalFunctions, storeApp.videoPlayerService));
    vm.checkoutVM = ko.observable(new storeApp.checkoutViewModel($, ko, bowser, connect, csTracking, storeApp.storeGlobalFunctions, storeApp.newsLetterService, storeApp.promoService));
    vm.superTicketVM = ko.observable(new storeApp.superTicketVM(ko, $, connect, storeApp.storeGlobalFunctions, csTracking, storeApp.superTicketService));
    vm.myLibraryVM = ko.observable(new storeApp.myLibraryVM(ko, $, moment, bowser, connect, storeApp.storeGlobalFunctions, csTracking, storeApp.videoPlayerService));
    vm.watchlistVM = ko.observable(new storeApp.watchlistVM(ko, $, moment, bowser, connect, storeApp.watchlistService, storeApp.pricingService, storeApp.storeGlobalFunctions, csTracking));
    vm.activateVM = ko.observable(new storeApp.activateVM(ko, $, connect));
    return vm;
  };
})();