CineplexAppConstants = window.CineplexAppConstants || {};
CineplexAppConstants.ScreenQualityLevels = {
  sd: 1,
  hd: 2,
  uhd: 3
};
CineplexAppConstants.ScreenQualityList = [{
  key: 'sd',
  display: 'SD'
}, {
  key: 'hd',
  display: 'HD'
}, {
  key: 'uhd',
  display: '4K'
}];
CineplexAppConstants.HarmonizedName = 'harmonize';
CineplexAppConstants.ContentType = {
  none: 0,
  all: 1,
  rental: 2,
  digitalPurchase: 3
};
CineplexAppConstants.PricingPlanType = {
  None: 0,
  Single: 1,
  Harmonize: 2
};